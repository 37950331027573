import { __decorate, __metadata } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
let MiniVehicle = class MiniVehicle extends BaseMini {
    entityType = EntityType.VEHICLE;
    localEntity = null;
    get manageLink() {
        return '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        return [];
    }
    async executeQuery(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await this.$http.get(`/vehicles/${id}`);
            this.localEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onTrigger() {
        if (this.id) {
            this.executeQuery(this.id);
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniVehicle.prototype, "onTrigger", null);
MiniVehicle = __decorate([
    Component
], MiniVehicle);
export default MiniVehicle;
