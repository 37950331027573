var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": _vm.disableSearch,
      "entity-type": _vm.EntityType.INVENTORY,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.INVENTORY,
      "entity-type": _vm.EntityType.INVENTORY,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-create-button": false,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectDrawingNumber', {
    attrs: {
      "disabled": _vm.isFilterLocked('drawingNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "mb-2": ""
    }
  }, [_c('AppSelectAsBuilt', {
    attrs: {
      "disabled": _vm.isFilterLocked('asBuiltNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.asBuiltNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "asBuiltNumber", $$v);
      },
      expression: "searchFilters.asBuiltNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectSerial', {
    attrs: {
      "disabled": _vm.isFilterLocked('serialNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.serialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "serialNumber", $$v);
      },
      expression: "searchFilters.serialNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "disabled": _vm.isFilterLocked('side'),
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "color": _vm.EntityType.INVENTORY,
      "label": "Side",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.side,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "side", $$v);
      },
      expression: "searchFilters.side"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }]
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-4": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "headers": _vm.searchAssemblyHeaders,
      "icon": _vm.$icons[_vm.EntityType.ASSEMBLY],
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };