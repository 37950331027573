import { __decorate } from "tslib";
import { addDays, isAfter, isBefore, isWithinInterval } from 'date-fns';
import html2canvas from 'html2canvas';
import { uniqBy } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { Side } from '~/db_types';
import { DocumentType, EventType } from '~/db_types/swagger_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { clearLogisticsCollectionCache, getLogisticsAllocationMatrix, putAssemblyTemplate, putPartReference, sortObjectBy } from '~/nasa_ui/utils';
import { dateFrom } from '~/nasa_ui/utils/dates';
import { MegaAllocationType, transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import PdfUtility, { PageOrientationOptions } from '~/nasa_ui/utils/PDFUtility';
let AllocationMatrix = class AllocationMatrix extends Mixins(BaseVue, CurrentUserMixin, ReadOnlyMixin) {
    currentlyHiddenMegas = [];
    dateFrom = dateFrom;
    DocumentType = DocumentType;
    EventType = EventType;
    formDataNote = null;
    isAfter = isAfter;
    isAddingNote = false;
    isBefore = isBefore;
    isLoading = false;
    MegaAllocationType = MegaAllocationType;
    rawAllocationMatrix = null;
    selectedAssemblyTemplateToEdit = null;
    selectedPartReference = null;
    selectedMegaLeaf = null;
    showAllocationWindow = true;
    showDeliveryOrders = true;
    showArchivedMegas = false;
    showMegaAllocationTypeGround = true;
    showMegaAllocationTypeOnOrbit = true;
    showMegaAllocationTypeTradeStudy = false;
    showModalUpdateMegaAllocationType = false;
    showModalAssemblyTemplateInfo = false;
    showModalAutoHardwareListInfo = false;
    showModalCloneMega = false;
    showModalEventAllocationWindow = false;
    showModalMegaPartEdit = false;
    showModalUpdateNeed = false;
    showNeedDates = true;
    showOnlyCriticalLists = false;
    spacePhrases = [
        'All systems go for launch!',
        'Smoother than low-Earth orbit.',
        'No issues detected, mission A-OK!',
        'Clear skies, no asteroids ahead.',
        'Shinier than a new space helmet!',
        'Aliens would give thumbs-up!',
        "T-minus zero, let's launch!",
        'Saturn’s rings are jealous!',
        'Like a comet, flawless speed!',
        'Landed smoother than Mars rover!'
    ];
    get filteredUniqAssemblyTemplateLeaves() {
        if (!this.showOnlyCriticalLists) {
            return this.uniqAssemblyTemplateLeaves;
        }
        return this.uniqAssemblyTemplateLeaves.filter((assemblyTemplate) => {
            return assemblyTemplate.autoHardwareListIsCritical;
        });
    }
    get randomSuccessPhrase() {
        return this.spacePhrases[Math.floor(Math.random() * this.spacePhrases.length)];
    }
    // These will be of type MEGA, super, etc and will be ROOTs
    // This is where the filtering happens
    get transformedAssemblyTemplates() {
        if (!this.rawAllocationMatrix || !this.rawAllocationMatrix.assemblyTemplates) {
            return [];
        }
        const transformed = transformAssemblyTemplateResponseWithChildrens(this.rawAllocationMatrix.assemblyTemplates);
        return transformed
            .filter((mega) => !this.currentlyHiddenMegas.map((mega) => mega.id).includes(mega.id))
            .filter((mega) => {
            if (!this.showMegaAllocationTypeGround &&
                !this.showMegaAllocationTypeOnOrbit &&
                !this.showMegaAllocationTypeTradeStudy) {
                return mega;
            }
            return ((this.showMegaAllocationTypeGround && mega.attributes.megaAllocationType === MegaAllocationType.GROUND) ||
                (this.showMegaAllocationTypeOnOrbit && mega.attributes.megaAllocationType === MegaAllocationType.ON_ORBIT) ||
                (this.showMegaAllocationTypeTradeStudy &&
                    mega.attributes.megaAllocationType === MegaAllocationType.TRADE_STUDY));
        })
            .filter((mega) => {
            // if you dont want to see the archived ones
            if ((!this.showArchivedMegas && !mega.attributes.isArchived) || !Object.hasOwn(mega.attributes, 'isArchived')) {
                return mega;
            }
            // if you want to see the inactives and this one is inactive, true
            if (this.showArchivedMegas && mega.attributes.isArchived) {
                return mega;
            }
            return false;
        });
    }
    get uniqAssemblyTemplateLeaves() {
        if (!this.transformedAssemblyTemplates) {
            return [];
        }
        return uniqBy(this.transformedAssemblyTemplates.flatMap((assemblyTemplate) => {
            return assemblyTemplate._children;
        }), '_name').sort(sortObjectBy('_name'));
    }
    calculateComputedWindowEndDate(rootMega) {
        const hasWaiverAtRoot = Boolean(rootMega.waiverDate);
        const allocationWindow = this.findAllocationWindowEvent(rootMega);
        if (hasWaiverAtRoot && this.hasAllocationWindow(rootMega)) {
            // is it after the end? if so use that as the end
            return !isBefore(dateFrom(rootMega._waiverDate), dateFrom(allocationWindow._endDate))
                ? rootMega._waiverDate
                : allocationWindow._endDate;
        }
        if (hasWaiverAtRoot && !this.hasAllocationWindow(rootMega)) {
            return rootMega._waiverDate;
        }
        if (!hasWaiverAtRoot && this.hasAllocationWindow(rootMega)) {
            return allocationWindow._endDate;
        }
        return null;
    }
    created() {
        this.fetchAllocationMatrix();
    }
    generateChickletStatusCssClass(assemblyTemplate) {
        return assemblyTemplate?.attributes.chickletNodeStatus
            ? `chicklet_status__${assemblyTemplate?.attributes.chickletNodeStatus}`
            : '';
    }
    getChildTemplateFromRoot(rootMega, idToFind) {
        return rootMega._children?.find((child) => child.autoHardwareListId === idToFind) ?? null;
    }
    findAllocationWindowEvent(rootMega) {
        return rootMega._cosmicEvents.find((evt) => evt.subType === EventType.ALLOCATION_WINDOW) ?? null;
    }
    findChangeSetsByPartReference(partReference) {
        if (!this.rawAllocationMatrix) {
            return null;
        }
        return this.rawAllocationMatrix.deliveryOrderLineItems.changeSets?.filter((cs) => cs.partReferenceId === partReference.id);
    }
    findChangeSetsByPartReferenceAndDocumentId(partReference, documentId) {
        return this.findChangeSetsByPartReference(partReference)?.filter((cs) => cs.documentId === documentId);
    }
    findDeliveryOrdersByPartReference(partReference) {
        if (!this.rawAllocationMatrix) {
            return null;
        }
        const documentIdThatReferenceThisPart = this.findChangeSetsByPartReference(partReference)?.map((cs) => cs.documentId);
        if (!documentIdThatReferenceThisPart) {
            return this.rawAllocationMatrix.deliveryOrderLineItems.documents ?? null;
        }
        return this.rawAllocationMatrix.deliveryOrderLineItems.documents?.filter((doc) => {
            return documentIdThatReferenceThisPart?.includes(doc.id);
        });
    }
    async fetchAllocationMatrix() {
        try {
            this.isLoading = true;
            const resp = await getLogisticsAllocationMatrix();
            this.rawAllocationMatrix = resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
        finally {
            this.isLoading = false;
        }
    }
    hasAllocationWindow(rootMega) {
        return rootMega._cosmicEvents.find((evt) => evt.subType === EventType.ALLOCATION_WINDOW) !== undefined;
    }
    isEstimatedCloseDateAfterNeed(projectedCloseDate, computedNeedDate) {
        if (!projectedCloseDate || !computedNeedDate) {
            return false;
        }
        return !isAfter(dateFrom(computedNeedDate), dateFrom(projectedCloseDate));
    }
    isEstimatedCloseDateWithin90DaysOfNeed(projectedCloseDate, computedNeedDate) {
        if (!projectedCloseDate || !computedNeedDate) {
            return false;
        }
        const lowRange = addDays(dateFrom(computedNeedDate), 1);
        const highRange = addDays(dateFrom(computedNeedDate), 89);
        return isWithinInterval(dateFrom(projectedCloseDate), {
            start: lowRange,
            end: highRange
        });
    }
    isUsageLifeErrorMitigated(rootMega, partReference) {
        // Check to see if usage if current in error state
        const isUsageInErrorState = partReference.usageLifeExpirationDate &&
            !isAfter(dateFrom(partReference.usageLifeExpirationDate), dateFrom(this.calculateComputedWindowEndDate(rootMega)));
        if (isUsageInErrorState) {
            // is there an open DO line item that has a planned expiration date that is not in error state
            return Boolean(this.findChangeSetsByPartReference(partReference)?.filter((cs) => cs.plannedExpirationDate).length);
        }
        return true;
    }
    printAllocationMatrix() {
        const toBePrinted = document.querySelectorAll('.allocation_matrix .allocation_matrix_home')[0];
        let cloned = toBePrinted.innerHTML;
        cloned = cloned.replaceAll('fad', 'fas').replaceAll('fa-duotone', 'fa-regular');
        const pages = [];
        document.querySelectorAll('#printContainer')[0].innerHTML = cloned;
        html2canvas(document.querySelectorAll('#printContainer')[0])
            .then((canvas) => {
            const windowHeight = 830;
            const numberOfPages = Math.ceil(canvas.height / windowHeight);
            for (let i = 0; i < numberOfPages; i++) {
                const canvasCopy = document.createElement('canvas');
                canvasCopy.width = canvas.width;
                canvasCopy.height = windowHeight;
                const canvasCopyContext = canvasCopy.getContext('2d');
                canvasCopyContext.fillStyle = '#FFF';
                canvasCopyContext.fillRect(0, 0, canvas.width, windowHeight);
                canvasCopyContext.drawImage(canvas, 0, windowHeight * i, canvasCopy.width, windowHeight, 0, 0, canvasCopy.width, windowHeight);
                pages.push(canvasCopy);
            }
        })
            .then(() => {
            const reportOptions = {
                pageMargins: [10, 60, 10, 30],
                pageOrientation: PageOrientationOptions.LANDSCAPE
            };
            const contentBody = pages.map((page) => {
                return {
                    image: page.toDataURL('image/jpeg'),
                    width: 792
                };
            });
            const reportData = {
                title: `Allocation matrix`,
                data: {
                    document: {
                        isExportControlled: false,
                        isInitialized: true,
                        responsibleOrganizationCode: this.currentUserActiveSupportContext
                    }
                },
                body: contentBody,
                footer: true
            };
            new PdfUtility(reportOptions, reportData);
        });
    }
    reset() {
        this.resetJustModals();
        clearLogisticsCollectionCache();
        this.fetchAllocationMatrix();
    }
    resetJustModals() {
        // this.shouldHideAssemblyTemplateSelectionOnApplyWaiverModal = false;
        // this.showModalAddCommentToMegaLeaf = false;
        // this.showModalAssemblyTemplateWaiver = false;
        this.showModalAutoHardwareListInfo = false;
        this.showModalCloneMega = false;
        // this.showModalDeliveryOrderLineItemInfo = false;
        this.showModalEventAllocationWindow = false;
        // this.showModalEventAssignLaunchReturn = false;
        // this.showModalEventLaunch = false;
        // this.showModalEventReturn = false;
        this.showModalMegaPartEdit = false;
        this.showModalUpdateNeed = false;
        this.showModalUpdateMegaAllocationType = false;
        // this.currentlySelectedLaunchReturnEvents = [];
        this.selectedAssemblyTemplateToEdit = null;
        // this.selectedCosmicEvent = null;
        // this.selectedChangeSet = null;
        this.selectedMegaLeaf = null;
    }
    uiClickOfAddToNote(part) {
        if (this.isReadOnly) {
            return;
        }
        this.isAddingNote = true;
        this.formDataNote = `${part.notes || ''}`;
        this.selectedPartReference = part;
    }
    uiClickOfAllocationWindow(item) {
        if (this.isReadOnly) {
            return;
        }
        this.showAllocationWindow = true;
        this.showModalEventAllocationWindow = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfCloneMega(item) {
        if (this.isReadOnly) {
            return;
        }
        this.showModalCloneMega = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfEditAssemblyTemplatePart(item) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedAssemblyTemplateToEdit = item;
        this.showModalMegaPartEdit = true;
    }
    uiClickOfHideMega(item) {
        this.currentlyHiddenMegas.push(item);
    }
    uiClickOfUnhideMegas() {
        this.currentlyHiddenMegas = [];
    }
    uiClickOfUpdateNeed(item) {
        if (this.isReadOnly) {
            return;
        }
        this.showModalUpdateNeed = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfUpdateMegaAllocationType(item) {
        if (this.isReadOnly) {
            return;
        }
        this.showModalUpdateMegaAllocationType = true;
        this.selectedMegaLeaf = item;
    }
    uiClickOfViewAutoHardwareListInfo(item) {
        this.showModalAutoHardwareListInfo = true;
        this.selectedMegaLeaf = item;
    }
    async onClickOfArchiveMega(item) {
        if (this.isReadOnly) {
            return;
        }
        if (!item) {
            return;
        }
        try {
            const body = {
                attributes: Object.assign({}, item.attributes, { isArchived: true }),
                autoHardwareListId: item.autoHardwareListId ?? null,
                digitalLibraryRecordId: item.digitalLibraryRecordId ?? null,
                documentId: item.documentId ?? null,
                installedOn: item.installedOn,
                maxQuantity: item.maxQuantity,
                minQuantity: item.minQuantity,
                name: item.name ?? null,
                needDate: item.needDate ?? null,
                parentId: item.parentId ?? null,
                parts: item.parts ?? null,
                pbsItemId: item.pbsItemId ?? null,
                sequence: item.sequence,
                subType: item.subType,
                waiverDate: item.waiverDate ?? null,
                waiverNumber: item.waiverNumber ?? null
            };
            const resp = await putAssemblyTemplate(item.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Archived.'
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfUnarchiveMega(item) {
        if (this.isReadOnly) {
            return;
        }
        if (!item) {
            return;
        }
        try {
            const body = {
                attributes: Object.assign({}, item.attributes, { isArchived: false }),
                autoHardwareListId: item.autoHardwareListId ?? null,
                digitalLibraryRecordId: item.digitalLibraryRecordId ?? null,
                documentId: item.documentId ?? null,
                installedOn: item.installedOn,
                maxQuantity: item.maxQuantity,
                minQuantity: item.minQuantity,
                name: item.name ?? null,
                needDate: item.needDate ?? null,
                parentId: item.parentId ?? null,
                parts: item.parts ?? null,
                pbsItemId: item.pbsItemId ?? null,
                sequence: item.sequence,
                subType: item.subType,
                waiverDate: item.waiverDate ?? null,
                waiverNumber: item.waiverNumber ?? null
            };
            const resp = await putAssemblyTemplate(item.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Unarchived.'
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfSaveNoteToPart() {
        if (this.isReadOnly) {
            return;
        }
        if (!this.selectedPartReference) {
            return;
        }
        try {
            const body = {
                attributes: this.selectedPartReference.attributes || {},
                asBuiltNumber: this.selectedPartReference.asBuiltNumber || null,
                birthDate: this.selectedPartReference.birthDate || null,
                calibrationNumber: this.selectedPartReference.calibrationNumber || null,
                contractEndingItemNumber: this.selectedPartReference.contractEndingItemNumber || null,
                drawingDescription: this.selectedPartReference.drawingDescription || null,
                drawingNumber: this.selectedPartReference.drawingNumber || null,
                isTool: this.selectedPartReference.isTool ?? false,
                itemClass: this.selectedPartReference.itemClass || null,
                lastCalibrationDate: this.selectedPartReference.lastCalibrationDate || null,
                location: this.selectedPartReference.location || null,
                lotNumber: this.selectedPartReference.lotNumber || null,
                notes: this.formDataNote || null,
                serialNumber: this.selectedPartReference.serialNumber || null,
                shelfLifeExpirationDate: this.selectedPartReference.shelfLifeExpirationDate || null,
                side: this.selectedPartReference.side ? this.selectedPartReference.side : Side.NONE,
                size: this.selectedPartReference.size || null,
                usageLifeExpirationDate: this.selectedPartReference.usageLifeExpirationDate || null
            };
            const resp = await putPartReference(this.selectedPartReference.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.formDataNote = null;
            this.selectedPartReference = null;
            this.isAddingNote = false;
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
AllocationMatrix = __decorate([
    Component
], AllocationMatrix);
export default AllocationMatrix;
