var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { AutoHardwareListResponse } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { postAutoHardwareList } from '~/nasa_ui/utils';
let ModalAutoHardwareListCreate = class ModalAutoHardwareListCreate extends Mixins(BaseModal) {
    formDataAutoHardwareList = {
        description: null,
        drawingNumberPrefix: null,
        isCritical: null,
        name: null
    };
    get shouldDisableCreateButton() {
        return (!this.formDataAutoHardwareList.description ||
            !this.formDataAutoHardwareList.name ||
            !this.formDataAutoHardwareList.drawingNumberPrefix?.length);
    }
    async onClickOfCreateNewAutoHardwareList() {
        try {
            this.isSaving = true;
            const reqBody = {
                description: this.formDataAutoHardwareList.description,
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                isCritical: this.formDataAutoHardwareList.isCritical ?? false,
                name: this.formDataAutoHardwareList.name
            };
            const resp = await postAutoHardwareList(reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.emitSaved(resp.data);
            this.closeDialog();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved(data) {
        return data;
    }
};
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof AutoHardwareListResponse !== "undefined" && AutoHardwareListResponse) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalAutoHardwareListCreate.prototype, "emitSaved", null);
ModalAutoHardwareListCreate = __decorate([
    Component
], ModalAutoHardwareListCreate);
export default ModalAutoHardwareListCreate;
