var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.showForm ? _c('div', {
    staticClass: "pa-0"
  }, [_vm.isEditing ? _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "alert": _vm.templateChangeAlert,
      "px-2": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Change type",
      "value": _vm.changeSetTypeBlockquote
    }
  })], 1), _vm.equipmentGroupBlockquote ? _c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Equipment group",
      "value": _vm.equipmentGroupBlockquote
    }
  })], 1) : _vm._e(), _vm.itemInstanceInventoryTypeBlockquote ? _c('v-flex', {
    attrs: {
      "xs5": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Class",
      "value": _vm.itemInstanceInventoryTypeBlockquote
    }
  })], 1) : _vm._e()], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs8": "",
      "pr-2": ""
    }
  }, [_vm.userGroupSelectItems ? _c('AppSelect', {
    attrs: {
      "color": _vm.EntityType.USER,
      "chips": false,
      "disabled": _vm.isLocked,
      "label": "Approver must be a member of this group to respond",
      "select-options": _vm.userGroupSelectItems,
      "required": ""
    },
    model: {
      value: _vm.formData.groupCode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "groupCode", $$v);
      },
      expression: "formData.groupCode"
    }
  }) : _vm._e()], 1), _vm.formData.groupCode ? _c('v-flex', {
    attrs: {
      "xs4": "",
      "mt-2": ""
    }
  }, [!_vm.showSelectedGroupMembershipTable ? _c('ButtonGeneric', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSelectedGroupMembershipTable = !_vm.showSelectedGroupMembershipTable;
      }
    }
  }, [_vm._v(" View group membership ")]) : _vm._e(), _vm.showSelectedGroupMembershipTable ? _c('ButtonGeneric', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSelectedGroupMembershipTable = !_vm.showSelectedGroupMembershipTable;
      }
    }
  }, [_vm._v(" Hide group membership ")]) : _vm._e()], 1) : _vm._e()], 1), _vm.showSelectedGroupMembershipTable ? _c('v-layout', {
    staticClass: "my-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "my-2",
    attrs: {
      "color": _vm.EntityType.USER,
      "headers": _vm.userGroupMembershipTableHeaders,
      "items": _vm.userGroupMembers,
      "loading": _vm.isFetchingSelectedGroupMembership,
      "dense-rows": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', {
    staticClass: "my-2",
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "disabled": _vm.isLocked,
      "hide-unknown": true,
      "label": "Requires QC stamp?"
    },
    model: {
      value: _vm.formData.requiresQualityControlStamp,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "requiresQualityControlStamp", $$v);
      },
      expression: "formData.requiresQualityControlStamp"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "disabled": _vm.isLocked,
      "hide-unknown": true,
      "label": "Requires comment?"
    },
    model: {
      value: _vm.formData.requiresComment,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "requiresComment", $$v);
      },
      expression: "formData.requiresComment"
    }
  })], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };