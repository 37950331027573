var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "border-color": "var(--v-item_instances-base)",
      "start-collapsed": ""
    },
    on: {
      "dblclick": _vm.fetchAutoHardwareLists
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('IconAutoHardwareList', {
    staticClass: "mr-2"
  }), _c('span', [_vm._v("Auto hardware lists")])], 1), _c('div', [_c('ButtonCreate', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.launchCreateAutoHardwareListModal
    }
  }, [_vm._v("Create new auto list")]), _c('AppTableActions', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "headers": _vm.tableHeadersAutoHardwareList,
      "items": _vm.tableItemsAutoHardwareList,
      "show-header": false
    },
    on: {
      "dblclick": _vm.launchEditAutoHardwareList
    },
    scopedSlots: _vm._u([{
      key: "_drawingNumberPrefixes",
      fn: function ({
        item
      }) {
        return [_c('div', [item.drawingNumberPrefix && item.drawingNumberPrefix.length ? _c('ul', {
          staticClass: "pa-0 list-style-none"
        }, _vm._l(item._drawingNumberPrefix, function (prefix) {
          return _c('li', {
            key: prefix,
            staticClass: "pl-0 font-weight-bold monospace_font"
          }, [_vm._v(" " + _vm._s(prefix) + " ")]);
        }), 0) : _vm._e()])];
      }
    }, {
      key: "_isCritical",
      fn: function ({
        item
      }) {
        return [_c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[item.isCritical],
            "icon-color": item.isCritical ? 'var(--v-success-base)' : 'var(--v-secondary-base)',
            "icon-size": "18px"
          }
        })];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.launchEditAutoHardwareList(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfDeleteAutoHardwareList(item);
            }
          }
        })];
      }
    }])
  })], 1)]), _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title",
      "xs12": ""
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.mail,
      "icon-color": "var(--v-primary-base)"
    }
  }), _vm._v(" Email test ")], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Subject",
      "required": ""
    },
    model: {
      value: _vm.formDataEmail.subjectTemplate,
      callback: function ($$v) {
        _vm.$set(_vm.formDataEmail, "subjectTemplate", $$v);
      },
      expression: "formDataEmail.subjectTemplate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTextarea', {
    attrs: {
      "label": "HTML Template",
      "required": ""
    },
    model: {
      value: _vm.formDataEmail.messageHtmlTemplate,
      callback: function ($$v) {
        _vm.$set(_vm.formDataEmail, "messageHtmlTemplate", $$v);
      },
      expression: "formDataEmail.messageHtmlTemplate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "mb-3": ""
    }
  }, [_c('AppContactMultiple', {
    attrs: {
      "label": "Whos getting the email?"
    },
    model: {
      value: _vm.formDataEmail.recipientContactId,
      callback: function ($$v) {
        _vm.$set(_vm.formDataEmail, "recipientContactId", $$v);
      },
      expression: "formDataEmail.recipientContactId"
    }
  })], 1)], 1), _c('ButtonEmail', {
    attrs: {
      "slot": "actions",
      "disabled": _vm.disableEmailTestButton,
      "icon-color": "white"
    },
    on: {
      "click": _vm.sendEmail
    },
    slot: "actions"
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Test the email")])])], 1), _vm.hasAvailableContexts ? _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.mail,
      "icon-color": "var(--v-primary-base)"
    }
  }), _vm._v(" Email Users (fur real) ")], 1), _c('ButtonEmail', {
    staticClass: "pr-2"
  }), _vm._l(_vm.availableContexts, function (orgCode, index) {
    return _c('ButtonEmail', {
      key: index,
      staticClass: "pr-2",
      attrs: {
        "organizationCode": orgCode.organizationCode
      }
    });
  })], 2) : _vm._e(), _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.import,
      "icon-color": "var(--v-users-base)"
    }
  }), _vm._v(" Bulk upload Users ")], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Assumes: "), _c('ul', [_c('li', [_vm._v(" the order of the csv is "), _c('code', [_vm._v("[auid, email, lastName, firstName]")])]), _c('li', [_vm._v("Delimiter is "), _c('code', [_vm._v(",")])])])]), _c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.USER,
      "icon": "fad fa-file-excel"
    },
    on: {
      "click": function ($event) {
        _vm.showModalUserImport = true;
      }
    }
  }, [[_c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Upload Users")])]], 2)], 1)], 1), _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "start-collapsed": "",
      "border-color": "var(--v-organizations-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.organizations,
      "icon-color": "var(--v-organizations-base)"
    }
  }), _c('span', [_vm._v(" Create new support context ")])], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', [_vm._v("This will create a new Organization of type PROCESSOR")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-3": "",
      "mb-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "autofocus": "",
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.formDataOrganizationCreate.name,
      callback: function ($$v) {
        _vm.$set(_vm.formDataOrganizationCreate, "name", $$v);
      },
      expression: "formDataOrganizationCreate.name"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-3": "",
      "mb-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": _vm.formDataOrganizationCreate.isCageCode ? 'CAGE Code' : 'Code',
      "replace-spaces-with-underscores": "",
      "required": "",
      "upper-case": ""
    },
    model: {
      value: _vm.formDataOrganizationCreate.code,
      callback: function ($$v) {
        _vm.$set(_vm.formDataOrganizationCreate, "code", $$v);
      },
      expression: "formDataOrganizationCreate.code"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "label": "Is the Code entered a CAGE Code?",
      "hide-unknown": ""
    },
    model: {
      value: _vm.formDataOrganizationCreate.isCageCode,
      callback: function ($$v) {
        _vm.$set(_vm.formDataOrganizationCreate, "isCageCode", $$v);
      },
      expression: "formDataOrganizationCreate.isCageCode"
    }
  })], 1)], 1)], 1)], 1), _c('ButtonCreate', {
    attrs: {
      "slot": "actions",
      "disabled": !_vm.formDataOrganizationCreate.code || !_vm.formDataOrganizationCreate.name,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfCreatProcessorOrganization
    },
    slot: "actions"
  }, [_vm._v(" Create Support context ")])], 1), _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "start-collapsed": "",
      "border-color": "var(--v-contracts-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.contracts,
      "icon-color": "var(--v-contracts-base)"
    }
  }), _c('span', [_vm._v("Contracts")])], 1), _c('div', [_c('ButtonCreate', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.uiClickOfContractCreate
    }
  }), _c('AppTableActions', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "headers": _vm.tableHeadersContracts,
      "items": _vm.tableItemsContracts,
      "show-header": false
    },
    on: {
      "dblclick": _vm.uiClickOfContractEdit
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.uiClickOfContractEdit(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfContractDelete(item);
            }
          }
        })];
      }
    }])
  })], 1)]), _c('c-card', {
    attrs: {
      "border-color": "var(--v-item_instances-base)",
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.import,
      "icon-color": "var(--v-item_instances-base)"
    }
  }), _c('span', [_vm._v("SARAH import")])], 1), _c('div', {
    staticClass: "c-grid mb-2"
  }, [_c('div', {
    staticClass: "col-full mb-2"
  }, [_c('AppFileUpload', {
    key: "sarah_upload",
    model: {
      value: _vm.formDataSarah.file,
      callback: function ($$v) {
        _vm.$set(_vm.formDataSarah, "file", $$v);
      },
      expression: "formDataSarah.file"
    }
  })], 1), _c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.formDataSarah.description,
      callback: function ($$v) {
        _vm.$set(_vm.formDataSarah, "description", $$v);
      },
      expression: "formDataSarah.description"
    }
  })], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('ButtonGeneric', {
    attrs: {
      "disabled": !_vm.formDataSarah.file || !_vm.formDataSarah.description,
      "icon": _vm.$icons.import,
      "color": "secondary"
    },
    on: {
      "click": _vm.onClickOfImportSarah
    }
  }, [_vm._v("Import")])], 1)]), _c('div', [_c('AppTableActions', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "headers": _vm.tableHeadersPartReference,
      "items": _vm.tableItemsPartReference,
      "show-header": false,
      "dense-rows": "",
      "disable-initial-sort": ""
    },
    on: {
      "dblclick": _vm.uiClickOfInfoPartReference
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionInfo', {
          on: {
            "click": function ($event) {
              return _vm.uiClickOfInfoPartReference(item);
            }
          }
        }), _c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.uiClickOfEditPartReference(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.uiClickOfDeletePartReference(item);
            }
          }
        })];
      }
    }])
  })], 1)]), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-assembly_templates-base)",
      "start-collapsed": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": "fa-light fa-cat-space",
      "icon-color": "var(--v-assembly_templates-base)"
    }
  }), _c('span', [_vm._v("MEGA creation/updation")])], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Assumes: "), _c('ul', [_c('li', [_vm._v(" the order of the csv is "), _c('code', [_vm._v("[Item number, Item name, PartNumber(s), Parent name]")])]), _c('li', [_vm._v("Delimiter is "), _c('code', [_vm._v(",")])])])]), _c('AppPageDescription', [_vm._v("Import the CSV file to begin")]), _c('AppFileUpload', {
    key: "mega_upload",
    model: {
      value: _vm.fileMega,
      callback: function ($$v) {
        _vm.fileMega = $$v;
      },
      expression: "fileMega"
    }
  }), _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppText', {
    attrs: {
      "label": "Root name",
      "required": ""
    },
    model: {
      value: _vm.formDataMegaCreation.name,
      callback: function ($$v) {
        _vm.$set(_vm.formDataMegaCreation, "name", $$v);
      },
      expression: "formDataMegaCreation.name"
    }
  })], 1)]), _c('ButtonGeneric', {
    attrs: {
      "disabled": !_vm.fileAcceptedMega || !_vm.formDataMegaCreation.name,
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "isSaving": _vm.isSaving,
      "icon": "fad fa-file-excel mt-2"
    },
    on: {
      "click": _vm.onClickOfImportMega
    }
  }, [[_c('span', [_vm._v("Import MEGA structure")])]], 2), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.fileAcceptedMega ? _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-fourth"
  })]) : _vm._e()])], 1)])]), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-item_instances-base)",
      "start-collapsed": ""
    },
    on: {
      "dblclick": _vm.fetchPartReferences
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.part_reference,
      "icon-color": "var(--v-assembly_templates-base)"
    }
  }), _c('span', [_vm._v("Part reference upload")])], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Assumes: "), _c('ul', [_c('li', [_vm._v(" the order of the csv is "), _c('code', [_vm._v("[Item number, ....]")])]), _c('li', [_vm._v("Delimiter is "), _c('code', [_vm._v(",")])])])]), _c('AppPageDescription', [_vm._v("Import the CSV file to begin")]), _c('AppFileUpload', {
    key: "part_references_upload",
    model: {
      value: _vm.filePartReferences,
      callback: function ($$v) {
        _vm.filePartReferences = $$v;
      },
      expression: "filePartReferences"
    }
  }), _c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "isSaving": _vm.isSaving,
      "disabled": !_vm.fileAcceptedPartReference,
      "icon": "fad fa-file-excel mt-2"
    },
    on: {
      "click": _vm.onClickOfImportPartReferences
    }
  }, [[_c('span', [_vm._v("Import Part refs")])]], 2), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.fileAcceptedPartReference ? _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-fourth"
  })]) : _vm._e()])], 1)])]), _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-vehicles-base)",
      "start-collapsed": ""
    },
    on: {
      "dblclick": _vm.fetchVehicles
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.VEHICLE],
      "icon-color": "var(--v-vehicles-base)"
    }
  }), _c('span', [_vm._v("Vehicles")])], 1), _c('div', [_c('ButtonCreate', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.launchCreateVehicleModal
    }
  }, [_vm._v("Create new vehicles")]), _c('AppTableActions', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.VEHICLE,
      "headers": _vm.tableHeadersVehicle,
      "items": _vm.tableItemsVehicle,
      "show-header": false
    },
    on: {
      "dblclick": _vm.launchEditVehicleModal
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.launchEditVehicleModal(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfDeleteVehicle(item);
            }
          }
        })];
      }
    }])
  })], 1)]), _vm.showModalAutoHardwareListCreate ? _c('ModalAutoHardwareListCreate', {
    on: {
      "modal-close": _vm.resetAutoHardwareList
    }
  }) : _vm._e(), _vm.selectedAutoHardwareList ? _c('ModalAutoHardwareListEdit', {
    attrs: {
      "id": _vm.selectedAutoHardwareList.id
    },
    on: {
      "modal-close": _vm.resetAutoHardwareList
    }
  }) : _vm._e(), _vm.showModalVehicleCreate ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.VEHICLE,
      "hide-title-section": "",
      "modal-width": "50vw"
    },
    on: {
      "modal-close": _vm.resetVehicles
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "required": "",
            "label": "Name"
          },
          model: {
            value: _vm.formDataVehicle.name,
            callback: function ($$v) {
              _vm.$set(_vm.formDataVehicle, "name", $$v);
            },
            expression: "formDataVehicle.name"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateNewVehicle
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.resetVehicles
          }
        })];
      },
      proxy: true
    }], null, false, 419588112)
  }) : _vm._e(), _vm.showModalVehicleEdit && _vm.selectedVehicle ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.VEHICLE,
      "hide-title-section": "",
      "modal-width": "50vw"
    },
    on: {
      "modal-close": _vm.resetVehicles
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "required": "",
            "label": "Name"
          },
          model: {
            value: _vm.formDataVehicle.name,
            callback: function ($$v) {
              _vm.$set(_vm.formDataVehicle, "name", $$v);
            },
            expression: "formDataVehicle.name"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveVehicleList
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.resetVehicles
          }
        })];
      },
      proxy: true
    }], null, false, 3196480238)
  }) : _vm._e(), _vm.showModalUserImport ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.USER,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showModalUserImport = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.fileAccepted ? _c('div', [_c('div', {
          staticClass: "title mb-4"
        }, [_c('Alert', {
          attrs: {
            "type": "success"
          }
        }, [_c('p', {
          staticClass: "title"
        }, [_vm._v("File accepted.")])])], 1), _c('AppPageDescription', {
          attrs: {
            "remove-margin-left": ""
          }
        }, [_vm._v(" Clicking "), _c('strong', [_vm._v("IMPORT")]), _vm._v(" below will "), _c('i', {
          staticClass: "pr-1"
        }, [_vm._v("CREATE")]), _c('span', {
          staticClass: "success--text font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(_vm.usersToCreate.length))]), _vm._v(" Users/Contacts ")]), _c('AppPageDescription', {
          attrs: {
            "color": _vm.EntityType.USER,
            "remove-margin-left": ""
          }
        }, [_vm._v(" Imported Users will be granted the support context of "), _c('u', [_vm._v(_vm._s(_vm.currentUserActiveSupportContext))])]), _c('AppTableSelectable', {
          attrs: {
            "item-key": "index",
            "items": _vm.allParsedUsers,
            "headers": _vm.tableHeaderUsersImport,
            "color": _vm.EntityType.USER
          },
          model: {
            value: _vm.usersToCreate,
            callback: function ($$v) {
              _vm.usersToCreate = $$v;
            },
            expression: "usersToCreate"
          }
        })], 1) : _vm._e()]), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.fileAccepted ? _c('div', [_c('AppPageDescription', [_vm._v("Import the CSV file to begin")]), _c('AppFileUpload', {
          on: {
            "input": _vm.onUserImportFileSelected
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving,
            "disabled": _vm.isSaving || _vm.usersToCreate.length === 0
          },
          on: {
            "click": _vm.createUsers
          }
        }, [_vm._v(" Import " + _vm._s(_vm.usersToCreate.length) + " " + _vm._s(_vm.usersToCreate.length === 1 ? 'user' : 'users') + " ")]), _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showModalUserImport = false;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 950044835)
  }) : _vm._e(), _vm.showModalPartReferenceInfo && _vm.selectedPartReference ? _c('ModalPartReferenceInfo', {
    attrs: {
      "id": _vm.selectedPartReference.id
    },
    on: {
      "modal-close": _vm.resetPartReferences
    }
  }) : _vm._e(), _vm.showModalPartReferenceEdit && _vm.selectedPartReference ? _c('ModalPartReferenceEdit', {
    attrs: {
      "id": _vm.selectedPartReference.id
    },
    on: {
      "modal-close": _vm.resetPartReferences
    }
  }) : _vm._e(), _vm.showModalContractCreate ? _c('AppModal', {
    key: "contract_create",
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "hide-title-section": "",
      "modal-width": "55vw"
    },
    on: {
      "modal-close": _vm.resetContracts
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "required": "",
            "label": "Number"
          },
          model: {
            value: _vm.formDataContract.number,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "number", $$v);
            },
            expression: "formDataContract.number"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: _vm.formDataContract.name,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "name", $$v);
            },
            expression: "formDataContract.name"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "required": "",
            "label": "Start"
          },
          model: {
            value: _vm.formDataContract.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "startDate", $$v);
            },
            expression: "formDataContract.startDate"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "End"
          },
          model: {
            value: _vm.formDataContract.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "endDate", $$v);
            },
            expression: "formDataContract.endDate"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateNewContract
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.resetContracts
          }
        })];
      },
      proxy: true
    }], null, false, 2701797717)
  }) : _vm._e(), _vm.showModalContractEdit ? _c('AppModal', {
    key: "contract_edit",
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "hide-title-section": "",
      "modal-width": "55vw"
    },
    on: {
      "modal-close": _vm.resetContracts
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "required": "",
            "label": "Number"
          },
          model: {
            value: _vm.formDataContract.number,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "number", $$v);
            },
            expression: "formDataContract.number"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: _vm.formDataContract.name,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "name", $$v);
            },
            expression: "formDataContract.name"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "required": "",
            "label": "Start"
          },
          model: {
            value: _vm.formDataContract.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "startDate", $$v);
            },
            expression: "formDataContract.startDate"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "End"
          },
          model: {
            value: _vm.formDataContract.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataContract, "endDate", $$v);
            },
            expression: "formDataContract.endDate"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEditContract
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.resetContracts
          }
        })];
      },
      proxy: true
    }], null, false, 2718948020)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };