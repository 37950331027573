var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('draggable', {
    staticClass: "list-of-groups mb-3",
    class: {
      read_only: _vm.isReadOnly
    },
    attrs: {
      "disabled": _vm.isReadOnly,
      "tag": "ul",
      "ghost-class": "darken-2"
    },
    model: {
      value: _vm.userGroups,
      callback: function ($$v) {
        _vm.userGroups = $$v;
      },
      expression: "userGroups"
    }
  }, [_c('transition-group', {
    staticClass: "pl-0",
    attrs: {
      "tag": "ul"
    }
  }, _vm._l(_vm.userGroups, function (group, index) {
    return _c('li', {
      key: group.code || index,
      staticClass: "user_groups white--text mb-1 pa-2 align-center"
    }, [!_vm.isReadOnly ? _c('c-icon-fa', {
      attrs: {
        "icon": "fal fa-bars",
        "icon-color": "white",
        "tooltip": "Drag to sort."
      }
    }) : _vm._e(), _c('v-chip', {
      staticClass: "mini_chip seq_chip",
      attrs: {
        "disabled": ""
      }
    }, [_c('strong', [_vm._v(_vm._s(index + 1))])]), _c('strong', {
      staticClass: "mr-2 group_name"
    }, [_vm._v(_vm._s(_vm.computedGroupName(group)))]), !_vm.isReadOnly ? _c('c-icon-fa', {
      staticClass: "close_icon",
      attrs: {
        "icon": _vm.$icons.close,
        "icon-color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.removeUserGroup(group);
        }
      }
    }) : _vm._e()], 1);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };