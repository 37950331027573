var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "modal-width": "95vw",
      "modal-height": "60vh",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('c-card', {
          attrs: {
            "border-color": "var(--v-change_sets-base)"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.$icons.change_sets,
            "icon-color": "var(--v-change_sets-base)"
          }
        }), _vm._v(" Line item details ")], 1), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-fourth"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.DeliveryOrderLineItemStatusDisplay,
            "enum": _vm.DeliveryOrderLineItemStatus,
            "clearable": "",
            "label": "Line item status"
          },
          model: {
            value: _vm.formData.deliveryOrderLineItemStatus,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "deliveryOrderLineItemStatus", $$v);
            },
            expression: "formData.deliveryOrderLineItemStatus"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.DeliveryOrderLineItemTypeDisplay,
            "enum": _vm.DeliveryOrderLineItemType,
            "clearable": "",
            "label": "Line item type"
          },
          model: {
            value: _vm.formData.lineItemType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "lineItemType", $$v);
            },
            expression: "formData.lineItemType"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppContact', {
          attrs: {
            "label": "Task owner"
          },
          model: {
            value: _vm.formData.ownerContactId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ownerContactId", $$v);
            },
            expression: "formData.ownerContactId"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "DIL #"
          },
          model: {
            value: _vm.formData.deliveryItemsListNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "deliveryItemsListNumber", $$v);
            },
            expression: "formData.deliveryItemsListNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "Shipper #"
          },
          model: {
            value: _vm.formData.shipperNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "shipperNumber", $$v);
            },
            expression: "formData.shipperNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.JDocumentTypeDisplay,
            "enum": _vm.JDocumentType,
            "clearable": "",
            "label": "J Doc #"
          },
          model: {
            value: _vm.formData.jDocumentNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "jDocumentNumber", $$v);
            },
            expression: "formData.jDocumentNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppText', {
          attrs: {
            "label": "WBS",
            "pattern": "/^W\\d{4}-\\d{5}-\\d{5}$/",
            "placeholder": "W0000-00000-00000"
          },
          model: {
            value: _vm.formData.workBreakdownStructureNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "workBreakdownStructureNumber", $$v);
            },
            expression: "formData.workBreakdownStructureNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppDate', {
          attrs: {
            "label": "Open"
          },
          model: {
            value: _vm.formData.openedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "openedDate", $$v);
            },
            expression: "formData.openedDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppDate', {
          attrs: {
            "label": "Est. completion"
          },
          model: {
            value: _vm.formData.projectedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedDate", $$v);
            },
            expression: "formData.projectedDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppDate', {
          attrs: {
            "label": "Planned expiration"
          },
          model: {
            value: _vm.formData.plannedExpirationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "plannedExpirationDate", $$v);
            },
            expression: "formData.plannedExpirationDate"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid my-2"
        }, [_c('div', {
          staticClass: "col-twelveth"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Quantity"
          },
          model: {
            value: _vm.formData.lineItemQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "lineItemQuantity", $$v);
            },
            expression: "formData.lineItemQuantity"
          }
        })], 1)])]), _c('c-card', {
          staticClass: "mt-2",
          attrs: {
            "border-color": "var(--v-item_instances-base)"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.$icons.part_reference,
            "icon-size": "16px",
            "icon-color": "var(--v-item_instances-base)"
          }
        }), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.computedSelectedPartReference ? _c('span', [_vm._v("Part selection")]) : _vm._e()]), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.computedSelectedPartReference ? _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
          ..._vm.computedSelectedPartReference
        }, false)) : _vm._e()], 1)], 1), _c('div', {
          attrs: {
            "slot": "after-title"
          },
          slot: "after-title"
        }, [_c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.computedSelectedPartReference ? _c('ButtonMiniActionRemoveFromList', {
          staticClass: "mr-2",
          attrs: {
            "tooltip": "Clear part selection"
          },
          on: {
            "click": _vm.clearAllPartReferenceSelections
          }
        }) : _vm._e()], 1)], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.showPartReferenceForm && !_vm.computedSelectedPartReferenceId ? _c('div', {
          staticClass: "find_a_part_reference"
        }, [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.selectedPartReferenceIdViaMegaNeed ? _c('div', {
          staticClass: "c-grid mb-2 align-center"
        }, [_c('div', {
          staticClass: "col-fourth"
        }, [_c('AppSelect', {
          attrs: {
            "select-options": _vm.selectOptionsItemListing,
            "label": "Select part via Item listing",
            "monospace-selections": ""
          },
          model: {
            value: _vm.selectedAutoHardwareListId,
            callback: function ($$v) {
              _vm.selectedAutoHardwareListId = $$v;
            },
            expression: "selectedAutoHardwareListId"
          }
        })], 1), _vm.selectedAutoHardwareListId ? _c('div', {
          staticClass: "col-half"
        }, [_c('AppSelect', {
          attrs: {
            "loading": _vm.isLoadingPartsForSelectedAutoHardwareList,
            "select-options": _vm.selectOptionsPartReferenceFromSelectedItemListing,
            "allow-create": "",
            "label": _vm.selectedAutoHardwareListName ? `Select a part from list ${_vm.selectedAutoHardwareListName}` : 'Select a part'
          },
          on: {
            "option-create": _vm.uiClickOfCreateNewPartReference
          },
          scopedSlots: _vm._u([{
            key: "select-option",
            fn: function ({
              option
            }) {
              return [_vm.findPartReferenceFromId(option.value) ? _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
                ..._vm.findPartReferenceFromId(option.value)
              }, false)) : _vm._e()];
            }
          }], null, false, 3865064791),
          model: {
            value: _vm.selectedPartReferenceIdViaAutoHardwareListing,
            callback: function ($$v) {
              _vm.selectedPartReferenceIdViaAutoHardwareListing = $$v;
            },
            expression: "selectedPartReferenceIdViaAutoHardwareListing"
          }
        })], 1) : _vm._e()]) : _vm._e()]), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.selectedPartReferenceIdViaAutoHardwareListing && !_vm.showPartReferenceForm && !_vm.computedSelectedPartReferenceId ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-fourth"
        }, [_c('AppSelect', {
          attrs: {
            "select-options": _vm.selectOptionsMegaNeed,
            "loading": _vm.isFetchingMegaRootAssemblyTemplates,
            "monospace-selections": "",
            "label": "Select part via MEGA need"
          },
          model: {
            value: _vm.selectedMegaNeedAssemblyTemplateId,
            callback: function ($$v) {
              _vm.selectedMegaNeedAssemblyTemplateId = $$v;
            },
            expression: "selectedMegaNeedAssemblyTemplateId"
          }
        })], 1), _vm.selectedMegaNeedAssemblyTemplateId ? _c('div', {
          staticClass: "col-half",
          staticStyle: {
            "align-content": "center"
          }
        }, [_vm.selectablePartsForMegaNeed && _vm.selectablePartsForMegaNeed.length ? _c('AppSelect', {
          attrs: {
            "select-options": _vm.selectOptionsPartReferenceFromMegaNeed,
            "allow-create": "",
            "monospace-selections": "",
            "label": _vm.selectedMegaNeedAssemblyTemplateName ? `Select a part from ${_vm.selectedMegaNeedAssemblyTemplateName}` : 'Select a part'
          },
          on: {
            "option-create": _vm.uiClickOfCreateNewPartReference
          },
          scopedSlots: _vm._u([{
            key: "select-option",
            fn: function ({
              option
            }) {
              return [_vm.findPartReferenceFromId(option.value) ? _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
                ..._vm.findPartReferenceFromId(option.value)
              }, false)) : _vm._e()];
            }
          }], null, false, 3865064791),
          model: {
            value: _vm.selectedPartReferenceIdViaMegaNeed,
            callback: function ($$v) {
              _vm.selectedPartReferenceIdViaMegaNeed = $$v;
            },
            expression: "selectedPartReferenceIdViaMegaNeed"
          }
        }) : _vm._e()], 1) : _vm._e()]) : _vm._e()]), !_vm.showPartReferenceForm && !_vm.selectedPartReferenceIdViaAutoHardwareListing && !_vm.selectedPartReferenceIdViaMegaNeed ? _c('ButtonCreate', {
          staticClass: "mt-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.showPartReferenceForm = true;
            }
          }
        }, [_vm._v(" Create new part ")]) : _vm._e()], 1) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showPartReferenceForm ? _c('div', {
          staticClass: "part_reference_from mt-2"
        }, [_c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Description"
          },
          model: {
            value: _vm.formDataPartReference.drawingDescription,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "drawingDescription", $$v);
            },
            expression: "formDataPartReference.drawingDescription"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Drawing number"
          },
          model: {
            value: _vm.formDataPartReference.drawingNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "drawingNumber", $$v);
            },
            expression: "formDataPartReference.drawingNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "AsBuilt"
          },
          model: {
            value: _vm.formDataPartReference.asBuiltNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "asBuiltNumber", $$v);
            },
            expression: "formDataPartReference.asBuiltNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppSelectEnum', {
          attrs: {
            "display": _vm.SideDisplay,
            "enum": _vm.Side,
            "color": _vm.EntityType.ITEM_MASTER,
            "label": "Side",
            "sort": ""
          },
          model: {
            value: _vm.formDataPartReference.side,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "side", $$v);
            },
            expression: "formDataPartReference.side"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Size"
          },
          model: {
            value: _vm.formDataPartReference.size,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "size", $$v);
            },
            expression: "formDataPartReference.size"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Serial"
          },
          model: {
            value: _vm.formDataPartReference.serialNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "serialNumber", $$v);
            },
            expression: "formDataPartReference.serialNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Lot"
          },
          model: {
            value: _vm.formDataPartReference.lotNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "lotNumber", $$v);
            },
            expression: "formDataPartReference.lotNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth mb-2"
        }, [_c('AppSelectEnum', {
          attrs: {
            "display": _vm.InventoryClassDisplay,
            "enum": _vm.InventoryClass,
            "sort": false,
            "value": _vm.formDataPartReference.itemClass && _vm.formDataPartReference.itemClass[0],
            "label": "Class",
            "monospace-selections": ""
          },
          on: {
            "input": function ($event) {
              _vm.formDataPartReference.itemClass = [$event];
            }
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "Calibration number"
          },
          model: {
            value: _vm.formDataPartReference.calibrationNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "calibrationNumber", $$v);
            },
            expression: "formDataPartReference.calibrationNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppText', {
          attrs: {
            "label": "CEI number"
          },
          model: {
            value: _vm.formDataPartReference.contractEndingItemNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "contractEndingItemNumber", $$v);
            },
            expression: "formDataPartReference.contractEndingItemNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid my-2"
        }, [_c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Birth"
          },
          model: {
            value: _vm.formDataPartReference.birthDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "birthDate", $$v);
            },
            expression: "formDataPartReference.birthDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Calibration"
          },
          model: {
            value: _vm.formDataPartReference.lastCalibrationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "lastCalibrationDate", $$v);
            },
            expression: "formDataPartReference.lastCalibrationDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Usage life expiry"
          },
          model: {
            value: _vm.formDataPartReference.usageLifeExpirationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "usageLifeExpirationDate", $$v);
            },
            expression: "formDataPartReference.usageLifeExpirationDate"
          }
        })], 1), _c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppDate', {
          attrs: {
            "label": "Shelf life expiry"
          },
          model: {
            value: _vm.formDataPartReference.shelfLifeExpirationDate,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "shelfLifeExpirationDate", $$v);
            },
            expression: "formDataPartReference.shelfLifeExpirationDate"
          }
        })], 1), _c('div', {
          staticClass: "col-half mb-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Location"
          },
          model: {
            value: _vm.formDataPartReference.location,
            callback: function ($$v) {
              _vm.$set(_vm.formDataPartReference, "location", $$v);
            },
            expression: "formDataPartReference.location"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('ButtonCancel', {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.clearPartReferenceForm
          }
        }, [_vm._v("Cancel part creation")])], 1)])]) : _vm._e()]), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.computedSelectedPartReference ? _c('AppPartReferenceDisplay', {
          staticClass: "part_display",
          attrs: {
            "part-reference-id": _vm.computedSelectedPartReferenceId
          }
        }) : _vm._e()], 1), _vm.computedSelectedPartReference ? _c('ButtonClearSelection', {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.clearAllPartReferenceSelections
          }
        }, [_vm._v(" Clear part selection ")]) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.lineItem ? _c('ButtonSave', {
          attrs: {
            "isSaving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEmitLineItem
          }
        }) : _c('ButtonCreate', {
          attrs: {
            "isSaving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEmitLineItem
          }
        }, [_vm._v("Add")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(" Cancel "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };