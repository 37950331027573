var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": "",
      "modal-width": "60vw"
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid mb-2 align-center"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "required": "",
            "label": "Name",
            "placeholder": "I-xxx"
          },
          model: {
            value: _vm.formDataAutoHardwareList.name,
            callback: function ($$v) {
              _vm.$set(_vm.formDataAutoHardwareList, "name", $$v);
            },
            expression: "formDataAutoHardwareList.name"
          }
        })], 1), _c('div', {
          staticClass: "col-third"
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Critical?"
          },
          model: {
            value: _vm.formDataAutoHardwareList.isCritical,
            callback: function ($$v) {
              _vm.$set(_vm.formDataAutoHardwareList, "isCritical", $$v);
            },
            expression: "formDataAutoHardwareList.isCritical"
          }
        })], 1), _c('div', {
          staticClass: "col-half mt-2"
        }, [_c('AppText', {
          attrs: {
            "label": "Description",
            "required": ""
          },
          model: {
            value: _vm.formDataAutoHardwareList.description,
            callback: function ($$v) {
              _vm.$set(_vm.formDataAutoHardwareList, "description", $$v);
            },
            expression: "formDataAutoHardwareList.description"
          }
        })], 1), _c('div', {
          staticClass: "col-full mt-2"
        }, [_c('AppListString', {
          attrs: {
            "required": ""
          },
          model: {
            value: _vm.formDataAutoHardwareList.drawingNumberPrefix,
            callback: function ($$v) {
              _vm.$set(_vm.formDataAutoHardwareList, "drawingNumberPrefix", $$v);
            },
            expression: "formDataAutoHardwareList.drawingNumberPrefix"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving,
            "disabled": _vm.shouldDisableCreateButton
          },
          on: {
            "click": _vm.onClickOfCreateNewAutoHardwareList
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Cancel "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };