var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_attachment_viewer"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('c-progress') : _vm._e()], 1), !_vm.isFetching && _vm.transformedAttachment ? _c('AppFilePreview', _vm._b({
    attrs: {
      "file": _vm.file,
      "download-url-for-logging": _vm.transformedAttachment._downloadKey
    },
    on: {
      "log": _vm.logFileView
    }
  }, 'AppFilePreview', {
    ..._vm.$attrs
  }, false)) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };