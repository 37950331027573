import { AttachmentType, Side, UserGroupType, UserRole } from './gql_types';
export * from './gql_types';
export { AttachmentType };
export { Side };
export { UserRole };
export { UserGroupType };
// #region JDoc
export var JDocumentType;
(function (JDocumentType) {
    JDocumentType["J_21"] = "J_21";
    JDocumentType["J_24"] = "J_24";
    JDocumentType["J_26"] = "J_26";
    JDocumentType["OTHER"] = "OTHER";
})(JDocumentType || (JDocumentType = {}));
export const JDocumentTypeDisplay = new Map([
    [JDocumentType.J_21, 'J-21'],
    [JDocumentType.J_24, 'J-24'],
    [JDocumentType.J_26, 'J-26'],
    [JDocumentType.OTHER, 'Other']
]);
// #endregion
