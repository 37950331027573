import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DB_DEFAULT, DEFAULT_DASH, GENERAL } from '~/nasa_ui/constants';
import { getLocation } from '~/nasa_ui/utils';
import { transformLocationResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let QuickDisplayLocation = class QuickDisplayLocation extends Mixins(BaseVue) {
    selectedEntity = null;
    DEFAULT_DASH = DEFAULT_DASH;
    LABEL_WIDTH = '55px';
    gap;
    id;
    width;
    get computedValueWidth() {
        return `calc(${this.width} - ${this.LABEL_WIDTH})`;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformLocationResponseWithRefs([this.selectedEntity])[0] : null;
    }
    canDisplayLocationElement(locationAttribute) {
        return (Boolean(locationAttribute) &&
            locationAttribute !== GENERAL &&
            locationAttribute !== DB_DEFAULT &&
            locationAttribute !== 'UNKNOWN');
    }
    async fetchSelectedEntity() {
        if (!this.id) {
            return;
        }
        try {
            const resp = await getLocation(this.id);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        default: '0'
    }),
    __metadata("design:type", String)
], QuickDisplayLocation.prototype, "gap", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], QuickDisplayLocation.prototype, "id", void 0);
__decorate([
    Prop({
        type: String,
        default: '300px'
    }),
    __metadata("design:type", String)
], QuickDisplayLocation.prototype, "width", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], QuickDisplayLocation.prototype, "fetchSelectedEntity", null);
QuickDisplayLocation = __decorate([
    Component
], QuickDisplayLocation);
export default QuickDisplayLocation;
