var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "modal-width": _vm.computedModalWidth,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.isExternal && _vm.transformedHardwareListPart && _vm.transformedHardwareListPart.partReference && _vm.transformedHardwareListPart.partReference.itemInstanceId ? _c('AppItemInstanceDisplay', {
          attrs: {
            "id": _vm.transformedHardwareListPart.partReference.itemInstanceId,
            "hide-border-bottom": ""
          }
        }) : _vm.transformedHardwareListPart && _vm.transformedHardwareListPart._partReference ? _c('div', [_c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info"
          }
        }, [_vm._v(" This Hardware is listed as external to " + _vm._s(_vm.currentSystemName) + " ")])], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference.drawingNumber) + " ")])])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "AsBuilt"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference.asBuiltNumber) + " ")])])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Side"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._side) + " ")])])], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.transformedHardwareListPart._partReference._description))])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Serial"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._serialNumber) + " ")])])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Lot"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._lotNumber) + " ")])])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Class"
          }
        }, [_vm.transformedHardwareListPart._partReference._itemClasses.length ? _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._itemClasses.join(', ')) + " ")]) : _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.DEFAULT_DASH))])])], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Tool?"
          }
        }, [_c('AppBooleanDisplay', {
          attrs: {
            "value": _vm.transformedHardwareListPart._partReference.isTool,
            "icon-size": "18px"
          }
        })], 1)], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "CEI number"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._contractEndingItemNumber) + " ")])])], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Calbration number"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._calibrationNumber) + " ")])])], 1)]), _vm.transformedHardwareListPart._partReference ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Birth"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._birthDate) + " ")]), _vm.transformedHardwareListPart._partReference.birthDate ? _c('small', {
          staticClass: "d-block"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._birthDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Last calibration"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._lastCalibrationDate) + " ")]), _vm.transformedHardwareListPart._partReference.lastCalibrationDate ? _c('small', {
          staticClass: "d-block"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._lastCalibrationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Usage life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._usageLifeExpirationDate) + " ")]), _vm.transformedHardwareListPart._partReference.usageLifeExpirationDate ? _c('small', {
          staticClass: "d-block"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._usageLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Shelf life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._shelfLifeExpirationDate) + " ")]), _vm.transformedHardwareListPart._partReference.shelfLifeExpirationDate ? _c('small', {
          staticClass: "d-block"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListPart._partReference._shelfLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1)], 1) : _vm._e(), _vm.transformedHardwareListPart._partReference ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Location"
          }
        }, [_vm._v(_vm._s(_vm.transformedHardwareListPart._partReference._location))])], 1)], 1) : _vm._e()], 1) : _vm.isFetching ? _c('c-progress') : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };