var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Watch } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
import { currencyDisplay, isNullOrUndefined } from '~/nasa_ui/utils';
import AppNumber from '../AppNumber/AppNumber';
let AppMoney = class AppMoney extends AppNumber {
    lowestMinAllowed = -Infinity;
    highestMaxAllowed = Infinity;
    baseValue = null;
    // weirdness with the inheritence and accessing the getters computedMin/Max
    // So... i copied this from AppNumber
    defaultRules = {
        isNumber: (value) => {
            if (value === undefined || value === null) {
                return true;
            }
            const pattern = /^\d*\.?\d*$/;
            if (!pattern.test(Math.abs(value).toString())) {
                return `MUST BE A NUMBER`;
            }
            return true;
        },
        min: (value) => {
            if (value === undefined || value === null) {
                return true;
            }
            return value >= this.computedMin || `NUMBER MUST BE ≥ ${this.computedMin}`;
        },
        max: (value) => {
            if (value === undefined || value === null) {
                return true;
            }
            return value <= this.computedMax || `NUMBER MUST BE ≤ ${this.computedMax}`;
        },
        wholeNumber: (value) => {
            if (value === undefined || value === null || value === '') {
                return true;
            }
            const pattern = /^\d+$/;
            return pattern.test(value) ? true : 'INVALID NUMBER. WHOLE NUMBERS ONLY';
        },
        required: (value) => !!value || 'REQUIRED'
    };
    get costForDisplay() {
        return this.value ? currencyDisplay(this.value) : '$0.00';
    }
    get computedLabel() {
        return this.label || 'Unit cost';
    }
    get computedMin() {
        return !isNullOrUndefined(this.min) && this.min >= this.lowestMinAllowed ? this.min : this.lowestMinAllowed;
    }
    get computedMax() {
        return !isNullOrUndefined(this.max) && this.max <= this.highestMaxAllowed ? this.max : this.highestMaxAllowed;
    }
    get propsWithComputedMinMax() {
        return Object.assign({
            ...this.$props,
            max: this.computedMax,
            messages: [this.unitCostMessage],
            min: this.computedMin,
            outline: true,
            ref: 'moneyInput',
            rules: this.localRulesBaseOnProps
        });
    }
    get unitCostMessage() {
        return this.value ? this.costForDisplay : '';
    }
    emitError(val) { }
    onValueChange(value) {
        this.baseValue = value;
    }
};
__decorate([
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppMoney.prototype, "emitError", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], AppMoney.prototype, "onValueChange", null);
AppMoney = __decorate([
    Component
], AppMoney);
export default AppMoney;
