var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', _vm._g({
    attrs: {
      "color": "comments",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.onModalClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Manage Attachments")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm.isLoading ? _c('c-progress') : _c('div', [!_vm.showNewAttachmentForm && !_vm.showAddDocumentAttachmentTable ? _c('div', [_c('ButtonCreate', {
          staticClass: "ml-0",
          on: {
            "click": function ($event) {
              _vm.showNewAttachmentForm = true;
            }
          }
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "slot": "icon",
            "icon": "fad fa-file-plus"
          },
          slot: "icon"
        }), _c('span', [_vm._v("Attach new file")])], 1), _vm.avaiableDocumentAttachments && _vm.avaiableDocumentAttachments.length ? _c('ButtonCreate', {
          staticClass: "ml-0",
          on: {
            "click": function ($event) {
              _vm.showAddDocumentAttachmentTable = true;
            }
          }
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "slot": "icon",
            "icon": "fad fa-file-plus"
          },
          slot: "icon"
        }), _c('span', [_vm._v("Link Document attachment")])], 1) : _vm._e()], 1) : _c('ButtonCancel', {
          on: {
            "click": _vm.goBack
          }
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons.arrow_left
          }
        }), _vm._v(" Go back ")], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.showNewAttachmentForm && !_vm.showAddDocumentAttachmentTable ? _c('AppTableExpandable', {
          staticClass: "mb-3",
          attrs: {
            "headers": _vm.tableHeaders,
            "icon": _vm.$icons.attachment,
            "items": _vm.computedTableItems,
            "hide-pagination": "",
            "item-key": "id"
          },
          scopedSlots: _vm._u([{
            key: "expanded-row",
            fn: function ({
              item
            }) {
              return [_c('AppFileAttachmentForm', {
                attrs: {
                  "attachment": item,
                  "read-only": _vm.isReadOnly,
                  "hide-upload": "",
                  "is-edit": ""
                },
                model: {
                  value: _vm.editForm,
                  callback: function ($$v) {
                    _vm.editForm = $$v;
                  },
                  expression: "editForm"
                }
              }), _c('v-layout', [_c('ButtonEdit', {
                on: {
                  "click": _vm.onClickOfUpdate
                }
              }, [_vm._v("Update attachment")]), _c('v-spacer'), _c('ButtonRemove', {
                on: {
                  "click": _vm.onClickOfRemove
                }
              })], 1)];
            }
          }], null, false, 3011096621),
          model: {
            value: _vm.expandedTableRow,
            callback: function ($$v) {
              _vm.expandedTableRow = $$v;
            },
            expression: "expandedTableRow"
          }
        }) : _vm._e()], 1)], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showNewAttachmentForm ? _c('div', {
          staticClass: "mt-3"
        }, [_c('AppFileAttachmentForm', {
          attrs: {
            "read-only": _vm.isReadOnly
          },
          model: {
            value: _vm.createForm,
            callback: function ($$v) {
              _vm.createForm = $$v;
            },
            expression: "createForm"
          }
        }), _c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.createForm.description || !_vm.createForm.file || _vm.isSaving,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveNew
          }
        }, [_vm._v(" Add new attachment ")])], 1) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showAddDocumentAttachmentTable ? _c('div', {
          staticClass: "mt-3"
        }, [_c('AppTableSelectable', {
          attrs: {
            "headers": _vm.tableHeaders,
            "items": _vm.computedDocumentAttachmentTableItems,
            "loading": _vm.isLoading,
            "color": "grey darken-1",
            "item-key": "id"
          },
          model: {
            value: _vm.selectedDocumentAttachments,
            callback: function ($$v) {
              _vm.selectedDocumentAttachments = $$v;
            },
            expression: "selectedDocumentAttachments"
          }
        }), _c('ButtonSave', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.isSaving || !_vm.selectedDocumentAttachments.length
          },
          on: {
            "click": _vm.onClickOfLinkAttachments
          }
        }, [_vm._v(" Link attachments ")])], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.onModalClose
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }])
  }, {
    ..._vm.$listeners
  }));
};
var staticRenderFns = [];
export { render, staticRenderFns };