var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.CHANGE_SET,
      "force-disable-search": _vm.disableSearch,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "enterPressed": _vm.onClickOfSearchButton,
      "searchButtonClicked": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.showTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.searchResultsTableHeaders,
      "items": _vm.searchResultsTableItems,
      "dense-rows": "",
      "disableInitialSort": "",
      "selectable-rows": "",
      "sticky": "",
      "title": "Matches"
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };