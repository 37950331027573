var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.DOCUMENT,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholderText
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.DOCUMENT,
      "entity-type": _vm.EntityType.DOCUMENT,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.onlyCosmicManaged ? _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppSelect', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isFilterLocked('subType'),
      "select-options": _vm.validTypesForCurrentlyActiveCtxToSelectOptions,
      "label": "Type",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1) : _vm._e(), _vm.onlyCosmicManaged ? _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppSelect', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isFilterLocked('subType'),
      "select-options": _vm.cosmicManagedDocumentTypes,
      "label": "Type",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "pr-2": "",
      "xs6": ""
    }
  }, [_c('TagsInput', {
    attrs: {
      "label": "Has these Tags"
    },
    model: {
      value: _vm.searchFilters.tags,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "tags", $$v);
      },
      expression: "searchFilters.tags"
    }
  })], 1), _vm.onlyCosmicManaged ? _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppContactMultiple', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isFilterLocked('createdByUserId'),
      "label": "Created by"
    },
    on: {
      "contacts-changed": _vm.onCreatedByContactChanged
    },
    model: {
      value: _vm.searchFilters.createdByUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "createdByUserId", $$v);
      },
      expression: "searchFilters.createdByUserId"
    }
  })], 1) : _vm._e(), _vm.onlyCosmicManaged ? _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppContactMultiple', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isFilterLocked('initiatorContactId'),
      "label": "Initiator"
    },
    model: {
      value: _vm.searchFilters.initiatorContactId,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "initiatorContactId", $$v);
      },
      expression: "searchFilters.initiatorContactId"
    }
  })], 1) : _vm._e()], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "mb-2": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isFilterLocked('number'),
      "value-color": _vm.EntityType.DOCUMENT,
      "clearable": "",
      "label": "Direct match of Document number(s)"
    },
    model: {
      value: _vm.searchFilters.number,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "number", $$v);
      },
      expression: "searchFilters.number"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }]
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable && !_vm.onlyCosmicManaged ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "headers": _vm.DocumentTableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "disable-initial-sort": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e(), _vm.shouldShowTable && _vm.onlyCosmicManaged ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "headers": _vm.CosmicManagedDocumentTableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    },
    scopedSlots: _vm._u([{
      key: "_computedStatusIcon",
      fn: function ({
        item
      }) {
        return [_c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedStatusIcon,
            expression: "item._computedStatusIcon"
          }],
          attrs: {
            "tooltip": `${item._computedStatus}`,
            "tooltip-position": "left"
          }
        })];
      }
    }, {
      key: "_dueDate",
      fn: function ({
        item
      }) {
        return [item.cosmicDocumentWorkflow && item.cosmicDocumentWorkflow.dueDate ? _c('div', [_vm.isBeforeTodayInclusive(item.cosmicDocumentWorkflow.dueDate) ? _c('strong', {
          staticClass: "date_wrap error--text"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons.exclamation
          }
        }), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.$dateDisplay(item.cosmicDocumentWorkflow.dueDate)))])], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.cosmicDocumentWorkflow.dueDate)) + " ")])]) : !item.cosmicDocumentWorkflow ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v("—")])];
      }
    }, {
      key: "_initializationStatusIcon",
      fn: function ({
        item
      }) {
        return [_c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._initializationStatusIcon,
            expression: "item._initializationStatusIcon"
          }],
          attrs: {
            "tooltip": `${item._initializationStatus}`,
            "tooltip-position": "left"
          }
        })];
      }
    }], null, false, 3724687438)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };