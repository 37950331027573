<template><section><h1>Change Log</h1>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v6.0.2...v6.0.3">6.0.3</a> (2025-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v52.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b6e02af7bfd7dcd1c364a0bc178fe1ef20e8d0d">8b6e02a</a>)</li>
<li><strong>Schema:</strong> update to v52.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/19d506ec2e3e4d28a2eeddfe865636f1cd3f70ee">19d506e</a>)</li>
<li><strong>Schema:</strong> update to v52.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47c66b169503cd9ca6a5e833cc186878603079e8">47c66b1</a>)</li>
<li><strong>Schema:</strong> update to v52.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/98377fa415b1690eb75748d65d43d23b99f5d88f">98377fa</a>)</li>
<li><strong>Schema:</strong> update to v52.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/79fc9023f8775cade5fc69c8a7971f61914a59fd">79fc902</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v6.0.1...v6.0.2">6.0.2</a> (2025-03-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/673df282a5bfb4116f0b306ec169871fba1b49ca">673df28</a>)</li>
<li><strong>MEGA Deliveries:</strong> added an archived icon to the select box to identify the archived MEGAs in the list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/83dfb305c5cf73f85b28521256cf19a5253aa93d">83dfb30</a>)</li>
<li><strong>Schema:</strong> update to v52.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3616a5c7beb3983e8f253d0f7ca42b470bd9b38f">3616a5c</a>)</li>
<li><strong>Schema:</strong> update to v52.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/13be3e86105d309208f7d55e61cf604d1a4d5c2c">13be3e8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v6.0.0...v6.0.1">6.0.1</a> (2025-03-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>MEGA clone:</strong> correctly forwards the user to the mega allocation page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/66d11e1baa73ffcf38514f2b29241d5a41b87d7b">66d11e1</a>)</li>
<li><strong>MEGA Deliveries:</strong> no longer select the 0th MEGA; allow the user to select (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7488b52b64f93947b90f2d22ae79d4fc9fc14183">7488b52</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.44.3...v6.0.0">6.0.0</a> (2025-03-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>General:</strong> update includes some nice (hopefully) loading indicators (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f1fa063afa6c97432cd9263c45fdc7fd1011fc19">f1fa063</a>)</li>
<li><strong>Schema:</strong> update to v52.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/49ae58d065074dfc4afab9b004914223e1b116b2">49ae58d</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Progress:</strong> ever forward. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9d6ac4c2bbf2981ce3b82d0f23676481d0785b42">9d6ac4c</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Progress:</strong> updated progress indictors throughout the app. no obvious visual change expected</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.44.2...v5.44.3">5.44.3</a> (2025-03-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e57a03867a4a98966708fca86bb7d355d4084978">e57a038</a>)</li>
<li><strong>COSMIC:</strong> Zero-G Deep Clean (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/43966f508d92f56a0f00b59eca66c5023d02546d">43966f5</a>)</li>
<li><strong>Schema:</strong> update to v52.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/92d43df10883e3f364330de22170e2c311e5ee6e">92d43df</a>)</li>
<li><strong>Schema:</strong> update to v52.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7da847ece3cd15e8b7dcd2ee89faee643f056bac">7da847e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.44.1...v5.44.2">5.44.2</a> (2025-03-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> update tooltip hover timing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/90764f6b1f3e771b3881479cd077dd9cff394686">90764f6</a>)</li>
<li><strong>Allocation matrix:</strong> update tooltip hover timing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/65a6b92fabe5984dec91b5967a840cf8d04e3c2d">65a6b92</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.44.0...v5.44.1">5.44.1</a> (2025-03-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> corrects issue loading allocation window without a endDate (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f565e310e5ec1c9194324a13e131c88e1ea19a04">f565e31</a>)</li>
<li><strong>COSMIC:</strong> Contracts update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/65e2f041a8bbfcc008a787a50a68a2a66f36825b">65e2f04</a>)</li>
<li><strong>MEGA allocation:</strong> update report to use new dedicated endpoint -&gt; speed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0ca06fb174ebfca594c807430199f9594b5f5372">0ca06fb</a>)</li>
<li><strong>MEGA Deliveries:</strong> update report to use new dedicated endpoint -&gt; speed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/25a15928ef8cf94bfb5a4ca2c0f2d56a3e2d9ce3">25a1592</a>)</li>
<li><strong>Schema:</strong> update to v51.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/81eb7e8e9f4e2ebfcb6f24f9f6bd766775d695a7">81eb7e8</a>)</li>
<li><strong>Schema:</strong> update to v51.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bdc0ca6f59167b9cc43d58b547768db7e10fb04f">bdc0ca6</a>)</li>
<li><strong>Schema:</strong> update to v51.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/02898d012ddee529cb7c6ea0dcd03f6a7598c8d8">02898d0</a>)</li>
<li><strong>Schema:</strong> update to v51.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/25af9fd6f2e8c8fd454200a500b3dd8029121608">25af9fd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.43.0...v5.44.0">5.44.0</a> (2025-03-06)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dc10c018672b63e43da5241aa84f59d977f372ea">dc10c01</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/923b46fe92f0fac345ff4d70a12733a819a6b146">923b46f</a>)</li>
<li><strong>DO Search:</strong> update wording to be inline with the show closed check box (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9a8d7d6f95e4441140f768a9c684dc79efbe93d8">9a8d7d6</a>)</li>
<li><strong>Schema:</strong> update to v51.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fae89e3c17635e6f3c1c185885c47d618cc15978">fae89e3</a>)</li>
<li><strong>Schema:</strong> update to v51.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f6b82869f83d0ad05362e5df1839631b29daed02">f6b8286</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>MEGA deliveries report:</strong> added need date to report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/441247e14d504dd17f852da3c6893c14eafedb72">441247e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.42.2...v5.43.0">5.43.0</a> (2025-03-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Part reference:</strong> update allows Location to be displayed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b65749183dd7d9a81d2a82f483d8a0d6707b623b">b657491</a>)</li>
<li><strong>Schema:</strong> update to v50.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ddb9864b68b9a1ad57aa616119f59bbda9439275">ddb9864</a>)</li>
<li><strong>Schema:</strong> update to v50.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5ba72b0f9594b98552b5a94dba649bf00d81bd6">d5ba72b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Reporting:</strong> new MEGA Deliveries exported in pdf format (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6f08d41f1ef028e4dd680944503eb701a3a4da14">6f08d41</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.42.1...v5.42.2">5.42.2</a> (2025-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> update mixin that controls the foreign key linkages (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c993c0303bd7a278b1a8ebfeaed325e6193123ac">c993c03</a>)</li>
<li><strong>Delivery matrix:</strong> corrected issue where updating the ECD cleared the planned expiry date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b8f206b36856f2facd3359d2c97941e6050e560b">b8f206b</a>)</li>
<li><strong>Delivery matrix:</strong> multiple edit mode fixes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/daedf140b74f2d47bde2a1c72f36bc9c1df19e1d">daedf14</a>)</li>
<li><strong>Delivery matrix:</strong> show completed boolean flip (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b77c84a8c09aae8f69ad75809e8aeba010e645e8">b77c84a</a>)</li>
<li><strong>Delivery orders:</strong> update wording and filter for closed-ness (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e14bcec5f7f89fe22b33f515d6241b94422e1892">e14bcec</a>)</li>
<li><strong>MEGA allocation:</strong> corrects error computing the window end date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/89291cd1cb4bd1ddb42c7ae0f121788f910cbd71">89291cd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.42.0...v5.42.1">5.42.1</a> (2025-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly template creation modal:</strong> update adds item listing input (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6ea011c1436d8e700ec8924f0c88814f5612abb9">6ea011c</a>)</li>
<li><strong>Delivery matrix:</strong> update fixes ui overflow issues and spacing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/761af94228f91eac47e900e9ad59cb1317b91f7b">761af94</a>)</li>
<li><strong>DO:</strong> info tab spacing update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e5ba671b2ef1e298484d9957397c8028f4955456">e5ba671</a>)</li>
<li><strong>MEGA allocation:</strong> update changes wording and corrects spacing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f9d5d9399b04cb65835743843e8996579ab365f9">f9d5d93</a>)</li>
<li><strong>MEGA Deliveries report:</strong> ui tweaks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0fdf6affa26dc24e2ec82ada785cb566a8ab0adf">0fdf6af</a>)</li>
<li><strong>Quick part reference display:</strong> update to include description (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a24ce097055079b05808bead054434848dc209d2">a24ce09</a>)</li>
<li><strong>Schema:</strong> update to v50.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/337c7d3004407e7bbab42c1028431e178125fbc3">337c7d3</a>)</li>
<li><strong>Schema:</strong> update to v50.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ddea469f10b8f24665dee35a3f91238c565ab350">ddea469</a>)</li>
<li><strong>Schema:</strong> update to v50.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/23e87ddd0e1c93c0f0c0ecc1837ca691e9621d12">23e87dd</a>)</li>
<li><strong>Schema:</strong> update to v50.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f8043d220c30abeb72ed8ee6ff13a9e6f067e479">f8043d2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.41.3...v5.42.0">5.42.0</a> (2025-02-27)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly template:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a06be1c4e10afdfc7dcb7478190f513f9e8f8919">a06be1c</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>MEGA Deliveries:</strong> column update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/96975817928ecaa12f27b2282c5620f090ba416c">9697581</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.41.2...v5.41.3">5.41.3</a> (2025-02-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7747e7be67b7b2d7bd6766ce0bc35787c8ab6e37">7747e7b</a>)</li>
<li><strong>Schema:</strong> update to v49.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e5780f7b0aecbe1600a47bf379d569a56315ba5f">e5780f7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.41.1...v5.41.2">5.41.2</a> (2025-02-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/16105149dfd27608fce8ce77dde408c8c97153d8">1610514</a>)</li>
<li><strong>Schema:</strong> update to v49.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/34e1b40a87d0e1c29177fdcfbe2e80f4ebea1110">34e1b40</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.41.0...v5.41.1">5.41.1</a> (2025-02-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Cache:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/32069ee8cebcf5b240b758502ed5980b6b6b7b81">32069ee</a>)</li>
<li><strong>Import:</strong> update to correct MEGA upload (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/73290d71055d4e7547edf231fed55b1869e71a6a">73290d7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.40.3...v5.41.0">5.41.0</a> (2025-02-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9a9ac0f9dbe205e1118bff12fd7daf361ad6b68a">9a9ac0f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Cache:</strong> update to caching mechanism and keys (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c015a0cc976ca8ea3551e4a67cf5b0c5ccac3cb8">c015a0c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.40.2...v5.40.3">5.40.3</a> (2025-02-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item listings:</strong> improve performance on large data sets (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9237f60b6e44789d5a54b70b7e85177cafb1abbc">9237f60</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.40.1...v5.40.2">5.40.2</a> (2025-02-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/024bf898037ed28e232141faf72368d9da099e9a">024bf89</a>)</li>
<li><strong>Schema:</strong> update to v49.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7b3348a74e060ec00b17778e848d2f67892abaf9">7b3348a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.40.0...v5.40.1">5.40.1</a> (2025-02-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Clone MEGA:</strong> update to add option to clone the mega allocation type (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c1ab5bbf08b84612ec7577e017e2458269046933">c1ab5bb</a>)</li>
<li><strong>Schema:</strong> update to v49.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c6d83f9b87c76a0914433625f77d0c07240a8e29">c6d83f9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.39.1...v5.40.0">5.40.0</a> (2025-02-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> implement new display component that should standardize the label/value look (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ca30014fa5cecc657b79db2429fc919f055081dd">ca30014</a>)</li>
<li><strong>Delivery matrix:</strong> implement new display component that should standardize the label/value look (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f55df46e6e04c58ea2d5e0d956ca0bca662d3479">f55df46</a>)</li>
<li><strong>MEGA allocation:</strong> implement new display component that should standardize the label/value look (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a7af76a9a1a096eeb221dc5eb91678e1df0a06a0">a7af76a</a>)</li>
<li><strong>MEGA deliveries report:</strong> implement new display component that should standardize the label/value look (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b4679140afe6bfa93a2afe604d1f40ed47e13849">b467914</a>)</li>
<li><strong>Permissions:</strong> initial implementation featuring 3 permissions (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f3ef4103b7e3f50ed721db5ec9d176331656a50b">f3ef410</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.39.0...v5.39.1">5.39.1</a> (2025-02-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/02d75508c7aa484bd4e98a00b74607cbd816c63a">02d7550</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.38.4...v5.39.0">5.39.0</a> (2025-02-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>MEGA deliveries:</strong> update to this report to display the correct data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b40ffd50ca19399299d94c69d7408b8e9258fff3">b40ffd5</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> added a print button to the top left (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0810bdbaa3c032778150b44bd3891384512056bb">0810bdb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.38.3...v5.38.4">5.38.4</a> (2025-02-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> corrects issue where the notes icon became unclickable (nearly) (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b8c17c974ac2b0c6d5f50355076ac15ccf69f91e">b8c17c9</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fff0abee4874fdbd4a22565a38b6d740b058f140">fff0abe</a>)</li>
<li><strong>DO line item:</strong> update the modal to identify when the user editing multiple items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7553c8aeba2187536de50a7a3888c5d279f3857c">7553c8a</a>)</li>
<li><strong>Iconography:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5712b8697404f5c63e31941319c61c1a50b1facd">5712b86</a>)</li>
<li><strong>Schema:</strong> update to v49.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/23c35d47b18a4e5a16b126fe7d239ee515cbb83d">23c35d4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.38.2...v5.38.3">5.38.3</a> (2025-02-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Code:</strong> cleanup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ebd46fffb2a377fbde89de99979bc60993fa913b">ebd46ff</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ff6259f6adab6a32ca8d0e63880602a757ec5b26">ff6259f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.38.1...v5.38.2">5.38.2</a> (2025-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> grammar (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4c846ee056a189fb683b1a3a07c69742abfc5ab9">4c846ee</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.38.0...v5.38.1">5.38.1</a> (2025-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> reorder boxes and update wording (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/258e4a2b3ad90698cd0fc86f9f0ec3fc7c4b8ca4">258e4a2</a>)</li>
<li><strong>MEGA Allocation:</strong> updated the structure so that the details of the MEGA are in its own box not mixed in with the Alerts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6e26527d0007a7cce865f046bfbbfe925e222ccc">6e26527</a>)</li>
<li><strong>Success note:</strong> adding a boring corporate success note (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/90da808591c5b5d1589af082d1415c5ae7acc2cd">90da808</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.37.4...v5.38.0">5.38.0</a> (2025-02-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> improvement to alerting and mitigation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e5606fee520dd991510844f48661f0529034af53">e5606fe</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.37.3...v5.37.4">5.37.4</a> (2025-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> reword fields for clarification (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f599bcbc9869f6a1b546143c0095e97135d883d9">f599bcb</a>)</li>
<li><strong>Modals:</strong> no longer force a refetch when closed without saving (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a4d3816b84dda69e376e61d260b234856506cbb0">a4d3816</a>)</li>
<li><strong>Schema:</strong> update to v49.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a875f190896c60625524ceb6bfc6aa66c8baddf2">a875f19</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.37.2...v5.37.3">5.37.3</a> (2025-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> clarify names of things; added helpful (hopefully) tooltips to values (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d915b695838fde8d24d0e604f1e45478d96d6a93">d915b69</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.37.1...v5.37.2">5.37.2</a> (2025-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> logic for planned expiration date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/38d10af04de9a7b12d0ac86c171ac0655e6f2fb9">38d10af</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.37.0...v5.37.1">5.37.1</a> (2025-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> logic for usage life expiry (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7222d0207c906fc29995586eaad6e2ba2cc696a2">7222d02</a>)</li>
<li><strong>Allocation matrix:</strong> rearrange data in each cell; delivery orders are now displayed per part (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dadfad117f40478225fe7ac981b973fec59badc2">dadfad1</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.36.0...v5.37.0">5.37.0</a> (2025-02-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>Notes:</strong> better notes feature (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/52aa24fa7d5da15c5ad32e305443f604df367bd9">52aa24f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.35.2...v5.36.0">5.36.0</a> (2025-02-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>Notes:</strong> ability to update part notes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/58601c46a0ccec15006c9b3b9408f1cd07fdaa09">58601c4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.35.1...v5.35.2">5.35.2</a> (2025-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> display inherited need if needed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/100c066768b659cad592b68d67f03df97ccc43a4">100c066</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0d49939caf6afb432ea1863e451ac52ee0fd9b24">0d49939</a>)</li>
<li><strong>Delivery matrix:</strong> added filtering and columns (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/775118ea9cdff3ed89c92ae928b2e590679a6c0c">775118e</a>)</li>
<li><strong>Menu lists:</strong> update adds external icon to the  menu items that link to another page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3fc3c62b933db19f76fadd0e709d129974ed4995">3fc3c62</a>)</li>
<li><strong>Modals:</strong> adjusting default openedness of cards; allow closure of modal without requerying the db (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2444a86ad231f86a8a1241cc1e3808ba5bf26199">2444a86</a>)</li>
<li><strong>Schema:</strong> update to v49.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/eff83d3c5ffb6be5b869b4e0d5b20a9306ede6bb">eff83d3</a>)</li>
<li><strong>Schema:</strong> update to v49.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc79d1c8da18250e8136cff2534547b7001518e7">fc79d1c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.35.0...v5.35.1">5.35.1</a> (2025-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> remove toggle for LL and remove location from part view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/59597663b7ce20b13cb352a87586e372deba3e79">5959766</a>)</li>
<li><strong>Allocation type update:</strong> corrected internal event name on this modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8bc02a31b0ddef594d422dae7ff179acee26810f">8bc02a3</a>)</li>
<li><strong>MEGA allocation:</strong> updated wording to match the allocation matrix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6850c1ef071891650a94c8b4a8c4eb3d0933c39e">6850c1e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.34.0...v5.35.0">5.35.0</a> (2025-02-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DO line item modal:</strong> open part selection by default (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/89accf17b964918e2f99d2e62e7d13f1567c5cbf">89accf1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> rebranding the quilt (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/35b1a38d1e9b40c6890d14f1d50cf80cd9a0f181">35b1a38</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.7...v5.34.0">5.34.0</a> (2025-02-06)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dep update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a562cc99daca75ebf93104e365880dc864e54129">a562cc9</a>)</li>
<li><strong>Part selection:</strong> UI loading of available parts on part selection modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ef3db42c9cb5b3058fd95523280db50d5786abb5">ef3db42</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item listings:</strong> ability to edit multiple items on a list at once; filtering the list of parts as well (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/06aea060961f7308589ea6657f48e3aa1d8b114d">06aea06</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.6...v5.33.7">5.33.7</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Home page link update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d59e340c00c71ef64a94deabffafb18b174cb686">d59e340</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.5...v5.33.6">5.33.6</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Query update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3b4c9bd4b2a9d7e2db734000569adbef16facf1d">3b4c9bd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.4...v5.33.5">5.33.5</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4b36d74225a7a7ce6bddc3ea91312bd097f9ee19">4b36d74</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.3...v5.33.4">5.33.4</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0e9238d613e196b56522b41216a355662f5ed664">0e9238d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.2...v5.33.3">5.33.3</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4b3b32933c2bff9bde49bcd3c3c26a0f9e4cce56">4b3b329</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.1...v5.33.2">5.33.2</a> (2025-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c86416b485fe00cb8f698842cf48c205fc64806c">c86416b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.33.0...v5.33.1">5.33.1</a> (2025-02-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DO modals:</strong> update for consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6939e1f07ee48bd9285466b2eb3778d926ae1925">6939e1f</a>)</li>
<li><strong>Full parts list:</strong> corrected share link issue (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c61020d056af9aec11581bb6797e730b763403e5">c61020d</a>)</li>
<li><strong>Import:</strong> update with words (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7e8d645fc8d4775c97a5d94ecd49533d5fa2e7d">f7e8d64</a>)</li>
<li><strong>Item listing:</strong> correct pill overflow (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a2262945b16c7f33d05311f019feb9e5a36f2cb7">a226294</a>)</li>
<li><strong>Menu standards:</strong> implemented. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5392120cd1383f651eae1b10611973dc70e6625c">5392120</a>)</li>
<li><strong>Schema:</strong> update to v49.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e053667ba8f97c4e46fc6287c20328be207921e5">e053667</a>)</li>
<li><strong>Schema:</strong> update to v49.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f4236a4c1a6846dcf5496ef34da20cad6441ce35">f4236a4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.32.0...v5.33.0">5.33.0</a> (2025-02-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/84b162f0088c2e9947854a20a3ebbd767c8d9f86">84b162f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery orders:</strong> ability to edit part from DO manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ef809f9107c8183014ea0b473c2d0f9473c5dda2">ef809f9</a>)</li>
<li><strong>Delivery orders:</strong> ability to reopen a previously closed line item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/484fa5f28f97db96a838bfd590c3da745bafd5d8">484fa5f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.31.1...v5.32.0">5.32.0</a> (2025-02-03)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.12.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4f59ca253f9c639527a395b6809622aeaebb5f74">4f59ca2</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>MEGA Allocation:</strong> date logic for alerts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a8017ec58c384d6217762642e9faa377577df9f9">a8017ec</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.31.0...v5.31.1">5.31.1</a> (2025-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d6cd561c60d433329d2226f52de0bddbca9d8a1a">d6cd561</a>)</li>
<li><strong>Schema:</strong> update to v48.10.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6781f3578637735f8fd707c75acda18a484d8306">6781f35</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.30.4...v5.31.0">5.31.0</a> (2025-01-29)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1cd462b83a0b8fb5654e449542b2ca1b689035e9">1cd462b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Planned expiration:</strong> implemented the planned expiration date on delivery order line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9701e3374958d06e95964ffb4f2a2ee07bb343f0">9701e33</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.30.3...v5.30.4">5.30.4</a> (2025-01-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>MEGA edit:</strong> update part selection (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/584636c94123e036c31366c1a8e8a6a5257e9cf1">584636c</a>)</li>
<li><strong>Schema:</strong> update to v48.10.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c69a421e52965b2b618a817e6774491dfe66278b">c69a421</a>)</li>
<li><strong>Schema:</strong> update to v48.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/18a2f8460d2173cf662c68113ec8986be510891d">18a2f84</a>)</li>
<li><strong>Schema:</strong> update to v48.9.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bad177ed463708f7a3f7a69864f5803f52f73778">bad177e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.30.2...v5.30.3">5.30.3</a> (2025-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.8.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f1d4e3bd49aee67fab0d822e44134069ba23634">7f1d4e3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.30.1...v5.30.2">5.30.2</a> (2025-01-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> makes some modals extra pretty with data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3e533d91f0581ed29a94f9d158c172e4c086ed53">3e533d9</a>)</li>
<li><strong>Delivery matrix:</strong> toggle for viewing only my delivery matrix items by task owner (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1e2e79dcfe251c0e0e66e1535a9ec5e027db500e">1e2e79d</a>)</li>
<li><strong>Delivery order line item modal:</strong> update to improve details (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e9d7006a3fed570e291ca8d80ebadc81b4d12cba">e9d7006</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.30.0...v5.30.1">5.30.1</a> (2025-01-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Parts:</strong> cleared up some display issues and betterified the part selection area (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f76d30502e360da14cccf24d5fd8a5799e6e4813">f76d305</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.7...v5.30.0">5.30.0</a> (2025-01-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.7.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4e773e9bab02e5574b5ab6ea8ac0a8e16c5c6503">4e773e9</a>)</li>
<li><strong>Schema:</strong> update to v48.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/98174162371333ac5897984eb34f41433ba49192">9817416</a>)</li>
<li><strong>Schema:</strong> update to v48.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/083349fd4b9e559f51ff9074b3c4b5bfdeef2671">083349f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> update combines what was previously 2 view into 1 and adds features for viewing info (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aed6217c530e578177b0627726144a7a0c7ef3e5">aed6217</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.6...v5.29.7">5.29.7</a> (2025-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/348e458cdc2991f66278c7473866a7ebabc06031">348e458</a>)</li>
<li><strong>Home:</strong> fixed ux eye twitchy thing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f539622c536c09e49b6992fa4cec1318800e28c">7f53962</a>)</li>
<li><strong>Schema:</strong> update to v48.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e14ba9e33902301b52a2a5495c3f34bd7224f3fe">e14ba9e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.5...v5.29.6">5.29.6</a> (2025-01-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> updated view with links (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4a7c30cd6071ac7cc79889512c0a994ea78e313a">4a7c30c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.4...v5.29.5">5.29.5</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item listings:</strong> corrects visual bug if there are greater than 1 drawing number prefix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c1863519c48fc621ac16d90b80fc36a9ff85be5c">c186351</a>)</li>
<li><strong>Parts:</strong> general update from demo (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b28c3775f4e32736347b3e8ac9a52e1dde172db4">b28c377</a>)</li>
<li><strong>Schema:</strong> update to v48.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1521b41f94af42d33c07cc4e2657ca5c784b6842">1521b41</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.3...v5.29.4">5.29.4</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>MEGA Allocation:</strong> update with tree view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7e691098ff11b11d578d0965d148aa23b8ed8b57">7e69109</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.2...v5.29.3">5.29.3</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Part reference selection:</strong> update dropdown list display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/eb272f8ac46456ef92b0c6440a9275bc738c44be">eb272f8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.1...v5.29.2">5.29.2</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/07dad5dd3d5ab7f8d4fc68d45b47b625b6659887">07dad5d</a>)</li>
<li><strong>Part references:</strong> update adds notes display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/37d37988e29c588958fdce9177a506d239bbfca9">37d3798</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.29.0...v5.29.1">5.29.1</a> (2025-01-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Full part reference report:</strong> corrects info/edit icons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e74ca436f7b2b0598f636f0799c0e32e2833fffe">e74ca43</a>)</li>
<li><strong>Item listings:</strong> further ui improvements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0730bcf8d69ce0b5f5463a36409f744bb67e95d7">0730bcf</a>)</li>
<li><strong>Item listings:</strong> update brings visual consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2a84e1097a213f17efa03b4e7c9450ec05c3310a">2a84e10</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.28.1...v5.29.0">5.29.0</a> (2025-01-17)</h1>
<h3>Features</h3>
<ul>
<li><strong>Full part reference report:</strong> new report lists all part references in the system with the ability for the user to view info or edit details (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/46bd3c17aa7ac2cfde69c58949db56eb39aa16bc">46bd3c1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.28.0...v5.28.1">5.28.1</a> (2025-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> update how you enter parts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f36fe66806983f4bf327c431e4f3646259ca392d">f36fe66</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.27.0...v5.28.0">5.28.0</a> (2025-01-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/67fb7410711512af4d0eec3bfc5865b2eecc3d90">67fb741</a>)</li>
<li><strong>Schema:</strong> update to v48.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5aeb1ea50efad6829e45f16d44f63c634a251a3b">5aeb1ea</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery order:</strong> update information tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2e3b3a90c59e33c2c3fae26dfbdd9a39838a14b5">2e3b3a9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.26.0...v5.27.0">5.27.0</a> (2025-01-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> truncate text that runs over its container (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/206becb9cbca6564f0fed99473855ec857160ba5">206becb</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Allocation:</strong> ability to set a MEGA as archived; implemented all new features into the single view as well as the lil Sebastain view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/62025db0b4cba5637d213ab34309dca8d5b3535c">62025db</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.25.0...v5.26.0">5.26.0</a> (2025-01-14)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> adding DIL DN SN to the comment made when the ECD changes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/efc2c7137004de05e81af06a3a6e36fd208a00cd">efc2c71</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> updates to Line item's estimated completion date are now manually tracked via a SYSTEM type comment anytime the date changes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9b02459390331eae8f5938d3b29eed2ac86b2a35">9b02459</a>)</li>
<li><strong>Mega allocation type:</strong> implemented mega allocation type (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c4995685160f44f2414674b419ad103a325e6445">c499568</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.24.0...v5.25.0">5.25.0</a> (2025-01-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> sticky headers and hidable DO info (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9a68cb7a773d39496a3830d2ad5761d500cd3987">9a68cb7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.6...v5.24.0">5.24.0</a> (2025-01-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Allocation matrix:</strong> display DO for selected parts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/313b5ab99025212fa695d8b53bae6b0ee1e7751b">313b5ab</a>)</li>
<li><strong>Allocation matrix:</strong> display error when ecd &gt; computedNeed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8ff910043b0ef1091332b20dd1e0c4f3dc3f1ea2">8ff9100</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.5...v5.23.6">5.23.6</a> (2025-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> adds limited life information (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fdb82bce0fe70906dfbf84d415c5d6f3559a0696">fdb82bc</a>)</li>
<li><strong>Allocation matrix:</strong> update adds support for multiple part selection (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dc6dac2400c3ed19b51a3c2d710da0fb193df1a9">dc6dac2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.4...v5.23.5">5.23.5</a> (2025-01-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> added link to view detailed MEGA (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/10bf1667872dd9685277342ca87a3752c2e048e5">10bf166</a>)</li>
<li><strong>Allocation matrix:</strong> implemented allocation window create/edit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c214744b97d8ad031d525f4146e9bb5cbbe67167">c214744</a>)</li>
<li><strong>Allocation matrix:</strong> implemented allocation window toggles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c49112c5e253ee8763363c727291dddc3ae5cdca">c49112c</a>)</li>
<li><strong>Allocation matrix:</strong> update ux (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b4b53a751e0c798814ed19d6ca203018743e5137">b4b53a7</a>)</li>
<li><strong>MEGA Clone:</strong> componentized the modal for this and implemented it (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4a43568f33926b23c0e250c7394ea756f720d275">4a43568</a>)</li>
<li><strong>Need date update:</strong> componentized the modal for this and implemented it (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/48d894b451b5007e485e86c9bf8b0291a5269238">48d894b</a>)</li>
<li><strong>Schema:</strong> update to v48.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9e9aa1999b7a7a94eaf4a30eb7ef41113d6dd765">9e9aa19</a>)</li>
<li><strong>Schema:</strong> update to v48.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9ba571a4d7b4992835473fce2f67092d56e31816">9ba571a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.3...v5.23.4">5.23.4</a> (2025-01-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Allocation matrix:</strong> adds item listing view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8ee8ee475aa8d73cbcaa567119dbc5824bd26566">8ee8ee4</a>)</li>
<li><strong>Allocation matrix:</strong> update adds the correct color to the need date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/93bfda510b9bf4885af5dfb7a95a8595725c2358">93bfda5</a>)</li>
<li><strong>Events:</strong> corrected bug where editting an Event would create a new one (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ce256c458e1b57adcd561c3df312f1605c016154">ce256c4</a>)</li>
<li><strong>MEGA allocation:</strong> correct navigational issue between quilt and single (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7e0218dce9252595d7563c9d265669e2f1fd32e3">7e0218d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.2...v5.23.3">5.23.3</a> (2025-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e91f444e1f72333105ce2a7f55a97e09f4a879cb">e91f444</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.1...v5.23.2">5.23.2</a> (2025-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c4a55c8edfd2cf35429e35a1813f1c7680dadc51">c4a55c8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.23.0...v5.23.1">5.23.1</a> (2025-01-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/19cd9a5c71a5ca575475f2618c9712c86832b046">19cd9a5</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.22.4...v5.23.0">5.23.0</a> (2025-01-08)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/36b1d84fcce862ead574efbb7b0b4ddcdd1bbd30">36b1d84</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Allocation Matrix:</strong> initial commit with basic functionality (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6d4fd4f8e00938470f2d34cf60c7fcdd12cb5d16">6d4fd4f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.22.3...v5.22.4">5.22.4</a> (2025-01-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item Listings:</strong> refactor improves code (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d31a8ab7c59cd375a75e0d345f26375221a4950c">d31a8ab</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.22.2...v5.22.3">5.22.3</a> (2025-01-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> adding labels to dropdowns. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/04c11b972595c7743b25eb39fc0cef5efa904d24">04c11b9</a>)</li>
<li><strong>Criticality:</strong> added ability to define a list of hardware as being critical (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/01e5022c6d56fb606d3d57d166bed63f9b74e2d4">01e5022</a>)</li>
<li><strong>DO line item:</strong> update corrects schema misconfigurations (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5f05d2de9e6bb70389914ee88faaf055a4c8789b">5f05d2d</a>)</li>
<li><strong>Schema:</strong> update to v48.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/61f6eefede8be422d19dba50eaa8aa142bf65746">61f6eef</a>)</li>
<li><strong>Schema:</strong> update to v48.1.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b08f2ab03d94e8f41f44a099e732b2b3d995ef0">8b08f2a</a>)</li>
<li><strong>Schema:</strong> update to v48.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c29a64d9bf41bc4595fa635cf2835dedb5ab5905">c29a64d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.22.1...v5.22.2">5.22.2</a> (2025-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d13554f6d1a11c3e36a538225c1853a2eb8cefb7">d13554f</a>)</li>
<li><strong>Schema:</strong> update to v48.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/11e77b6cb1d885073447a0be06bb5e37ae589d93">11e77b6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.22.0...v5.22.1">5.22.1</a> (2024-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1832dd72eaf31863df0b52fa0984d911ed6daafe">1832dd7</a>)</li>
<li><strong>Mega deliveries:</strong> updates to the report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b4d16da873e64098e92387ed818ba2759c74fae1">b4d16da</a>)</li>
<li><strong>Refactor:</strong> update and standardize code (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ecc4d52a0870763cf93095303b5877949510a5dc">ecc4d52</a>)</li>
<li><strong>Schema:</strong> update to v48.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf5bd19d0a4a542769dc6f3a1e6ef1ee14b01911">bf5bd19</a>)</li>
<li><strong>Schema:</strong> update to v48.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9d37213d3b53942faa333119425ecd6f7f35264d">9d37213</a>)</li>
<li><strong>Schema:</strong> update to v48.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fd1e74f5e203e8fb69f449a336af8fbba738eddd">fd1e74f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.21.0...v5.22.0">5.22.0</a> (2024-12-17)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/06457f8eab7de3985991158b3b253f7e3cdd3d5f">06457f8</a>)</li>
<li><strong>Mega Allocation:</strong> update adds the ability to clone (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c10acf027b0c0a2d15c1dd7e7dec49c429e834ba">c10acf0</a>)</li>
<li><strong>Mega Allocation:</strong> update functionality. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7e98efa85694b8982b2c28aa39044934710e0c36">7e98efa</a>)</li>
<li><strong>Need:</strong> related fixes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0992a700d9dbc7051cf55238d57325f019614a46">0992a70</a>)</li>
<li><strong>Schema:</strong> update to v48.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2ab4c56ef8327b5003852105644a8bd643a1669b">2ab4c56</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Mega deliveries:</strong> new report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/612813b556e1a58509cf4c3a8b6b13166d2536ed">612813b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.2...v5.21.0">5.21.0</a> (2024-12-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> update activates commenting on a MEGA (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2a4a1c7d8617819add98a9c58633432f6e234894">2a4a1c7</a>)</li>
<li><strong>Mega allocation:</strong> ability to comment on a mega leaf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6e562237e045a1c1c99cac2f6f1c082c70429f13">6e56223</a>)</li>
<li><strong>Mega allocations:</strong> adding toggles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a850200a3ae0e8954353425ec7daab75f71510e0">a850200</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0-at-waiver-date-hotfix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/41270aa8b986ebfe349e947e25522c6f09e8a38d">41270aa</a>)</li>
<li><strong>Schema:</strong> update to v47.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/92294328796c790f70f01f0ae156e9adc042cb76">9229432</a>)</li>
<li><strong>Schema:</strong> update to v47.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/75080b6d40bf3fd37dd90712e935d9ed9d57c887">75080b6</a>)</li>
<li><strong>Schema:</strong> update to v47.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/834b364ed71514b731f1a0b4f20b51a66c87f892">834b364</a>)</li>
<li><strong>Schema:</strong> update to v47.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f7361386911c558feb4da41c49e713c9bfaf040">7f73613</a>)</li>
<li><strong>Schema:</strong> update to v48.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7f05483f1b7711fb62db0e4e122a3b6abe27eabd">7f05483</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Mega allocation:</strong> ability to manipulate a single MEGA and corresponding data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b939ea82b556053aef6396ece88894ea89a80dac">b939ea8</a>)</li>
<li><strong>Schema:</strong> update to v48.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4f381dec1e98263e348103050ac81f26d2a26c85">4f381de</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.1...v5.20.2">5.20.2</a> (2024-12-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mega allocation:</strong> update to add events (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e4e0296e7341d609d0913bea844ebc1416b3a0a6">e4e0296</a>)</li>
<li><strong>Mega allocation:</strong> update to add events (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dca3c96d2f94936e9a6aff5ad14eb111905feb7f">dca3c96</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e0a2f96dd6d87159d106a2db8705adfbbce5820b">e0a2f96</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.20.0...v5.20.1">5.20.1</a> (2024-11-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/467b95e1910bfbd6e466928b98dad5ab4f560f99">467b95e</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/185e2fabe13ed787ad1e93b94430a6a1c4fd7f82">185e2fa</a>)</li>
<li><strong>Delivery Matrix:</strong> updated column for item list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/19d9694528e0c220dc1d785ebbc8688843e3e7bd">19d9694</a>)</li>
<li><strong>Delivery Order:</strong> updating column for item list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5d39d530493a9331917eebfca9d7b1c8ca1da77">d5d39d5</a>)</li>
<li><strong>Schema:</strong> update to v45.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bc8ba35cd25c15cdaa193dc7ea9b4fcf62b18291">bc8ba35</a>)</li>
<li><strong>Schema:</strong> update to v45.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3ae30f7cccc8dec85a6cf883579d714085b3b2ef">3ae30f7</a>)</li>
<li><strong>Schema:</strong> update to v45.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b19ba59b197075fd07a27e36b916122e67ab8ec4">b19ba59</a>)</li>
<li><strong>Schema:</strong> update to v45.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/84ba192b08f9ef6320a2ee88a473bddfeb6861cf">84ba192</a>)</li>
<li><strong>Schema:</strong> update to v45.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/26ec04177a7428898317ad56ba8552f2f42fcc12">26ec041</a>)</li>
<li><strong>Schema:</strong> update to v45.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6c45f1f513752aca1041afd14f0e1d43a89bf211">6c45f1f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.19.1...v5.20.0">5.20.0</a> (2024-11-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/35558b73f4d8f4712a5ad6062f35d511d8543bcb">35558b7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/48410f523f03e7f8c71c25ccd8b4819f5ef2ff99">48410f5</a>)</li>
<li><strong>Schema:</strong> update to v45.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/49a2bef4f44fd28579ee728547c465db8ac32350">49a2bef</a>)</li>
<li><strong>Schema:</strong> update to v45.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dba15dc4b91464d6a0612d375cf6e9c4917f5e92">dba15dc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Vehicles:</strong> add ADMIN ability to create/edit vehicles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ebd848987d60af9aff19cdbb3c064ef32c43ab40">ebd8489</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.19.0...v5.19.1">5.19.1</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item listings:</strong> update layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9102574a6c823f4399173e1916d9bf402de2655a">9102574</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.18.0...v5.19.0">5.19.0</a> (2024-11-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Info:</strong> update to display the CEIs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d37b727f35c9932bdf6ac8ac683dceca61408ddb">d37b727</a>)</li>
<li><strong>Schema:</strong> update to v45.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6fe564207cf1db65135479f7c08eaec1df3c745f">6fe5642</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item listings:</strong> add ui for viewing/interacting with item listings (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a7793d6e15ea21adbb90654713dec5fb20194030">a7793d6</a>)</li>
<li><strong>Report:</strong> Delivery matrix report will display all line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a9341f4c294eed5b1a9ba5b55e64851f73e93f06">a9341f4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.17.0...v5.18.0">5.18.0</a> (2024-11-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bca0cacb412132236332e5bf0427e38c0cf7cde7">bca0cac</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v45.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/31709820441b79caced6c488bc4d5487403305d8">3170982</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.16.0...v5.17.0">5.17.0</a> (2024-11-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DO:</strong> update input field sizing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4149c71c096297e6c9a970c60537ec40f49aaf96">4149c71</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> make a multi edit modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4b1e1d1c06560c071a6c5ba6616d4a6a4b1d1907">4b1e1d1</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.4...v5.16.0">5.16.0</a> (2024-11-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/561dc00588bacd48b2785848bcc5388ed4f9a919">561dc00</a>)</li>
<li><strong>COSMIC:</strong> Modal dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e44459e9c5729c1af1249215d3857abb04866b6a">e44459e</a>)</li>
<li><strong>Delivery order:</strong> adding size to a part reference (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9e1b7bfe71a10d76d9419038df7a751ec41874f6">9e1b7bf</a>)</li>
<li><strong>Line item type:</strong> added this field to the info popup and edit popup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/570682fd1236634d75351a6b36f849430ca20f23">570682f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Orders:</strong> complete line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fd9cc76f1d02a5a879ab42f3f2103dbc65febd57">fd9cc76</a>)</li>
<li><strong>Schema:</strong> update to v44.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b6b33d511fcc90d35f321443ab161fe850838aaa">b6b33d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.3...v5.15.4">5.15.4</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4c1a013ef7745e7c30b71c55888e8b734d3e06e4">4c1a013</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.2...v5.15.3">5.15.3</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ad3c671c6efceb678cb177bd9864e2ace9aac03c">ad3c671</a>)</li>
<li><strong>TabDeliveryOrderInfo:</strong> add Next ECD field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4801c544aa4110d344e895de6923fdf357d71b27">4801c54</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.1...v5.15.2">5.15.2</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/52d56ed7ff6baa007f855365af8ea3e9e188ab1c">52d56ed</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.15.0...v5.15.1">5.15.1</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/91b1923dbf179a7f023a25e645f0b41272419bbc">91b1923</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/73d1897256cdb30e6034ca870d9d774b2683e081">73d1897</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.14.0...v5.15.0">5.15.0</a> (2024-11-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d0775f1949b0a521923d923ec94fedc7e552beff">d0775f1</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2dbb8377d934e292da5463b06460a67a6e277868">2dbb837</a>)</li>
<li><strong>Delivery Matrix:</strong> added latest comment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/21535251d3c08f58a30fa42dfd208682128c4b8d">2153525</a>)</li>
<li><strong>Delivery matrix:</strong> update adds task owner display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8fe59cdea8374eb00becedc1b47673de20634c8b">8fe59cd</a>)</li>
<li><strong>Delivery matrix:</strong> users can now update status/ecd (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/055d08614be8eb31b0b42b6cbd120607d229b34f">055d086</a>)</li>
<li><strong>Delivery Order create:</strong> assign featured attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aebb9558d8d3e95989bd58fb4e6b97e7a9155d03">aebb955</a>)</li>
<li><strong>Delivery Order:</strong> adding delete modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f603dfcf00116e95117ac9dcb1a9b5e598f65948">f603dfc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Orders:</strong> info tab layout improvements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/70e7b663f6e6bf37bd69fc50153300d4aaab23d4">70e7b66</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.13.0...v5.14.0">5.14.0</a> (2024-11-06)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f443cfb6be512911f1923ed75f14b737f08ea8d8">f443cfb</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5e3cdaf524a862ace413f7bdb31ce106a43905d">d5e3cda</a>)</li>
<li><strong>Delivery matrix home:</strong> update comment modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/08713a690140a3fd4271cf59c23d0c110d962ceb">08713a6</a>)</li>
<li><strong>Delivery matrix:</strong> adding DO number to table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/91e4432afdc1d66a0c40a05622cd16320ddf5b37">91e4432</a>)</li>
<li><strong>Delivery Order create:</strong> update fields to match manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1689b40d45a9f913d0032e42787743c55302a644">1689b40</a>)</li>
<li><strong>Delivery order search:</strong> update attachments icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a198f5a3218e1e747c86503fa7bffc44998e1b29">a198f5a</a>)</li>
<li><strong>Delivery Orders:</strong> added task owner field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/43253fd05e513dffe7b5698554229bb358ffeca8">43253fd</a>)</li>
<li><strong>DO Information:</strong> remove initiator field and fix spacing on dates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/edadaa5e40abd358ab8798fb1bc1c31d7a281d93">edadaa5</a>)</li>
<li><strong>DO:</strong> adding action to preview attachment from search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fd532eb2812f0ec117f131f8ed3210e08d0627b0">fd532eb</a>)</li>
<li><strong>Naming:</strong> update Projected to Est. completion (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ade23f69dfe30f23152e1102093c36b76a114d8c">ade23f6</a>)</li>
<li><strong>Schema:</strong> update to v43.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f61ad6d41adca6e879e7ddb31577ab3ad88975a3">f61ad6d</a>)</li>
<li><strong>Schema:</strong> update to v43.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/84b74d718ebb46e47a89c4b0d8c9361ce4a11fc3">84b74d7</a>)</li>
<li><strong>Schema:</strong> update to v43.3.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8f91e01d143fc6de1f0e689fbfbec89d6d259f15">8f91e01</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order creation:</strong> fixed adding line items to a delivery order (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/33b08336a9b09730ba12f64a2d09061bd7c70281">33b0833</a>)</li>
<li><strong>Delivery Orders:</strong> ability to edit line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/981fc40ddbb80cf1a6908f96ecc956d2820356e3">981fc40</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.12.0...v5.13.0">5.13.0</a> (2024-11-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7667708b6fe65ef12df4c5ac0210c445db975fac">7667708</a>)</li>
<li><strong>Delivery matrix:</strong> update home view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4ff260253158b0c11dc5e7f2553e90f82fbabca8">4ff2602</a>)</li>
<li><strong>DO manage:</strong> update corrects caching issue after adding a line item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec132b19eca13749677eb261318d203f93161101">ec132b1</a>)</li>
<li><strong>DO:</strong> update info tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/65c7ff64e34010c5b4170fcf8c65b0e1f4a662d2">65c7ff6</a>)</li>
<li><strong>Schema:</strong> update to v43.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f6de71ded3f5e6ac9c12f7ca3dcd6f28483ffbce">f6de71d</a>)</li>
<li><strong>Schema:</strong> update to v43.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/667f0faaf0fdc9cde9c11cd6cc7168abcb8f2e3a">667f0fa</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>DO line items:</strong> ability to see all details related to a line item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f05117b9a8ae7caa993a380395fe660148eb1da9">f05117b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.11.0...v5.12.0">5.12.0</a> (2024-11-01)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Approvals:</strong> adding a way to manage the approvals of a DO_LINE_ITEM (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c197f2cc14c297d4a234b9b3329f68df409625f6">c197f2c</a>)</li>
<li><strong>Delivery matrix:</strong> update query path (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f88212af6a70e018eca68c71b6fbfac9495e2d7">1f88212</a>)</li>
<li><strong>Part ref:</strong> made side not a required form field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/983645260df91d41b3badfd9f8ef9762f3663b00">9836452</a>)</li>
<li><strong>Schema:</strong> update to v43.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/855f7b104f231cbf96dabe6d62a934788476e459">855f7b1</a>)</li>
<li><strong>Schema:</strong> update to v43.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/088170b5469e6c07d40dd785e804dc5169b29dc1">088170b</a>)</li>
<li><strong>Schema:</strong> update to v43.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b8729e1f5d62501f9ebc35fd8ce38799bea42389">b8729e1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order create:</strong> ability to add an attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f2b5c88157f12383576e11f61a927f6f02ca1d07">f2b5c88</a>)</li>
<li><strong>Delivery Order manage:</strong> ability to feature an attachment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a84003e2a6c1181e0aff4bcfbcd2669c894e9a39">a84003e</a>)</li>
<li><strong>Delivery order:</strong> finalized search screen (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e06148b506834d07b84d7c63c7c13e90b2de2fbf">e06148b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.10.0...v5.11.0">5.11.0</a> (2024-10-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> update search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/535dd60b9ac384e5d705559670740ccde8099c5e">535dd60</a>)</li>
<li><strong>Side nav:</strong> update icon to be the correct color (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e4074b910590f83112239d8e24f9d9de3ef4b613">e4074b9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Cache:</strong> implementing magic (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d3da1b4834e460561400ba80151fa92b61c7e3b5">d3da1b4</a>)</li>
<li><strong>Delivery Orders manage:</strong> add and delete line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b600cbd0688f016e073b7c7e38f6f7750733bced">b600cbd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.9.1...v5.10.0">5.10.0</a> (2024-10-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7b43a800c8b6706301158922de855fd69a46768">f7b43a8</a>)</li>
<li><strong>Delivery matrix:</strong> update query for matrix home (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3786a24233034472e24da7498aeab02b2b2c7d41">3786a24</a>)</li>
<li><strong>Delivery order search:</strong> ability to view closed DOs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b7df11520e71fd4027797d60ab27b5215845eb22">b7df115</a>)</li>
<li><strong>Delivery order search:</strong> update column layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0f5641e8c078ae31a94fd84a8797310474b79c5a">0f5641e</a>)</li>
<li><strong>Delivery Orders:</strong> add comment feature to search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fe6ecaaf0383c36774176869d6db8382b4dd62f8">fe6ecaa</a>)</li>
<li><strong>Delivery orders:</strong> update corrects search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc2760b587c31336e21c73a7a07d95e90c16ed40">fc2760b</a>)</li>
<li><strong>LI create:</strong> DeliveryOrderLineItemStatus implementation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d03c453c5c08b4c2124152a8c50a8e44f84f24ee">d03c453</a>)</li>
<li><strong>Schema:</strong> update to v43.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/85d2845135c87c6bf4450b2351869e2081a7720b">85d2845</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0a8ca6444909785d9fdd8652a5eb07cdfd8f62ac">0a8ca64</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/df484c387c30e818285286e91f7063b24b8ebd7b">df484c3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery Order create:</strong> ability to add line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/63c809775a90c1a58490363afe98142660285be5">63c8097</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.9.0...v5.9.1">5.9.1</a> (2024-10-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery order:</strong> Update the creation screen. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/49199f210e9f4fb5a57d5146ca37f8d624883175">49199f2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.8.1...v5.9.0">5.9.0</a> (2024-10-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> update control to pass the correct id (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bc65b02b7e4cfcf53046cfac6a46f1c7a81328bb">bc65b02</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f739fbedfbedaf486f0356749390186685e5b8b3">f739fbe</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fa858291d326c6d86396597c07d93de89c885502">fa85829</a>)</li>
<li><strong>COSMIC:</strong> Http dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf5838e3583bf81cad161885e35a177b2c33068d">bf5838e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Delivery order:</strong> creation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5ae43a096d9b6d028ea1997ff370772e56015c44">5ae43a0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.8.0...v5.8.1">5.8.1</a> (2024-10-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f420878aa89d83260d294b8eba24a74ce36d2801">f420878</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.5...v5.8.0">5.8.0</a> (2024-10-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ae4a4815a98004b6c41fc1520645479502c42367">ae4a481</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>COSMIC:</strong> update Delivery Matrix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9f43e41e9a1e75388174695d2a01896b1a099fa9">9f43e41</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.4...v5.7.5">5.7.5</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> add new grid css (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aa4ce62f8ef1db9d17c53103f54ae0bde0d0963a">aa4ce62</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.3...v5.7.4">5.7.4</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4d4faaf160b38183cdfee07682f401623959ee4f">4d4faaf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.2...v5.7.3">5.7.3</a> (2024-10-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6024d1b5d455a06e1558e556801a4703b5a518ec">6024d1b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a31730ffcc926d030c609f8fadfc7a3d97c95228">a31730f</a>)</li>
<li><strong>Schema:</strong> update to v41.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/26683f281bfb9c8972823c4c34d4dbe8666ec6f4">26683f2</a>)</li>
<li><strong>Schema:</strong> update to v41.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec7fba5989a8eed93292a03d79b2558078fff6b9">ec7fba5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.1...v5.7.2">5.7.2</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/204f114ff43681bacfd567ccec09a99fe8e35f11">204f114</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.7.0...v5.7.1">5.7.1</a> (2024-10-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v41.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3698fe0bfdec27ab3220cf50100217841f74eae2">3698fe0</a>)</li>
<li><strong>Schema:</strong> update to v41.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8f77ed68ab946b71dc76e7ec3f920f792e617031">8f77ed6</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.6.1...v5.7.0">5.7.0</a> (2024-09-25)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery order:</strong> initialized a new COSMIC entity called Delivery ordes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b480175d6e9c0bc9cf7879e89cd0fdd00e7e470">8b48017</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.6.0...v5.6.1">5.6.1</a> (2024-09-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/050ec8fb11255f307e32a1fabd7d59869134df30">050ec8f</a>)</li>
<li><strong>Schema:</strong> update to v41.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0941cd551ebd0fd15f16b1d3cad0bc91054d3f0c">0941cd5</a>)</li>
<li><strong>Schema:</strong> update to v41.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e75ff0e06b0aaa9ebec687010b2228ce3f50d5e7">e75ff0e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.2...v5.6.0">5.6.0</a> (2024-09-24)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/46c915873869d94c645d081b4365a215b992d9b4">46c9158</a>)</li>
<li><strong>Schema:</strong> update to v41.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/714b30705aa00eee0462ee00ba45f3eeaf90eb79">714b307</a>)</li>
<li><strong>Schema:</strong> update to v41.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/89ac3f3cb9fdc9a4ddfe3753f1c8e606b7228688">89ac3f3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f728f5583c23bd993b89c91e1bd79611cfe428da">f728f55</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.1...v5.5.2">5.5.2</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f505156462b296bf3e82954498c98ecca6d76ee2">f505156</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.5.0...v5.5.1">5.5.1</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/251cb5ce86ec2013194a7703b341784f430972ef">251cb5c</a>)</li>
<li><strong>Schema:</strong> update to v41.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a14dafeca6ca7cd15d37e55619d3e4132e3c8209">a14dafe</a>)</li>
<li><strong>Schema:</strong> update to v41.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/cd127612a76c5af79c5013784d238439a329e3d8">cd12761</a>)</li>
<li><strong>Schema:</strong> update to v41.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/eefdc318fe481cf3be7d4ce7e32156dee70af475">eefdc31</a>)</li>
<li><strong>Schema:</strong> update to v41.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3373968bd489d6d252888a53c13bdd5c6ab0a667">3373968</a>)</li>
<li><strong>Schema:</strong> update to v41.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/42aefb64382a10cfb2a7e78d5d35d6719d30af77">42aefb6</a>)</li>
<li><strong>UI:</strong> improved date picker popover (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f11c0e7203d8cf64b206c4418798b542d3d5b88f">f11c0e7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.2...v5.5.0">5.5.0</a> (2024-09-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d94c19eda4b2bb96e1397d715fa91ccc1eaed408">d94c19e</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c46f2b189020cb36aad17e74343851c95e93dae3">c46f2b1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/53fac86fc1c8cd178e0817313353b01870f7e109">53fac86</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/14dd5bf39a23b34712bb93ab3b2efe6439589477">14dd5bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.1...v5.4.2">5.4.2</a> (2024-09-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12a4daaaf82be129b898f0c24c86d174e05bcba7">12a4daa</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af241f22f2077e93b5105b22618b8a7ab4eca5ec">af241f2</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3c701dabff64bc6f51f9779d4af533c8e002d5ee">3c701da</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b0f7510c196c279b90cf4eea7c93489befdd38cb">b0f7510</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.0...v5.4.1">5.4.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc611c49e0a0c2e0a205f6c9ceca4de8dff33b69">fc611c4</a>)</li>
<li><strong>UI:</strong> streamlined checkbox controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5a90d5daf2a2aee30911c9a42c5c712f0a091bd4">5a90d5d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.1...v5.4.0">5.4.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2be37fb2df1e8492f1e0300a44f538bcf3f715f9">2be37fb</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bfa009c596ceab64d3fec5d82b4f3bf71999e739">bfa009c</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ed75e258b1aee74a14e1515e20ce8512f0a61cc1">ed75e25</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9c18bbfffa6caab69030b1158fdeac345a9f108b">9c18bbf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.0...v5.3.1">5.3.1</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aa53c52e6a77baeff675ce4b827def0cf2375ba7">aa53c52</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.1...v5.3.0">5.3.0</a> (2024-08-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f93ff140a043c670c55f98f3741ac1e050c25365">f93ff14</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.0...v5.2.1">5.2.1</a> (2024-08-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/296cbf29ce9f03954cae3a420699aad512006994">296cbf2</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1118561429d9ce33038b297fe3fc90efb1f7f0b5">1118561</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.1...v5.2.0">5.2.0</a> (2024-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c897bc9f6de92f14cc39b45ab3989b5b9b2d8035">c897bc9</a>)</li>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/40b796f62e5c1a0bf7d5997436cebb95c75b6ed0">40b796f</a>)</li>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/cfa5503ff68da4fd0dd0b5459a7afb796b31fee3">cfa5503</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f4d526ab217f9b8b614c186c417532a40fc3692e">f4d526a</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3b2182a9d625c805e1d47791537afaed2ddf58a2">3b2182a</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bee74958b59825d13ca33b1e3d80124f92f2056c">bee7495</a>)</li>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a7196a712907bb0f72bee17d68c5a380bb5620d">1a7196a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.0...v5.1.1">5.1.1</a> (2024-07-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e3950d642d862e1e7d2677b69fda8b1094d24136">e3950d6</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7d73aea7903a688c85493cdeb11dbae1a26bacc1">7d73aea</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8d8f4eb8e39d9ea5cad6ea1cd139b9752f304460">8d8f4eb</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2c9113b557d3c8dcfb3d34eb19ad2b47706c2bf2">2c9113b</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1388886e9aed69a2642c073a865985c759c24759">1388886</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dec37cf07ee1b09f25797af4a5d347b2bc892798">dec37cf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.0.0...v5.1.0">5.1.0</a> (2024-07-26)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5860978951cb676262e051d258cf8893d3def383">5860978</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b328c63b74452600ceaf7a412946fc320094f35a">b328c63</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.2...v5.0.0">5.0.0</a> (2024-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12dc7406f23327e845ca40ea1a7e8142a8783f83">12dc740</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time updates system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.1...v4.0.2">4.0.2</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/661e8516591a46a758256b23eab2d80a9fe7e901">661e851</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.0...v4.0.1">4.0.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/de959ab3ed3b58658c97e9a98c69ae4852af1282">de959ab</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.2...v4.0.0">4.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/371f86cc49645fc91dbeaddfb2db9458aec92da7">371f86c</a>)</li>
<li><strong>COSMIC:</strong> Dependency update for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a79b0db843675f80cfced5efd152efc4e8b5bb8">1a79b0d</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a2e366cf6b22284c5cf18891186a1dfcd1bcc186">a2e366c</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec136436e06fb948808e565bb05cc232dfda0615">ec13643</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2ca23d9a79cd38cb83298b9fdae7aa38e599760d">2ca23d9</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6120080cebd7a2c94bc192f8d2af7682ec0f248f">6120080</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.1...v3.0.2">3.0.2</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/82cff903d3453ad0f862c9de01248c16cc9b3c1e">82cff90</a>)</li>
<li><strong>Footer:</strong> updated knowledgebase link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/85bd6faa279ac837e8e7d9bf67721a2a710c77e3">85bd6fa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.0...v3.0.1">3.0.1</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf18c6ae607f75f5ed9aba432e947070d83207a9">bf18c6a</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47ddf65482ba04e0dd5783efc90e2d66d2ea3090">47ddf65</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.4...v3.0.0">3.0.0</a> (2024-06-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7a19a6043d8a5604f5664a395c76b86e3d194aaa">7a19a60</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.3...v2.1.4">2.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d708321f97d43c274ac50154a34fc1fe5798ef4f">d708321</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/86283d69ce3894414cd114c25b2e4a017752af22">86283d6</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b6791545af21c372231443185a1fc6d954e2f1ee">b679154</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a6666a2f8fe98dfcf741d418dda25e62ff511ab">1a6666a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.2...v2.1.3">2.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/087b58decf9d0f01ba8e6dc45476d37156434743">087b58d</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/17af931cdc30d31fe24bafcbb1b4fa40480f00d2">17af931</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ae0031e8cf583aeccb5f0f3cd5594368296ef1f7">ae0031e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.1...v2.1.2">2.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c7e4bd217b2de551eda774b37cdd2bb11069665e">c7e4bd2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.0...v2.1.1">2.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/09535cf6b2790065383b911f2f4e6ae7bb4a7d36">09535cf</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4bd19f07ab3ea5f662e2e62c6e30a9c395baef3b">4bd19f0</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.1...v2.1.0">2.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af7f3df39793a6f9f8c01faa137d61715ff4474f">af7f3df</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/328ad049a19e250bd66889c7e5e549dc762281da">328ad04</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c8fcb8aceb457d4004d59c7348f142a052a5b8b2">c8fcb8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.0...v2.0.1">2.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> updates for image pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c1d01e47df5fe323ff613c7d41df91b74e357d2d">c1d01e4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.13...v2.0.0">2.0.0</a> (2024-06-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b986f862ef93c40d3f6436c998dba9634fb11e0">8b986f8</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.12...v1.0.13">1.0.13</a> (2024-06-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Charts:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6f442d75bee7f50b43b4fed5a32e7a4011a67554">6f442d7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5476fe47207718166d65d5d4abb95d26d868271">d5476fe</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f795dc3f7156f76fb39e6dde9e90077e3d91bbd">1f795dc</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b96450e7318956da8189d393da02bbcbf37d82db">b96450e</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9cec7772c3e3f269c09e8c169699c2f3354c91ed">9cec777</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.11...v1.0.12">1.0.12</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix search:</strong> updated to default to IN_PROCESS if no status is set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6bc10d57ab4d0a8296fa937bd48113d5b889b061">6bc10d5</a>)</li>
<li><strong>Delivery matrix:</strong> updated search for better ui consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/944c3a727a925d2a1c0f5b76bafb1beacb2c8c73">944c3a7</a>)</li>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af875ccec7e33e1e23e58d9337b43bde50b7f61b">af875cc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.10...v1.0.11">1.0.11</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> updated how the hardware tracking is saved (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47b7b4eaa8c122ccf776a0fbe111c77fcc65834a">47b7b4e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.9...v1.0.10">1.0.10</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix changelog:</strong> corrected some data related issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7bda51fc0cee2c2baf11e732c2b3b6763aec64b">f7bda51</a>)</li>
<li><strong>Delivery matrix:</strong> corrected issue when completing an item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0fd518d72fd3cb7c1d971e029c552c4059073dc9">0fd518d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.8...v1.0.9">1.0.9</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> update ux (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/839cbe298d029ec65fdc2a8e52635ffc68e279ce">839cbe2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.7...v1.0.8">1.0.8</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>General:</strong> upadates to wording and reenforcement of actions via iconography (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/13b5cb343b52020955142633fffeb71bddbb74d4">13b5cb3</a>)</li>
<li><strong>Logos:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0894acc14f375cd3977224cb587e69feaae4a17b">0894acc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.6...v1.0.7">1.0.7</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ac1838b561195ac17f0873f9cc31a98eca08ce0b">ac1838b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.5...v1.0.6">1.0.6</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/23e343d620c778b6199721b4cb3b7fe09ef599a8">23e343d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.4...v1.0.5">1.0.5</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> implement new popups for creating critical HL and ROOT MEGA ATs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b235a9c469801c0fb0dc2892e38b24f51751a25a">b235a9c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.3...v1.0.4">1.0.4</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permissions:</strong> adidng in the all important LOGISTICS_ADMIN permission (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ef345bf72efd334c4b006049daf331020ce80eaf">ef345bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.2...v1.0.3">1.0.3</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> added a create HL modal and implement HL based routes/views (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/822597826bdad9bd17eba6bcb9a19450f4db88fd">8225978</a>)</li>
<li><strong>Chicklet:</strong> fun.tional (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a48e9b21bd281b74def108cbb233274798edfb52">a48e9b2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.1...v1.0.2">1.0.2</a> (2024-05-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical inventory:</strong> initial commit of base feature set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5040476a87298612d5c207d2266d3c4ec8375ead">5040476</a>)</li>
<li><strong>Delivery matrix:</strong> initial commit moving over functionality from COSMIC Docs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fab4bf40dc3e23f22c6ca8dd0cd58bff8b172ae6">fab4bf4</a>)</li>
<li><strong>Index:</strong> loading up the correct variables file (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bb0cbfc4fcb3c97d2fd29f5451e14628a0da231d">bb0cbfc</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ecca39f9873bf7184133cae3ca1d3674c9073128">ecca39f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.0...v1.0.1">1.0.1</a> (2024-05-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initial commit:</strong> new app is launched (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/adf546467ba6038a7f48f111ee69f3bb232003f6">adf5464</a>)</li>
<li><strong>Logistics:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f0f51da2c35c4923997db2f455718c33c890cb89">f0f51da</a>)</li>
</ul>
<h1>1.0.0 (2024-05-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li>gitlab CI filenam (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f2193aa125f0c85990b80055565bcb2fb1a9214">1f2193a</a>)</li>
<li>gitlab CI filename (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b305e9e1165a94cd66870a5e78bbe7f2a6754618">b305e9e</a>)</li>
</ul>
</section></template><script>export default {components:{}}</script>