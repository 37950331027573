var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppSelect', _vm._b({
    attrs: {
      "allow-create": false,
      "autocomplete": false,
      "chips": _vm.multiple,
      "color": _vm.EntityType.CONTRACT,
      "label": _vm.computedLabel,
      "loading": _vm.isLoading,
      "multiple": _vm.multiple,
      "select-options": _vm.options,
      "value": _vm.value,
      "monospace-selections": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, 'AppSelect', {
    ..._vm.$attrs
  }, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };