import { __decorate, __metadata } from "tslib";
import { isAfter, isBefore } from 'date-fns';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { dateFrom } from '~/nasa_ui/utils/dates';
var DateState;
(function (DateState) {
    DateState[DateState["BASE"] = 0] = "BASE";
    DateState[DateState["RED"] = 1] = "RED";
    DateState[DateState["YELLOW"] = 2] = "YELLOW";
    DateState[DateState["GREEN"] = 3] = "GREEN";
})(DateState || (DateState = {}));
let FancyDateDisplay = class FancyDateDisplay extends Vue {
    _dateA = null;
    _dateB = null;
    baseStateTextColor = 'var(--cosmic-text)';
    DateState = DateState;
    redStateTextColor = 'var(--v-secondary-base)';
    bold;
    dateA;
    dateB;
    display; // "date-a" or "date-b"
    fontSize;
    redIfDateAAfterDateB;
    redIfDateABeforeDateB;
    get dateADisplay() {
        return this.$dateDisplay(this._dateA);
    }
    get dateBDisplay() {
        return this.$dateDisplay(this._dateB);
    }
    get computedDateAState() {
        if (!this._dateA) {
            return DateState.BASE;
        }
        if (!this._dateB) {
            console.log('No dateB provided');
            return DateState.BASE;
        }
        if (this.redIfDateAAfterDateB) {
            return isAfter(this._dateA, this._dateB) ? DateState.RED : DateState.BASE;
        }
        if (this.redIfDateABeforeDateB) {
            return isBefore(this._dateA, this._dateB) ? DateState.RED : DateState.BASE;
        }
        return DateState.BASE;
    }
    get computedDateAColor() {
        switch (this.computedDateAState) {
            case DateState.BASE:
                return this.baseStateTextColor;
            case DateState.RED:
                return this.redStateTextColor;
        }
    }
    get computedDateToDisplay() {
        if (this.display === 'date-a') {
            return this.dateADisplay;
        }
        else if (this.display === 'date-b') {
            return this.dateBDisplay;
        }
    }
    onDateAChange() {
        this._dateA = dateFrom(this.dateA);
    }
    onDateBChange() {
        this._dateB = dateFrom(this.dateB);
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], FancyDateDisplay.prototype, "bold", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], FancyDateDisplay.prototype, "dateA", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], FancyDateDisplay.prototype, "dateB", void 0);
__decorate([
    Prop({
        type: String,
        default: 'date-a'
    }),
    __metadata("design:type", String)
], FancyDateDisplay.prototype, "display", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], FancyDateDisplay.prototype, "fontSize", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], FancyDateDisplay.prototype, "redIfDateAAfterDateB", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], FancyDateDisplay.prototype, "redIfDateABeforeDateB", void 0);
__decorate([
    Watch('dateA', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FancyDateDisplay.prototype, "onDateAChange", null);
__decorate([
    Watch('dateB', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FancyDateDisplay.prototype, "onDateBChange", null);
FancyDateDisplay = __decorate([
    Component
], FancyDateDisplay);
export default FancyDateDisplay;
