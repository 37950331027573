var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppNumber', {
    attrs: {
      "label": _vm.computedLabel,
      "clearable": _vm.clearable
    },
    on: {
      "input": _vm.emitInput
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_vm.computedErrorMessage ? _c('div', {
          staticClass: "message error--text pt-1"
        }, [_vm._v(_vm._s(_vm.computedErrorMessage))]) : _c('div', {
          staticClass: "message monospace_font pt-1"
        }, [_vm._v(_vm._s(_vm.unitCostMessage))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.baseValue,
      callback: function ($$v) {
        _vm.baseValue = $$v;
      },
      expression: "baseValue"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };