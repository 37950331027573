var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.isReadOnly ? _c('ButtonCreate', {
    staticClass: "mb-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showCreateModal = true;
      }
    }
  }, [_vm._v(" Connect related Documents ")]) : _vm._e(), _vm.showCreateModal ? _c('ModalRelatedDocumentsCreate', {
    attrs: {
      "existing-related-documents": _vm.relatedDocuments,
      "for-document-id": _vm.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCreateModal = false;
      },
      "refresh": _vm.onRefreshOfCreateModal
    }
  }) : _vm._e(), _vm.selectedRelatedDocument && _vm.showEditModal ? _c('ModalRelatedDocumentEdit', {
    attrs: {
      "existing-related-documents": _vm.relatedDocuments,
      "for-document-id": _vm.id,
      "related-document": _vm.selectedRelatedDocument
    },
    on: {
      "modal-close": _vm.onCloseOfEditModal,
      "refresh": _vm.onRefreshOfCreateModal
    }
  }) : _vm._e(), _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "headers": _vm.relatedDocumentsTableHeaders,
      "items": _vm.relatedDocumentsTableItems,
      "show-header": false,
      "sticky": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildDocumentUrl(item),
            "icon-size": "18px"
          }
        }), _c('ButtonMiniActionEdit', {
          attrs: {
            "icon-size": "18px"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfRelatedDocument(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          attrs: {
            "icon-size": "18px"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfDeleteRelatedDocument(item);
            }
          }
        })];
      }
    }])
  }), _vm.showDeleteConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600"
    },
    on: {
      "affirmativeClicked": _vm.deleteRelation,
      "negativeClicked": _vm.reset
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will PERMANENTLY delete this relationship.")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };