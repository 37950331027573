import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { CommentType, DeliveryOrderLineItemStatus, DeliveryOrderLineItemType } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, DeliveryOrderLineItemStatusDisplay, DeliveryOrderLineItemTypeDisplay } from '~/nasa_ui/types';
import { putDeliveryOrderLineItem } from '~/nasa_ui/utils';
let ModalDeliveryOrderLineItemUpdateMultiple = class ModalDeliveryOrderLineItemUpdateMultiple extends Mixins(BaseModalWithUser) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    DeliveryOrderLineItemType = DeliveryOrderLineItemType;
    DeliveryOrderLineItemTypeDisplay = DeliveryOrderLineItemTypeDisplay;
    statusComment = null;
    formData = {
        attributes: {},
        deliveryItemsListNumber: null,
        deliveryOrderLineItemStatus: null,
        jDocumentNumber: null,
        lineItemQuantity: null,
        lineItemType: null,
        openedDate: null,
        ownerContactId: null,
        projectedDate: null,
        shipperNumber: null,
        workBreakdownStructureNumber: null
    };
    changeSets;
    async onClickOfSave() {
        if (!this.changeSets) {
            return;
        }
        // this request body needs to list every property of the line item, even if
        // it's not being updated. It’s typed to be explicit (no undefined values)
        // because omitting a field here could lead to unintended data loss.
        const updates = this.changeSets.map((changeSetToUpdate) => {
            const body = {
                attributes: changeSetToUpdate.attributes,
                createPartReference: undefined,
                deliveryItemsListNumber: this.formData.deliveryItemsListNumber || changeSetToUpdate.deliveryItemsListNumber || null,
                deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
                jDocumentNumber: this.formData.jDocumentNumber || changeSetToUpdate.jDocumentNumber || null,
                lineItemQuantity: this.formData.lineItemQuantity || changeSetToUpdate.lineItemQuantity || null,
                lineItemType: this.formData.lineItemType || changeSetToUpdate.lineItemType || null,
                openedDate: this.formData.openedDate || changeSetToUpdate.openedDate || null,
                ownerContactId: this.formData.ownerContactId || changeSetToUpdate.ownerContactId || null,
                partReferenceId: changeSetToUpdate.partReferenceId ?? null,
                plannedExpirationDate: this.formData.plannedExpirationDate || changeSetToUpdate.plannedExpirationDate || null,
                projectedDate: this.formData.projectedDate || changeSetToUpdate.projectedDate || null,
                shipperNumber: this.formData.shipperNumber || changeSetToUpdate.shipperNumber || null,
                workBreakdownStructureNumber: this.formData.workBreakdownStructureNumber || changeSetToUpdate.workBreakdownStructureNumber || null
            };
            return putDeliveryOrderLineItem(changeSetToUpdate.documentId, changeSetToUpdate.id, body);
        });
        // User wants this specifically logged as a comment
        // when the projectedDate is updated -> make a comment
        const updatesToProjectedDateThatNeedComments = this.changeSets
            .filter((cs) => cs.projectedDate !== this.formData.projectedDate)
            .map((cs) => {
            const payload = {
                comment: `
DIL #:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${cs.deliveryItemsListNumber ?? DEFAULT_DASH}\`
Drawing #:&nbsp;&nbsp;\`${cs.partReference.drawingNumber ?? DEFAULT_DASH}\`
Serial #:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${cs.partReference.serialNumber ?? DEFAULT_DASH}\`

Est. completion date change:

&nbsp;&nbsp;&nbsp;&nbsp;From:&nbsp;&nbsp;&nbsp;&nbsp;\`${this.$dateDisplay(cs.projectedDate)}\`
&nbsp;&nbsp;&nbsp;&nbsp;To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${this.$dateDisplay(this.formData.projectedDate)}\` 

Updated by:&nbsp;&nbsp;&nbsp;&nbsp;${this.currentUserFirstLastNameDisplay} on \`${this.$dateTimeDisplay(new Date())}\` `,
                subType: CommentType.SYSTEM
            };
            const params = {
                changeSetId: cs.id,
                documentId: cs.documentId
            };
            return this.$http.post(`/comments`, payload, { params });
        });
        try {
            const resp = await Promise.all(updates);
            if (updatesToProjectedDateThatNeedComments.length) {
                await Promise.all(updatesToProjectedDateThatNeedComments);
            }
            if (this.statusComment) {
                const commentUpdates = this.changeSets.map((changeSetToUpdate) => {
                    const commentPayload = {
                        comment: this.statusComment.trim(),
                        subType: CommentType.STATUS
                    };
                    const commentParams = {
                        documentId: changeSetToUpdate.documentId,
                        changeSetId: changeSetToUpdate.id
                    };
                    return this.$http.post('/comments', commentPayload, {
                        params: commentParams
                    });
                });
                await Promise.all(commentUpdates);
            }
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Saved ${this.changeSets.length} items.`
            });
            this.emitSaved();
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved() { }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], ModalDeliveryOrderLineItemUpdateMultiple.prototype, "changeSets", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemUpdateMultiple.prototype, "emitSaved", null);
ModalDeliveryOrderLineItemUpdateMultiple = __decorate([
    Component
], ModalDeliveryOrderLineItemUpdateMultiple);
export default ModalDeliveryOrderLineItemUpdateMultiple;
