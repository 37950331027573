var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetTemplateOnCsTemplateManageFragment, ChangeSetType, ItemMasterType as EquipmentGroup, UserGroupType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { AlertType } from '~/nasa_ui/types';
import { ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { InventoryClassDisplay, ItemClassesWithoutMultiple } from '~/nasa_ui/types/enums/hardware';
import { UserWithoutAuidHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { changeSetTemplateFragment, IApprovalTableRow, ICstTableFilters } from '~/nasa_ui/views/ChangeSetTemplatesManageView/ChangeSetTemplatesManageView';
let ChangeSetTemplatesForm = class ChangeSetTemplatesForm extends Mixins(BaseCosmic, UserRepoMixin) {
    ChangeSetTypeDisplay = ChangeSetTypeDisplay;
    EquipmentGroup = EquipmentGroup;
    EquipmentGroupDisplay = EquipmentGroupDisplay;
    userGroupMembershipTableHeaders = [...UserWithoutAuidHeaders];
    isFetchingSelectedGroupMembership = false;
    isSaving = false;
    InventoryClassDisplay = InventoryClassDisplay;
    ogFormData = null;
    showForm = true;
    showSelectedGroupMembershipTable = false;
    userGroups = [];
    usersForSelectedGroup = [];
    formData = {
        subType: null,
        itemMasterType: null,
        itemInstanceInventoryType: null,
        groupCode: null,
        requiresQualityControlStamp: false,
        requiresComment: false
    };
    isEditing;
    isLocked;
    selectedChangeSetTemplate;
    cstTableFilters;
    get changeSetTypeBlockquote() {
        if (!this.formData || !this.formData.subType) {
            return null;
        }
        return ChangeSetTypeDisplay.get(this.formData.subType);
    }
    get computedInventoryClasss() {
        return ItemClassesWithoutMultiple;
    }
    get computedChangeSetTypes() {
        return Object.entries(ChangeSetType).reduce((acc, [key, val]) => {
            if (!key.includes('LINE_ITEM')) {
                acc[key] = val;
            }
            return acc;
        }, {});
    }
    get equipmentGroupBlockquote() {
        if (!this.formData || !this.formData.itemMasterType) {
            return null;
        }
        return EquipmentGroupDisplay.get(this.formData.itemMasterType);
    }
    get itemInstanceInventoryTypeBlockquote() {
        if (!this.formData || !this.formData.itemInstanceInventoryType) {
            return null;
        }
        return InventoryClassDisplay.get(this.formData.itemInstanceInventoryType) || 'Multiple';
    }
    get isFormDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.ogFormData);
    }
    get isFormValid() {
        return Boolean(this.formData && this.formData.subType && this.formData.groupCode);
    }
    get formState() {
        return {
            isEditing: this.isEditing,
            isFormDirty: this.isFormDirty,
            isFormValid: this.isFormValid,
            isLocked: this.isLocked,
            isSaving: this.isSaving,
            shouldDisableSaveButton: this.shouldDisableSaveButton
        };
    }
    get saveButtonText() {
        return this.isEditing ? 'Update Change Set Template' : 'Add Change Set Template';
    }
    get shouldDisableSaveButton() {
        if (this.isSaving || this.isLocked) {
            return true;
        }
        if (this.isEditing) {
            return !this.isFormDirty || !this.isFormValid;
        }
        return !this.isFormValid;
    }
    get shouldShowBothDdls() {
        if (!this.formData.subType) {
            return false;
        }
        return this.formData.subType.startsWith('ITEM_INSTANCE_INVENTORY');
    }
    get shouldShowDeleteButton() {
        if (!this.selectedChangeSetTemplate) {
            return false;
        }
        let hasAuthorities = false;
        if (this.selectedChangeSetTemplate.changeSetAuthorities?.totalCount) {
            hasAuthorities = this.selectedChangeSetTemplate.changeSetAuthorities.totalCount > 0;
        }
        return this.isEditing && !this.isLocked && !hasAuthorities;
    }
    get shouldShowEquipmentGroupDdl() {
        if (!this.formData.subType) {
            return false;
        }
        const subType = this.formData.subType;
        return subType.startsWith('ITEM_MASTER') || subType.startsWith('ITEM_INSTANCE');
    }
    get templateChangeAlert() {
        const text = this.isLocked
            ? 'This is not editable because the filters above do not match its scope.'
            : 'Changes made to this could have ramifications.', type = this.isLocked ? AlertType.ERROR : AlertType.WARNING;
        return { text, type };
    }
    get userGroupMembers() {
        if (!this.usersForSelectedGroup || !this.usersForSelectedGroup.length) {
            return [];
        }
        return transformUserResponses(this.usersForSelectedGroup);
    }
    get userGroupSelectItems() {
        if (!this.userGroups.length) {
            return null;
        }
        return this.userGroups
            .filter((group) => group.subType !== UserGroupType.USER_DELEGATE)
            .map((group) => {
            return {
                displayText: group.name ? group.name : group.code,
                value: group.code
            };
        });
    }
    emitCanceled() { }
    emitCreated(val) { }
    emitDeleted(val) { }
    emitUpdated(val) { }
    bindFiltersToFormData() {
        if (this.cstTableFilters.subType) {
            this.formData.subType = this.cstTableFilters.subType;
        }
        if (this.cstTableFilters.itemMasterType) {
            this.formData.itemMasterType = this.cstTableFilters.itemMasterType;
        }
        if (this.cstTableFilters.itemInstanceInventoryType) {
            this.formData.itemInstanceInventoryType = this.cstTableFilters.itemInstanceInventoryType;
        }
    }
    emitFormState() { }
    bindSelectedCstToFormData() {
        if (!this.selectedChangeSetTemplate || !this.selectedChangeSetTemplate.subType) {
            return;
        }
        this.formData.subType = this.selectedChangeSetTemplate.subType;
        this.formData.itemMasterType = this.selectedChangeSetTemplate.itemMasterType || null;
        this.formData.itemInstanceInventoryType = this.selectedChangeSetTemplate.itemInstanceInventoryType || null;
        this.formData.groupCode = this.selectedChangeSetTemplate.groupCode;
        this.formData.requiresComment = this.selectedChangeSetTemplate.requiresComment || false;
        this.formData.requiresQualityControlStamp = this.selectedChangeSetTemplate.requiresQualityControlStamp || false;
    }
    async getGroupMembership() {
        if (!this.formData.groupCode) {
            return;
        }
        try {
            this.isFetchingSelectedGroupMembership = true;
            const resp = await this.$http.get(`/user-groups/${encodeURIComponent(this.formData.groupCode)}`);
            this.usersForSelectedGroup = resp?.data.users || [];
            this.isFetchingSelectedGroupMembership = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async createChangeSetTemplate() {
        try {
            const resp = await this.createChangeSetTemplateMutation();
            if (resp?.data?.createChangeSetTemplate?.changeSetTemplate) {
                this.emitCreated(resp.data.createChangeSetTemplate.changeSetTemplate);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    createChangeSetTemplateMutation() {
        if (!this.formData) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        ${changeSetTemplateFragment}

        mutation CreateChangeSetTemplateOnCsTemplateManage($changeSetTemplateInput: ChangeSetTemplateInput!) {
          createChangeSetTemplate(input: { changeSetTemplate: $changeSetTemplateInput }) {
            changeSetTemplate {
              ...changeSetTemplateOnCSTemplateManage
            }
          }
        }
      `,
            variables: {
                changeSetTemplateInput: {
                    ...this.formData,
                    isActive: true
                }
            }
        });
    }
    created() {
        this.bindFiltersToFormData();
        if (this.isEditing) {
            this.bindSelectedCstToFormData();
        }
    }
    async fetchUserGroups() {
        try {
            const resp = await this.getAllUserGroups();
            if (resp?.userGroups?.nodes) {
                this.userGroups = resp.userGroups.nodes;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    mounted() {
        this.fetchUserGroups();
    }
    onClickOfCancel() {
        this.showForm = false;
        this.resetForm();
        this.emitCanceled();
    }
    async onClickOfSave() {
        if (!this.formData || !this.formData.subType || !this.formData.groupCode) {
            return;
        }
        this.isSaving = true;
        if (this.isEditing) {
            await this.updateChangeSetTemplate();
        }
        else {
            await this.createChangeSetTemplate();
        }
        this.isSaving = false;
    }
    resetForm() {
        if (!this.formData) {
            return;
        }
        this.formData = {
            subType: this.formData.subType,
            itemMasterType: this.formData.itemMasterType,
            itemInstanceInventoryType: this.formData.itemInstanceInventoryType,
            groupCode: null,
            requiresQualityControlStamp: false,
            requiresComment: false
        };
        this.ogFormData = { ...this.formData };
    }
    async updateChangeSetTemplate() {
        if (this.selectedChangeSetTemplate === null || this.selectedChangeSetTemplate === undefined) {
            return;
        }
        try {
            const resp = await this.updateChangeSetTemplateMutation(this.selectedChangeSetTemplate.id);
            if (resp?.data?.updateChangeSetTemplateById?.changeSetTemplate) {
                this.emitUpdated(resp.data.updateChangeSetTemplateById.changeSetTemplate);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    updateChangeSetTemplateMutation(id) {
        if (!this.formData || !this.formData.subType || id === null || id === undefined) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        ${changeSetTemplateFragment}

        mutation UpdateChangeSetTemplate($input: UpdateChangeSetTemplateByIdInput!) {
          updateChangeSetTemplateById(input: $input) {
            changeSetTemplate {
              ...changeSetTemplateOnCSTemplateManage
            }
          }
        }
      `,
            variables: {
                input: {
                    id: id,
                    patch: this.formData
                }
            }
        });
    }
    toggleForm() {
        this.showForm = !this.showForm;
        this.resetForm();
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ChangeSetTemplatesForm.prototype, "isEditing", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ChangeSetTemplatesForm.prototype, "isLocked", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IApprovalTableRow !== "undefined" && IApprovalTableRow) === "function" ? _a : Object)
], ChangeSetTemplatesForm.prototype, "selectedChangeSetTemplate", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof ICstTableFilters !== "undefined" && ICstTableFilters) === "function" ? _b : Object)
], ChangeSetTemplatesForm.prototype, "cstTableFilters", void 0);
__decorate([
    Emit('canceled'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "emitCanceled", null);
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof ChangeSetTemplateOnCsTemplateManageFragment !== "undefined" && ChangeSetTemplateOnCsTemplateManageFragment) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "emitCreated", null);
__decorate([
    Emit('deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "emitDeleted", null);
__decorate([
    Emit('updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof ChangeSetTemplateOnCsTemplateManageFragment !== "undefined" && ChangeSetTemplateOnCsTemplateManageFragment) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "emitUpdated", null);
__decorate([
    Watch('cstTableFilters', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "bindFiltersToFormData", null);
__decorate([
    Emit('formState'),
    Watch('formState', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "emitFormState", null);
__decorate([
    Watch('selectedChangeSetTemplate', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesForm.prototype, "bindSelectedCstToFormData", null);
__decorate([
    Watch('formData.groupCode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ChangeSetTemplatesForm.prototype, "getGroupMembership", null);
ChangeSetTemplatesForm = __decorate([
    Component
], ChangeSetTemplatesForm);
export default ChangeSetTemplatesForm;
