var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.activity ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.activityItemInstanceId ? _c('v-card', {
    staticClass: "pa-3 mb-2"
  }, [_c('AppHardwareDisplay', {
    staticClass: "mb-3",
    attrs: {
      "id": _vm.activityItemInstanceId,
      "hide-border-bottom": ""
    }
  })], 1) : _vm._e(), _c('Alert', {
    attrs: {
      "type": _vm.AlertType.INFO
    }
  }, [_c('p', [_vm._v(" In " + _vm._s(_vm.currentSystemName) + ", Activities are not contextual. Doing so would adversely affect the reporting output and potentially deliver inaccurate Limited Life alerting. ")]), _c('p', [_vm._v(" For this reason, Activities are shared amongst every partner utilizing " + _vm._s(_vm.currentSystemName) + ". This means that entering an Activity could affect Hardware outside of " + _vm._s(_vm.currentUserActiveSupportContext) + ". ")])]), _c('ActivityBaseForm', {
    attrs: {
      "id": _vm.id,
      "read-only": _vm.isReadOnly
    }
  }), _vm.activityFormComponentName ? _c('v-card', {
    staticClass: "mt-2 pa-3"
  }, [_c(_vm.activityFormComponentName, {
    tag: "component",
    attrs: {
      "id": _vm.id,
      "read-only": _vm.isReadOnly
    }
  })], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };