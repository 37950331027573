var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hide-title-section": "",
      "modal-width": "50vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.transformedSelectedEntity ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "remove-margin-left": "",
            "color": "info"
          }
        }, [_vm._v(" This will create a brand new " + _vm._s(_vm.transformedSelectedEntity._subType) + " from "), _c('u', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._name) + " ")])])], 1)]) : _vm._e(), _c('div', {
          staticClass: "c-grid align-center mb-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "label": "New name",
            "required": ""
          },
          model: {
            value: _vm.formData.newRootName,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "newRootName", $$v);
            },
            expression: "formData.newRootName"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third mb-2"
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.MegaAllocationTypeDisplay,
            "enum": _vm.MegaAllocationType,
            "clearable": "",
            "label": "New allocation type"
          },
          model: {
            value: _vm.formData.attributes.megaAllocationType,
            callback: function ($$v) {
              _vm.$set(_vm.formData.attributes, "megaAllocationType", $$v);
            },
            expression: "formData.attributes.megaAllocationType"
          }
        })], 1), _c('div', {
          staticClass: "col-full mb-2"
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Clone all Waivers?"
          },
          model: {
            value: _vm.formData.cloneWaivers,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "cloneWaivers", $$v);
            },
            expression: "formData.cloneWaivers"
          }
        })], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Navigate after successful clone?"
          },
          model: {
            value: _vm.navigateAfterClone,
            callback: function ($$v) {
              _vm.navigateAfterClone = $$v;
            },
            expression: "navigateAfterClone"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "loading": _vm.isSaving,
            "disabled": !_vm.formData.newRootName
          },
          on: {
            "click": _vm.onClickOfClone
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(" Cancel "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };