import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import get from 'lodash/get';
import qs from 'qs';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { ActivityType, PressurizationRange } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { EntityType } from '~/nasa_ui/types';
import { ActivityTypeDisplay, PressurizationRangeDisplay } from '~/nasa_ui/types/enums/activity';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { stripNullProperties } from '~/nasa_ui/utils/helpers/stripNullProperties';
import { ActivityTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformActivities, transformItemInstanceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchActivity = class SearchActivity extends Mixins(BaseSearch) {
    ActivityType = ActivityType;
    ActivityTypeDisplay = ActivityTypeDisplay;
    entityType = EntityType.ACTIVITY;
    hint = 'activity name, drawing number, as built';
    placeholder = 'search for activities';
    PressurizationRange = PressurizationRange;
    PressurizationRangeDisplay = PressurizationRangeDisplay;
    tableItems = [];
    transformedItemInstances = [];
    userSelectedMaxResults = 100;
    searchFilters = {
        drawingNumber: '',
        asBuilt: null,
        isPreventativeMaintenance: null,
        serialNumber: '',
        query: '',
        startDate: null,
        subType: null
    };
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('subType', EntityType.ACTIVITY, ActivityTypeDisplay), this.buildAppChipForProperty('authorityNumber', EntityType.ACTIVITY), this.buildAppChipForProperty('drawingNumber', EntityType.ITEM_DRAWING), this.buildAppChipForProperty('asBuilt', EntityType.ITEM_MASTER), this.buildAppChipForProperty('serialNumber', EntityType.INVENTORY), this.buildAppChipForProperty('startDate', EntityType.ACTIVITY), this.buildAppChipForProperty('pressurizationRange', EntityType.ACTIVITY, PressurizationRangeDisplay), this.isPreventativeMaintenanceFilterDisplay);
        return appChips.filter((key) => key !== undefined);
    }
    get isPreventativeMaintenanceFilterDisplay() {
        const filterValue = this.$get(this, 'pureSearchFilters.isPreventativeMaintenance');
        const text = `Preventative Maintenance: ${filterValue ? 'Yes' : 'No'}`;
        return isNullOrUndefined(filterValue)
            ? []
            : [
                {
                    close: true,
                    color: EntityType.ACTIVITY,
                    filterType: 'isPreventativeMaintenance',
                    outline: true,
                    text,
                    value: 'isPreventativeMaintenance'
                }
            ];
    }
    get computedTableHeaders() {
        return [...ActivityTableHeaders];
    }
    // override base
    onClickOfSearchButton() {
        this.emitSearchButtonClicked();
        this.emitTermSearched();
        this.performApi2Search();
        this.showTable = true;
    }
    async performApi2Search() {
        try {
            this.isLoading = true;
            const queryVariables = stripNullProperties(this.gqlSearchQueryVariables);
            if (queryVariables.firstResults) {
                delete queryVariables.firstResults;
            }
            const queryString = qs.stringify({
                ...queryVariables,
                skip: 0,
                take: this.gqlSearchQueryVariables.firstResults ? this.gqlSearchQueryVariables.firstResults : -1
            });
            const response = await this.$http.get(`/${this.entityType}?${queryString}`);
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.transformedItemInstances = transformItemInstanceResponses(response.data.itemInstances);
            this.emitSearchPerformed();
        }
        catch (error) {
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    setTableItems() {
        const clientSideFilters = [
            { key: 'serialNumber', value: 'itemInstance.serialNumber' },
            { key: 'pressurizationRange' },
            { key: 'isPreventativeMaintenance' }
        ].filter((filter) => !isNullOrUndefined(this.$route.query?.[filter.key]));
        this.tableItems = transformActivities(this.searchResults || [])
            .map((activity) => {
            // Add Item Instance from search response collection
            const iiMatch = this.transformedItemInstances.find((ii) => ii.id === activity.itemInstanceId);
            activity['_itemInstance'] = iiMatch || null;
            return activity;
        })
            .filter((activity) => {
            if (!clientSideFilters.length && !this.searchFilters.startDate) {
                console.log(activity._itemInstance);
                return activity;
            }
            // TODO: items are showing if they match any of the client side filters
            // so its using OR logic where if any of the filters match, it returns results
            // Not sure if we want to make them be AND logic where they stack.
            const isMatch = clientSideFilters?.length
                ? clientSideFilters.find((filter) => {
                    if (!this.$route.query) {
                        return false;
                    }
                    const filterKey = filter.value || filter.key;
                    const a = get(activity, filterKey);
                    const b = filterKey === 'itemInstance.serialNumber'
                        ? get(this.searchFilters, 'serialNumber') || ''
                        : get(this.searchFilters, filterKey);
                    if (Array.isArray(b)) {
                        return b.some((b) => b?.indexOf(a));
                    }
                    // Return false here if you want to EXCLUDE null values
                    const comparison = !isNullOrUndefined(a)
                        ? a.toString().toLowerCase().startsWith(b.toString().toLowerCase())
                        : false;
                    return comparison;
                })
                : true;
            // using 2 day window for startDate
            // filtering client side by displayDate for exact matches reguardless of timezone
            const startDateIsMatch = this.searchFilters.startDate
                ? this.searchFilters.startDate === this.$dateDisplay(activity.startDateTime)
                : true;
            return Boolean(isMatch && startDateIsMatch);
        });
    }
    checkForStartDate(val) {
        // manually clear startDate if cleared from filters
        if (val.hasOwnProperty('startDate')) {
            Vue.set(this.searchFilters, 'startDate', val.startDate);
        }
        else {
            Vue.set(this.searchFilters, 'startDate', null);
        }
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchActivity.prototype, "performApi2Search", null);
__decorate([
    Watch('searchResults'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchActivity.prototype, "setTableItems", null);
__decorate([
    Watch('searchFilters', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchActivity.prototype, "checkForStartDate", null);
SearchActivity = __decorate([
    Component
], SearchActivity);
export default SearchActivity;
