var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "single_line_display_location c-flex no-gap"
  }, [_vm.organizationCode ? _c('span', {
    staticClass: "pr-1"
  }, [_c('small', {
    staticClass: "font-weight-light"
  }, [_vm._v("org")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.organizationCode))])]) : _vm._e(), _vm.building && _vm.building === _vm.LOC_DB_DEFAULT && _vm.room && _vm.room === _vm.LOC_DB_DEFAULT && _vm.stock && _vm.stock === _vm.LOC_DB_DEFAULT && _vm.bin && _vm.bin === _vm.LOC_DB_DEFAULT ? _c('span', [_vm._v(" General ")]) : _vm.building && _vm.building === _vm.DB_DEFAULT && _vm.room && _vm.room === _vm.DB_DEFAULT && _vm.stock && _vm.stock === _vm.DB_DEFAULT && _vm.bin && _vm.bin === _vm.DB_DEFAULT ? _c('span', [_vm._v(" Default ")]) : !_vm.building && !_vm.room && !_vm.stock && !_vm.bin ? _c('span', [_c('i', [_vm._v("no descriptive data")])]) : _c('div', [_vm.building ? _c('span', {
    staticClass: "pr-1"
  }, [_c('small', {
    staticClass: "font-weight-light"
  }, [_vm._v("bldg")]), _vm.building === _vm.DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Default")]) : _vm.building === _vm.LOC_DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("General")]) : _vm.building === 'UNKNOWN' ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Unknown")]) : _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.building))])]) : _vm._e(), _vm.room ? _c('span', {
    staticClass: "pr-1"
  }, [_c('small', {
    staticClass: "font-weight-light"
  }, [_vm._v("r")]), _vm.room === _vm.DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Default")]) : _vm.room === _vm.LOC_DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("General")]) : _vm.room === 'UNKNOWN' ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Unknown")]) : _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.room))])]) : _vm._e(), _vm.stock ? _c('span', {
    staticClass: "pr-1"
  }, [_c('small', {
    staticClass: "font-weight-light"
  }, [_vm._v("st")]), _vm.stock === _vm.DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Default")]) : _vm.stock === _vm.LOC_DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("General")]) : _vm.stock === 'UNKNOWN' ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Unknown")]) : _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.stock))])]) : _vm._e(), _vm.bin ? _c('span', {
    staticClass: "pr-1"
  }, [_c('small', {
    staticClass: "font-weight-light"
  }, [_vm._v("b")]), _vm.bin === _vm.DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Default")]) : _vm.bin === _vm.LOC_DB_DEFAULT ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("General")]) : _vm.bin === 'UNKNOWN' ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Unknown")]) : _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.bin))])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };