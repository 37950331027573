var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AssemblyTemplateResponseWithParts } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { getAssemblyTemplate, putAssemblyTemplate } from '~/nasa_ui/utils';
import { MegaAllocationType, MegaAllocationTypeDisplay } from '~/nasa_ui/utils/helpers/transforms/assemblyTemplates';
let ModalMegaAllocationTypeUpdate = class ModalMegaAllocationTypeUpdate extends Mixins(BaseModal) {
    selectedEntity = null;
    MegaAllocationType = MegaAllocationType;
    MegaAllocationTypeDisplay = MegaAllocationTypeDisplay;
    formData = {
        attributes: {}
    };
    assemblyTemplateId;
    async onClickOfSave() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                attributes: Object.assign({}, this.selectedEntity.attributes, this.formData.attributes),
                autoHardwareListId: this.selectedEntity.autoHardwareListId ?? null,
                digitalLibraryRecordId: this.selectedEntity.digitalLibraryRecordId ?? null,
                documentId: this.selectedEntity.documentId ?? null,
                installedOn: this.selectedEntity.installedOn,
                maxQuantity: this.selectedEntity.maxQuantity,
                minQuantity: this.selectedEntity.minQuantity,
                name: this.selectedEntity.name ?? null,
                needDate: this.selectedEntity.needDate ?? null,
                parentId: this.selectedEntity.parentId ?? null,
                parts: this.selectedEntity.parts ?? null,
                pbsItemId: this.selectedEntity.pbsItemId ?? null,
                sequence: this.selectedEntity.sequence,
                subType: this.selectedEntity.subType,
                waiverDate: this.selectedEntity.waiverDate ?? null,
                waiverNumber: this.selectedEntity.waiverNumber ?? null
            };
            const resp = await putAssemblyTemplate(this.selectedEntity.id, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.emitSaved(resp.data);
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved(data) {
        return data;
    }
    async fetchSelectedEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalMegaAllocationTypeUpdate.prototype, "assemblyTemplateId", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof AssemblyTemplateResponseWithParts !== "undefined" && AssemblyTemplateResponseWithParts) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalMegaAllocationTypeUpdate.prototype, "emitSaved", null);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalMegaAllocationTypeUpdate.prototype, "fetchSelectedEntity", null);
ModalMegaAllocationTypeUpdate = __decorate([
    Component
], ModalMegaAllocationTypeUpdate);
export default ModalMegaAllocationTypeUpdate;
