var _a;
import { __decorate, __metadata } from "tslib";
import { uniq } from 'lodash';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { EntityType } from '~/nasa_ui/types';
import { getLocations } from '~/nasa_ui/utils/dal';
import { buildChipAsString } from '~/nasa_ui/utils/ui/buildChipAsString';
let AppSelectLocationByBin = class AppSelectLocationByBin extends Mixins(CurrentUserMixin) {
    EntityType = EntityType;
    locationIds = null;
    allBinsQueryResult = [];
    disabled;
    ignoreBinsWithZeroQuantity;
    label;
    multiple;
    unmanagedOnly;
    value;
    get binSelectOptions() {
        if (!this.allBinsQueryResult.length) {
            return [];
        }
        return this.allBinsQueryResult
            .filter((location) => {
            return this.unmanagedOnly ? !location?.contractNumber : true;
        })
            .map((location) => {
            const chip = buildChipAsString({
                chipText: location.contractNumber || '',
                chipClass: 'font-weight-bold',
                color: EntityType.CONTRACT,
                chipIconClass: `${this.$icons[EntityType.CONTRACT]} ${EntityType.CONTRACT}--text`
            });
            const locationDisplay = this.$locationDisplay(location, true, true);
            const contractNumber = location.contractNumber || '';
            return {
                displayText: `${locationDisplay}${contractNumber ? ` — ${contractNumber}` : ''}`,
                value: location?.id,
                location
            };
        });
    }
    emitInput(val) {
        return val;
    }
    syncBin(val) {
        this.locationIds = val;
    }
    created() {
        this.fetchAllBins();
    }
    async fetchAllBins() {
        try {
            const body = {
                includeArchived: false,
                skip: 0,
                sort: ['BuildingAsc'],
                take: -1
            };
            const resp = await getLocations(body);
            const locations = resp.data.results || [];
            if (locations.length) {
                const existingOptions = this.allBinsQueryResult ? [...this.allBinsQueryResult] : [];
                this.allBinsQueryResult = uniq([...existingOptions, ...locations]);
            }
            else {
                // No locations found
                throw new Error('No locations found.');
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to fetch all Locations.'
            });
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectLocationByBin.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectLocationByBin.prototype, "ignoreBinsWithZeroQuantity", void 0);
__decorate([
    Prop({ default: 'Building / Stock / Bin', type: String }),
    __metadata("design:type", String)
], AppSelectLocationByBin.prototype, "label", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectLocationByBin.prototype, "multiple", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppSelectLocationByBin.prototype, "unmanagedOnly", void 0);
__decorate([
    Prop({ default: '' }),
    __metadata("design:type", Object)
], AppSelectLocationByBin.prototype, "value", void 0);
__decorate([
    Watch('locationIds', { immediate: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppSelectLocationByBin.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], AppSelectLocationByBin.prototype, "syncBin", null);
AppSelectLocationByBin = __decorate([
    Component
], AppSelectLocationByBin);
export default AppSelectLocationByBin;
