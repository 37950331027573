var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-item_instances-lighten2)",
      "modal-width": "85vw",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Cancel"), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedPartReferencesActualized.length ? _c('div', [_c('Alert', {
    attrs: {
      "type": "warning"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.computedPartReferencesActualized.length))]), _vm._v(" parts have been actualized and are under revision control. No editing is permitted for these at this time. ")])], 1) : _vm._e()]), _c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('p', [_vm._v(" Updating these "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.computedPartReferencesThatAreUpdatable.length))]), _vm._v(" parts ")]), _c('ul', {
    style: `max-height: 200px; columns: ${_vm.computedPartReferencesThatAreUpdatable.length > 10 ? '3' : '1'}`
  }, _vm._l(_vm.computedPartReferencesThatAreUpdatable, function (partReference) {
    return _c('li', {
      key: partReference.id
    }, [_c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
      ...partReference
    }, false))], 1);
  }), 0)]), _c('div', [_c('div', {
    staticClass: "mb-2"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Is this a Tool?"
    },
    model: {
      value: _vm.formData.isTool,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isTool", $$v);
      },
      expression: "formData.isTool"
    }
  })], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.formData.drawingDescription,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "drawingDescription", $$v);
      },
      expression: "formData.drawingDescription"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Drawing number"
    },
    model: {
      value: _vm.formData.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "drawingNumber", $$v);
      },
      expression: "formData.drawingNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "AsBuilt"
    },
    model: {
      value: _vm.formData.asBuiltNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "asBuiltNumber", $$v);
      },
      expression: "formData.asBuiltNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "color": _vm.EntityType.ITEM_MASTER,
      "label": "Side",
      "sort": ""
    },
    model: {
      value: _vm.formData.side,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "side", $$v);
      },
      expression: "formData.side"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Size"
    },
    model: {
      value: _vm.formData.size,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "size", $$v);
      },
      expression: "formData.size"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Serial"
    },
    model: {
      value: _vm.formData.serialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "serialNumber", $$v);
      },
      expression: "formData.serialNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppText', {
    attrs: {
      "label": "Lot"
    },
    model: {
      value: _vm.formData.lotNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "lotNumber", $$v);
      },
      expression: "formData.lotNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth mb-2"
  }, [_c('AppSelectEnum', {
    attrs: {
      "display": _vm.InventoryClassDisplay,
      "enum": _vm.InventoryClass,
      "sort": false,
      "value": _vm.formData.itemClass && _vm.formData.itemClass[0],
      "label": "Class",
      "monospace-selections": ""
    },
    on: {
      "input": function ($event) {
        _vm.formData.itemClass = [$event];
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppText', {
    attrs: {
      "label": "Calibration number"
    },
    model: {
      value: _vm.formData.calibrationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "calibrationNumber", $$v);
      },
      expression: "formData.calibrationNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppText', {
    attrs: {
      "label": "CEI number"
    },
    model: {
      value: _vm.formData.contractEndingItemNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "contractEndingItemNumber", $$v);
      },
      expression: "formData.contractEndingItemNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid my-2"
  }, [_c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Birth"
    },
    model: {
      value: _vm.formData.birthDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "birthDate", $$v);
      },
      expression: "formData.birthDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Last calibration"
    },
    model: {
      value: _vm.formData.lastCalibrationDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "lastCalibrationDate", $$v);
      },
      expression: "formData.lastCalibrationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Usage life expiry"
    },
    model: {
      value: _vm.formData.usageLifeExpirationDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "usageLifeExpirationDate", $$v);
      },
      expression: "formData.usageLifeExpirationDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third mb-2"
  }, [_c('AppDate', {
    attrs: {
      "label": "Shelf life expiry"
    },
    model: {
      value: _vm.formData.shelfLifeExpirationDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "shelfLifeExpirationDate", $$v);
      },
      expression: "formData.shelfLifeExpirationDate"
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half"
  }, [_c('AppText', {
    attrs: {
      "label": "Location"
    },
    model: {
      value: _vm.formData.location,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "location", $$v);
      },
      expression: "formData.location"
    }
  })], 1), _c('div', {
    staticClass: "col-full mt-2"
  }, [_c('AppMarkdown', {
    attrs: {
      "label": "Notes"
    },
    model: {
      value: _vm.formData.notes,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "notes", $$v);
      },
      expression: "formData.notes"
    }
  })], 1)])])], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };