var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.entity ? _c('c-progress') : _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', [_vm._v(" Enter the "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(" number you want this "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(" assigned. All other "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v("s will shift to reflect this change. "), _vm.isDuplication ? _c('div', [_vm._v("Update approvals for this duplicated task.")]) : _vm._e()]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": `${_vm.stepDisplayText} Number`,
            "autofocus": "",
            "min": 1,
            "max": _vm.maxStepNumber
          },
          model: {
            value: _vm.formData.stepNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "stepNumber", $$v);
            },
            expression: "formData.stepNumber"
          }
        })], 1)], 1)], 1), !_vm.shouldShowProcedureTextOnly ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('c-text', {
          attrs: {
            "clearable": "",
            "label": "Warning",
            "messages": "Used for any operation that could result in injury/death to personnel if not followed.",
            "c-value": _vm.formData.warning
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.warning = $event.detail;
            }
          }
        }), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('Alert', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.warning,
            expression: "formData.warning"
          }],
          staticClass: "ml-4",
          attrs: {
            "type": "error"
          }
        }, [_vm._v(" " + _vm._s(_vm.formData.warning) + " ")])], 1)], 1) : _vm._e(), !_vm.shouldShowProcedureTextOnly ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('c-text', {
          attrs: {
            "clearable": "",
            "label": "Caution",
            "messages": "Used for any operation that could result in damage to equipment if not followed.",
            "c-value": _vm.formData.caution
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.caution = $event.detail;
            }
          }
        }), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('Alert', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.caution,
            expression: "formData.caution"
          }],
          staticClass: "ml-4",
          attrs: {
            "type": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.formData.caution) + " ")])], 1)], 1) : _vm._e(), !_vm.shouldShowProcedureTextOnly ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('c-text', {
          attrs: {
            "clearable": "",
            "label": "Notes",
            "messages": "Used to call attention to useful information that is not a specific procedural instruction.",
            "c-value": _vm.formData.notes
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.notes = $event.detail;
            }
          }
        }), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('AppPageDescription', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.notes,
            expression: "formData.notes"
          }],
          staticClass: "ml-4"
        }, [_vm._v(" " + _vm._s(_vm.formData.notes) + " ")])], 1)], 1) : _vm._e(), _vm.isRca ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('c-text', {
          attrs: {
            "c-value": _vm.formData.title,
            "label": "Issue"
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.title = $event.detail;
            }
          }
        })], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('AppPageDescription', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showMarkdownHelper,
            expression: "showMarkdownHelper"
          }],
          attrs: {
            "color": "info",
            "remove-margin-left": ""
          }
        }, [_vm._v(" In the box below please provide the details about this "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(". "), _c('br'), _vm._v(" This box accepts Markdown. To learn more about Markdown "), _c('a', {
          attrs: {
            "href": "https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax",
            "target": "_blank"
          }
        }, [_vm._v(" visit this resource."), _c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-external-link",
            "icon-size": "12px"
          }
        })], 1)])], 1), _c('AppMarkdown', {
          attrs: {
            "label": _vm.isRca ? 'Solution' : 'Procedure/Action',
            "read-only": false
          },
          on: {
            "focus": _vm.onFocusOfMarkdownControl
          },
          model: {
            value: _vm.formData.action,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "action", $$v);
            },
            expression: "formData.action"
          }
        })], 1), _vm.isRca ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppDate', {
          staticClass: "mb-3",
          attrs: {
            "label": "Start"
          },
          model: {
            value: _vm.formData.overrideStartDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "overrideStartDate", $$v);
            },
            expression: "formData.overrideStartDate"
          }
        })], 1) : _vm._e(), _vm.isRca ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppDate', {
          staticClass: "mb-3",
          attrs: {
            "label": "Estimated completion"
          },
          model: {
            value: _vm.formData.projectedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedDate", $$v);
            },
            expression: "formData.projectedDate"
          }
        })], 1) : _vm._e(), _vm.isRca ? _c('v-flex', {
          staticClass: "mt-3",
          attrs: {
            "xs12": ""
          }
        }, [_c('AppNumber', {
          staticClass: "risk-score-input",
          attrs: {
            "label": "Risk score at completion",
            "min": "0",
            "max": "25"
          },
          model: {
            value: _vm.formData.riskScoreAtCompletion,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "riskScoreAtCompletion", $$v);
            },
            expression: "formData.riskScoreAtCompletion"
          }
        })], 1) : _vm._e(), _vm.isWorkflow ? _c('v-flex', {
          attrs: {
            "xs12": "",
            "md6": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Due"
          },
          model: {
            value: _vm.formData.projectedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedDate", $$v);
            },
            expression: "formData.projectedDate"
          }
        })], 1) : _vm._e(), _vm.isRca || _vm.isWorkflow ? _c('v-flex', {
          staticClass: "mt-2",
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', [_c('v-flex', {
          attrs: {
            "xs12": "",
            "md6": "",
            "xl4": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": `Assign to Non-${_vm.$cosmicSystem} Contact`
          },
          on: {
            "input": function ($event) {
              _vm.formData.assigneeContactId = null;
            }
          },
          model: {
            value: _vm.showAssigneeInput,
            callback: function ($$v) {
              _vm.showAssigneeInput = $$v;
            },
            expression: "showAssigneeInput"
          }
        }), _vm.showAssigneeInput ? _c('AppContact', {
          attrs: {
            "inline-form": "",
            "inline-form-title": "Add new Contact for assignee",
            "label": "Assignee",
            "messages": [`NOTE: ${_vm.$cosmicSystem} User / Group is still required for task approval`]
          },
          model: {
            value: _vm.formData.assigneeContactId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "assigneeContactId", $$v);
            },
            expression: "formData.assigneeContactId"
          }
        }) : _vm._e()], 1)], 1)], 1) : _vm._e(), !_vm.shouldShowProcedureTextOnly ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppExpansionPanel', {
          staticClass: "hardware_callout",
          attrs: {
            "is-open": false,
            "color": "item_instances"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons.inventories
          }
        }), _c('span', {
          staticClass: "hardware_callout_title"
        }, [_vm._v("Attach Hardware")])], 1), _c('AppFormWrapper', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "size": "1"
          }
        }, [_c('AppPageDescription', {
          staticClass: "mb-3"
        }, [_vm._v(" You may select Hardware from: "), _c('c-radio-group', {
          staticClass: "ml-4 hardware_callout_note",
          domProps: {
            "cValue": _vm.hardwareSelectionMode
          },
          on: {
            "c-input": function ($event) {
              _vm.hardwareSelectionMode = $event.detail;
            }
          }
        }, [_c('c-radio', {
          key: 0,
          domProps: {
            "cValue": 0
          }
        }, [_c('span', {
          class: {
            active: _vm.hardwareSelectionMode === 0
          }
        }, [_vm._v("Pick List")])]), _c('c-radio', {
          key: 1,
          domProps: {
            "cValue": 1
          }
        }, [_c('span', {
          class: {
            active: _vm.hardwareSelectionMode === 1
          }
        }, [_vm._v(" Select a piece of " + _vm._s(_vm.$cosmicSystem) + " Inventory ")])]), _c('c-radio', {
          key: 2,
          domProps: {
            "cValue": 2
          }
        }, [_c('span', {
          class: {
            active: _vm.hardwareSelectionMode === 2
          }
        }, [_vm._v(" Enter a completely one-off Hardware ")])])], 1)], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.hardwareSelectionMode === 0 ? _c('AppTable', {
          staticClass: "mb-3",
          attrs: {
            "title": `Previously entered Hardware (${_vm.computedHardwarePickListItems && _vm.computedHardwarePickListItems.length})`,
            "color": _vm.EntityType.INVENTORY,
            "items": _vm.computedHardwarePickListItems,
            "headers": _vm.computedHardwarePickListTableHeaders,
            "selectable-rows": "",
            "show-search-input": ""
          },
          on: {
            "rowClicked": _vm.onClickOfHardwarePickListItem
          }
        }) : _vm._e()], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.isHardwareSelectionModeInventory ? _c('SelectorInventory', {
          attrs: {
            "existing-values": _vm.existingCalloutHardwareValues,
            "override-view-to-manual-mode": _vm.overrideHardwareSelectorViewToManualMode,
            "filters": {
              hasQuantity: true
            },
            "hide-create-inventory": "",
            "hide-show-other-context-filter": "",
            "keep-search-results-open": "",
            "output-mode": "object",
            "selectable-rows": "",
            "size": "0"
          },
          on: {
            "item-added": function ($event) {
              return _vm.showSnackbar('Hardware added');
            },
            "viewModeChanged": _vm.onHardwareViewModeChange
          },
          model: {
            value: _vm.formData.selectedCalloutHardware,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedCalloutHardware", $$v);
            },
            expression: "formData.selectedCalloutHardware"
          }
        }) : _vm._e()], 1), _c('v-fade-transition', [_c('AppTableActions', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "items": _vm.selectedCalloutHardwareTableItems,
            "headers": _vm.computedHardwarePickListTableHeaders,
            "show-count": "",
            "show-search-input": "",
            "title": "Selected Hardware"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function ({
              item
            }) {
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function ($event) {
                    return _vm.onClickOfRemoveHardwarePickListItem(item);
                  }
                }
              })];
            }
          }], null, false, 3825281355)
        })], 1)], 1)], 1)], 1) : _vm._e(), !_vm.shouldShowProcedureTextOnly ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppExpansionPanel', {
          staticClass: "hardware_callout",
          attrs: {
            "is-open": false,
            "color": "item_instances"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons['MANAGE_PAGE']
          }
        }), _c('span', {
          staticClass: "hardware_callout_title"
        }, [_vm._v("Attach Tools")])], 1), _c('AppFormWrapper', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "size": "1"
          }
        }, [_c('AppPageDescription', {
          staticClass: "mb-3"
        }, [_vm._v(" You may select Tools from: "), _c('c-radio-group', {
          staticClass: "ml-4 hardware_callout_note",
          domProps: {
            "cValue": _vm.toolsSelectionMode
          },
          on: {
            "c-input": function ($event) {
              _vm.toolsSelectionMode = $event.detail;
            }
          }
        }, [_c('c-radio', {
          key: 0,
          domProps: {
            "cValue": 0
          }
        }, [_c('span', {
          class: {
            active: _vm.toolsSelectionMode === 0
          }
        }, [_vm._v("Pick List")])]), _c('c-radio', {
          key: 1,
          domProps: {
            "cValue": 1
          }
        }, [_c('span', {
          class: {
            active: _vm.toolsSelectionMode === 1
          }
        }, [_vm._v("Select a piece of " + _vm._s(_vm.$cosmicSystem) + " Inventory")])]), _c('c-radio', {
          key: 2,
          domProps: {
            "cValue": 2
          }
        }, [_c('span', {
          class: {
            active: _vm.toolsSelectionMode === 2
          }
        }, [_vm._v("Enter a completely one-off Tool")])])], 1)], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.toolsSelectionMode === 0 ? _c('AppTable', {
          staticClass: "mb-3",
          attrs: {
            "icon": "fad fa-wrench",
            "title": `Previously Entered Tools (${_vm.computedToolsPickListItems.length})`,
            "color": _vm.EntityType.INVENTORY,
            "items": _vm.computedToolsPickListItems,
            "headers": _vm.computedToolPickListTableHeaders,
            "selectable-rows": "",
            "show-search-input": ""
          },
          on: {
            "rowClicked": _vm.onClickOfToolsPickListItem
          }
        }) : _vm._e()], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.isToolsSelectionModeInventory ? _c('SelectorInventory', {
          attrs: {
            "existing-values": _vm.existingCalloutToolValues,
            "override-view-to-manual-mode": _vm.overrideToolsSelectorViewToManualMode,
            "filters": {
              hasQuantity: true
            },
            "hide-create-inventory": "",
            "hide-show-other-context-filter": "",
            "keep-search-results-open": "",
            "output-mode": "object",
            "selectable-rows": "",
            "size": "0"
          },
          on: {
            "item-added": function ($event) {
              return _vm.showSnackbar('Tool added');
            },
            "viewModeChanged": _vm.onToolsViewModeChange
          },
          model: {
            value: _vm.formData.selectedCalloutTools,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedCalloutTools", $$v);
            },
            expression: "formData.selectedCalloutTools"
          }
        }) : _vm._e()], 1), _c('v-fade-transition', [_c('AppTable', {
          attrs: {
            "icon": "fad fa-wrench",
            "title": `Selected Tools (${_vm.formData.selectedCalloutTools && _vm.formData.selectedCalloutTools.length})`,
            "color": _vm.EntityType.INVENTORY,
            "items": _vm.selectedCalloutToolsTableItems,
            "headers": _vm.computedToolPickListTableHeaders,
            "selectable-rows": "",
            "show-search-input": ""
          },
          on: {
            "rowClicked": _vm.onClickOfRemoveToolsPickListItem
          }
        })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppExpansionPanel', {
          staticClass: "hardware_callout",
          attrs: {
            "color": _vm.EntityType.CHANGE_SET,
            "is-open": _vm.shouldShowProcedureTextOnly ? true : false
          },
          on: {
            "input": function ($event) {
              _vm.isCriteriaPanelOpen = $event;
            }
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.CHANGE_SET]
          }
        }), _c('span', {
          staticClass: "hardware_callout_title"
        }, [_vm._v("Completion criteria")])], 1), _c('SelectorAuthorityOverride', {
          attrs: {
            "hideMip": _vm.shouldHideMip,
            "read-only": _vm.isReadOnly,
            "required": !_vm.hasAuthoritiesOverride,
            "hide-save-as-art-button": ""
          },
          on: {
            "show-mip-form": function ($event) {
              _vm.isShowingMipForm = $event;
            }
          },
          model: {
            value: _vm.formData.authoritiesOverride,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "authoritiesOverride", $$v);
            },
            expression: "formData.authoritiesOverride"
          }
        })], 1), _c('v-snackbar', {
          staticClass: "user_group_added_snackbar",
          attrs: {
            "timeout": 2000,
            "absolute": "",
            "bottom": "",
            "color": "info",
            "right": ""
          },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v;
            },
            expression: "snackbar"
          }
        }, [_c('div', {
          staticClass: "text-xs-center"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.USER_GROUP]
          }
        }), _c('span', [_vm._v(_vm._s(_vm.snackbarMessage))])], 1)])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [!_vm.workOrderStepToEditId ? _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateOrEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateOrUpdateWorkOrderStep
          }
        }, [_vm._v(" Create " + _vm._s(_vm.stepDisplayText) + " ")]) : _vm._e(), !_vm.isDuplication ? _c('v-flex', [_vm.workOrderStepToEditId ? _c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableCreateOrEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateOrUpdateWorkOrderStep
          }
        }) : _vm._e()], 1) : _vm._e(), _vm.isDuplication ? _c('v-flex', [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableCreateOrEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveDuplicate
          }
        })], 1) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Cancel")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };