var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetAuthorityType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { objectArraysMatchOrder } from '~/nasa_ui/utils/helpers/objectArraysMatchOrder';
import { transformUserGroupResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SelectorAuthorityOverride = class SelectorAuthorityOverride extends Mixins(BaseCosmic, ReadOnlyMixin) {
    AppDynamicWrapperType = AppDynamicWrapperType;
    authorityOverrides = []; // represents userGroups + MIP data
    showGroupsSelector = true;
    showMipForm = false;
    userGroups = [];
    hideMip;
    required;
    size;
    value;
    get shouldDisableAddMipButton() {
        return this.userGroups.length === 0 || this.showMipForm || !this.userGroupsForAddMipForm.length;
    }
    // all the groups that DO NOT have a MIP assigned
    get userGroupsForAddMipForm() {
        return this.userGroups.filter((group) => {
            // find the authority override for this group
            const authorityOverride = this.authorityOverrides.find((authOverride) => authOverride.groupCode === group.code);
            // exclude it if it already has a MIP
            return authorityOverride?.authorityType === ChangeSetAuthorityType.DEFAULT;
        });
    }
    async fetchUserGroupsByCode(groupCodes) {
        try {
            if (!groupCodes.length) {
                return [];
            }
            const params = {
                code: groupCodes
            };
            const resp = await this.$http.get('/user-groups', { params });
            if (resp.data.results?.length) {
                return transformUserGroupResponses(resp.data.results);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        return [];
    }
    // called from ui
    onCancelMip() {
        this.showMipForm = false;
    }
    // called from ui
    onClickOfAddMip() {
        const selector = this.$refs['app_cosmic_user_groups'];
        selector.onClickOfCancelAdd();
        this.showMipForm = true;
    }
    // called from ui
    onMipAdded(authorityOverride) {
        this.authorityOverrides = this.authorityOverrides.map((authOverride) => {
            if (authorityOverride.groupCode === authOverride.groupCode) {
                return authorityOverride;
            }
            return authOverride;
        });
        this.showMipForm = false;
        this.emitInput(this.authorityOverrides);
    }
    emitInput(authorityOverrides) { }
    // if the authorityOverrides are reordered, let's reorder the userGroups to match
    onChangeOfAuthorityOverrides(authorityOverrides) {
        if (this.userGroupsMatchAuthorityOverrides(this.userGroups, authorityOverrides)) {
            return;
        }
        this.userGroups = authorityOverrides
            .map((authorityOverride) => authorityOverride.userGroup)
            .filter((_) => _);
        this.emitInput(authorityOverrides);
    }
    onChangeOfShowMipForm(showMipForm) { }
    // when userGroups change, update authorityOverrides
    onChangeOfUserGroups(userGroups) {
        if (this.userGroupsMatchAuthorityOverrides(userGroups, this.authorityOverrides)) {
            return;
        }
        this.authorityOverrides = userGroups.map((group) => {
            // see if we already have an authority override for this group
            const matchingAuthorityOverride = this.authorityOverrides.find((authOverride) => authOverride.groupCode === group.code);
            // return the match or create a new authority override
            return (matchingAuthorityOverride || {
                groupCode: group.code,
                authorityType: ChangeSetAuthorityType.DEFAULT,
                manualInspectionPointExplanation: null,
                manualInspectionPointType: null,
                userGroup: group
            });
        });
        this.emitInput(this.authorityOverrides);
    }
    userGroupsMatchAuthorityOverrides(userGroups, authorityOverrides) {
        const userGroups2 = authorityOverrides.map((_) => (_.userGroup || []));
        return objectArraysMatchOrder(userGroups, userGroups2, 'code');
    }
    async onChangeOfValue(authorityOverrides) {
        if (objectArraysMatchOrder(authorityOverrides || [], this.authorityOverrides, 'groupCode')) {
            return;
        }
        // if the authorityOverrides have group codes only, fetch the full userGroups
        if (authorityOverrides?.some((authOverride) => !authOverride.userGroup)) {
            const groupCodes = authorityOverrides.map((authOverride) => authOverride.groupCode).filter((_) => _);
            if (groupCodes.length !== authorityOverrides.length) {
                throw new Error('Some authorityOverrides are missing groupCodes.');
            }
            const userGroups = await this.fetchUserGroupsByCode(groupCodes);
            authorityOverrides = authorityOverrides.map((authOverride) => {
                const userGroup = userGroups.find((group) => group.code === authOverride.groupCode);
                if (!userGroup) {
                    throw new Error(`Could not find userGroup for groupCode: ${authOverride.groupCode}`);
                }
                return {
                    ...authOverride,
                    userGroup
                };
            });
        }
        this.authorityOverrides = [...(authorityOverrides || [])];
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorAuthorityOverride.prototype, "hideMip", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorAuthorityOverride.prototype, "required", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SelectorAuthorityOverride.prototype, "size", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SelectorAuthorityOverride.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectorAuthorityOverride.prototype, "emitInput", null);
__decorate([
    Watch('authorityOverrides', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectorAuthorityOverride.prototype, "onChangeOfAuthorityOverrides", null);
__decorate([
    Emit('show-mip-form'),
    Watch('showMipForm'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], SelectorAuthorityOverride.prototype, "onChangeOfShowMipForm", null);
__decorate([
    Watch('userGroups', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectorAuthorityOverride.prototype, "onChangeOfUserGroups", null);
__decorate([
    Watch('value', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], SelectorAuthorityOverride.prototype, "onChangeOfValue", null);
SelectorAuthorityOverride = __decorate([
    Component
], SelectorAuthorityOverride);
export default SelectorAuthorityOverride;
