var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.currentUser ? _c('div', [_c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-users-base)",
      "is-open": true
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": "fad fa-tasks-alt",
      "icon-color": "var(--v-users-base)"
    }
  }), _vm._v(" Navbar settings ")], 1), _c('div', {
    staticClass: "navbar-settings mb-4"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Show new browser window icon"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('new_tab_icon');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showNewBrowserWindowIcon,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showNewBrowserWindowIcon", $$v);
      },
      expression: "formData.showNewBrowserWindowIcon"
    }
  }), _vm.shouldShowLimitedLifeLink ? _c('AppCheckbox', {
    attrs: {
      "label": "Show \"Limited Life\" link"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('limited_life_link');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showLimitedLifeLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showLimitedLifeLink", $$v);
      },
      expression: "formData.showLimitedLifeLink"
    }
  }) : _vm._e(), _vm.shouldShowCalibrationsLink ? _c('AppCheckbox', {
    attrs: {
      "label": "Show \"Calibrations\" link"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('calibrations_link');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showCalibrationsLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showCalibrationsLink", $$v);
      },
      expression: "formData.showCalibrationsLink"
    }
  }) : _vm._e(), _c('AppCheckbox', {
    attrs: {
      "label": "Show \"Favorites\" link"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('favorites_link');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showFavoritesLink,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showFavoritesLink", $$v);
      },
      expression: "formData.showFavoritesLink"
    }
  }), _c('AppCheckbox', {
    attrs: {
      "label": "Show \"My Approvals\" link"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('my_approvals');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showMyApprovals,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showMyApprovals", $$v);
      },
      expression: "formData.showMyApprovals"
    }
  }), _c('AppCheckbox', {
    attrs: {
      "label": "Show active support context"
    },
    on: {
      "mouseover": function ($event) {
        return _vm.highlightNavbarItem('support_context');
      },
      "mouseleave": function ($event) {
        return _vm.highlightNavbarItem(null);
      }
    },
    model: {
      value: _vm.formData.showUserSupport,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "showUserSupport", $$v);
      },
      expression: "formData.showUserSupport"
    }
  })], 1)]), _c('AppExpansionPanel', {
    staticClass: "mt-2",
    attrs: {
      "color": "yellow darken-2",
      "is-open": true
    }
  }, [_c('div', {
    staticClass: "ml-1",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon-color": "var(--cosmic-text)",
      "icon": "fas fa-star"
    }
  }), _c('span', {
    staticClass: "title ml-1"
  }, [_vm._v("Favorites")])], 1), _c('UserFavorites', {
    attrs: {
      "hideTitle": ""
    }
  })], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };