var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AssemblyTemplateResponseWithChildren } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { clearAssemblyTemplateCollectionCache, getAssemblyTemplate, putAssemblyTemplate } from '~/nasa_ui/utils';
import { MegaAllocationType, MegaAllocationTypeDisplay, transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalMegaClone = class ModalMegaClone extends Mixins(BaseModal) {
    MegaAllocationTypeDisplay = MegaAllocationTypeDisplay;
    MegaAllocationType = MegaAllocationType;
    navigateAfterClone = false;
    selectedEntity = null;
    formData = {
        attributes: {
            megaAllocationType: null
        },
        cloneWaivers: false,
        newRootName: null,
        newRootSubType: null
    };
    get transformedSelectedEntity() {
        if (!this.selectedEntity) {
            return null;
        }
        return transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0];
    }
    assemblyTemplateId;
    async onClickOfClone() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                cloneWaivers: this.formData.cloneWaivers ?? false,
                newRootName: this.formData.newRootName || this.selectedEntity.name,
                newRootSubType: this.selectedEntity.subType
            };
            const resp = await this.$http.post(`/assembly-templates/${this.selectedEntity.id}/clone`, body);
            // if you want to update the mega allocation type
            if (resp.data.id && resp.data.attributes?.megaAllocationType !== this.formData.attributes.megaAllocationType) {
                // save it
                const body = {
                    attributes: Object.assign({}, resp.data.attributes, {
                        megaAllocationType: this.formData.attributes.megaAllocationType
                    }),
                    autoHardwareListId: resp.data.autoHardwareListId ?? null,
                    digitalLibraryRecordId: resp.data.digitalLibraryRecordId ?? null,
                    documentId: resp.data.documentId ?? null,
                    installedOn: resp.data.installedOn,
                    maxQuantity: resp.data.maxQuantity,
                    minQuantity: resp.data.minQuantity,
                    name: resp.data.name ?? null,
                    needDate: resp.data.needDate ?? null,
                    parentId: resp.data.parentId ?? null,
                    parts: resp.data.parts ?? null,
                    pbsItemId: resp.data.pbsItemId ?? null,
                    sequence: resp.data.sequence,
                    subType: resp.data.subType,
                    waiverDate: resp.data.waiverDate ?? null,
                    waiverNumber: resp.data.waiverNumber ?? null
                };
                const savedResponse = await putAssemblyTemplate(resp.data.id, body);
            }
            clearAssemblyTemplateCollectionCache();
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Cloned.'
            });
            if (this.navigateAfterClone) {
                // navigate to newly cloned assembly template
                this.$router.push({
                    path: `/mega_allocation/${resp.data.id}`
                });
            }
            this.emitSaved(resp.data);
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved(data) {
        return data;
    }
    async fetchSelectedEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
            if (this.selectedEntity.attributes?.megaAllocationType) {
                this.formData.attributes.megaAllocationType = this.selectedEntity.attributes.megaAllocationType;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalMegaClone.prototype, "assemblyTemplateId", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof AssemblyTemplateResponseWithChildren !== "undefined" && AssemblyTemplateResponseWithChildren) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalMegaClone.prototype, "emitSaved", null);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalMegaClone.prototype, "fetchSelectedEntity", null);
ModalMegaClone = __decorate([
    Component
], ModalMegaClone);
export default ModalMegaClone;
