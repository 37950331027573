var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-card', {
    attrs: {
      "color": "purple darken-2",
      "dark": ""
    }
  }, [_c('v-card-text', [_c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false,
      "show-horizontal-rule": false
    }
  }, [_vm._v("Emails")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Total",
      "bold": "",
      "align": "right"
    }
  }, [_vm.isLoading ? _c('c-progress') : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.mappedEmailLogs.length)))])], 1)], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last day",
      "bold": "",
      "align": "right"
    }
  }, [_vm.isLoading ? _c('c-progress') : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.emailLogCounts.day)))])], 1)], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last week",
      "bold": "",
      "align": "right"
    }
  }, [_vm.isLoading ? _c('c-progress') : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.emailLogCounts.week)))])], 1)], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last month",
      "bold": "",
      "align": "right"
    }
  }, [_vm.isLoading ? _c('c-progress') : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.emailLogCounts.month)))])], 1)], 1)], 1)], 1)], 1), _c('AppTableExpandable', {
    key: "errorLogsTable",
    attrs: {
      "headers": _vm.emailLogHeaders,
      "items": _vm.mappedEmailLogs,
      "loading": _vm.isLoading,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "user-defined-pagination": _vm.paginationSettings,
      "color": "purple darken-2",
      "show-search-input": ""
    },
    model: {
      value: _vm.selectedLog,
      callback: function ($$v) {
        _vm.selectedLog = $$v;
      },
      expression: "selectedLog"
    }
  }, [_c('template', {
    slot: "expanded-row"
  }, [_vm.selectedLog ? _c('div', [_c('v-layout', {
    staticClass: "pl-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('AppHeader', {
    staticClass: "pt-2",
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("To")]), _c('ul', {
    staticClass: "list-style-none pl-0 pt-2"
  }, _vm._l(_vm.selectedLog.recipientContacts.nodes, function (contact) {
    return _c('li', {
      key: contact.id
    }, [_vm._v(" " + _vm._s(_vm.$contactDisplay(contact)) + " - " + _vm._s(contact.email[0]) + " ")]);
  }), 0), _c('AppHeader', {
    staticClass: "pt-2",
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("Email content")]), _c('p', {
    staticClass: "pt-2",
    domProps: {
      "innerHTML": _vm._s(_vm.selectedLog.messageHtml)
    }
  }), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "dark": ""
    }
  }, [_c('vue-json-pretty', {
    attrs: {
      "path": 'res',
      "data": _vm.selectedLog
    }
  })], 1)], 1)], 1)], 1) : _vm._e()])], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };