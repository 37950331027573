var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": false,
      "entity-type": _vm.EntityType.ITEM_DRAWING,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING,
      "headers": _vm.tableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "disable-initial-sort": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    },
    scopedSlots: _vm._u([{
      key: "_aliases",
      fn: function ({
        item
      }) {
        return [item.aliases && item.aliases.length ? _c('ul', {
          staticClass: "pa-0 list-style-none",
          staticStyle: {
            "max-height": "80px",
            "overflow-y": "auto",
            "width": "120px"
          }
        }, _vm._l(item._aliases, function (alias) {
          return _c('li', {
            key: alias,
            staticClass: "pl-0 font-weight-bold"
          }, [_vm._v(" " + _vm._s(alias) + " ")]);
        }), 0) : _c('p', {
          staticClass: "pl-0 font-weight-bold"
        }, [_vm._v("—")])];
      }
    }], null, false, 537966452)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };