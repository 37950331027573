import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { InventoryClass, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, InventoryClassDisplay, SideDisplay } from '~/nasa_ui/types';
import { putPartReference } from '~/nasa_ui/utils';
let ModalPartReferenceEditMultiple = class ModalPartReferenceEditMultiple extends Mixins(BaseModal) {
    InventoryClassDisplay = InventoryClassDisplay;
    InventoryClass = InventoryClass;
    SideDisplay = SideDisplay;
    Side = Side;
    formData = {
        asBuiltNumber: null,
        attributes: {},
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: null,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        notes: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: null,
        size: null,
        usageLifeExpirationDate: null
    };
    partReferences;
    get computedPartReferencesActualized() {
        return this.partReferences.filter((partReference) => partReference.isItemInstanceActualized);
    }
    get computedPartReferencesThatAreUpdatable() {
        return this.partReferences.filter((partReference) => !partReference.isItemInstanceActualized);
    }
    async onClickOfSave() {
        if (!this.computedPartReferencesThatAreUpdatable) {
            return;
        }
        const updates = this.computedPartReferencesThatAreUpdatable.map((partReferenceToUpdate) => {
            const body = {
                asBuiltNumber: this.formData.asBuiltNumber || partReferenceToUpdate.asBuiltNumber || null,
                attributes: partReferenceToUpdate.attributes,
                birthDate: this.formData.birthDate || partReferenceToUpdate.birthDate || null,
                calibrationNumber: this.formData.calibrationNumber || partReferenceToUpdate.calibrationNumber || null,
                contractEndingItemNumber: this.formData.contractEndingItemNumber || partReferenceToUpdate.contractEndingItemNumber || null,
                drawingDescription: this.formData.drawingDescription || partReferenceToUpdate.drawingDescription || null,
                drawingNumber: this.formData.drawingNumber || partReferenceToUpdate.drawingNumber || null,
                isTool: (this.formData.isTool !== null ? this.formData.isTool : partReferenceToUpdate.isTool) ?? false,
                itemClass: this.formData.itemClass || partReferenceToUpdate.itemClass || null,
                lastCalibrationDate: this.formData.lastCalibrationDate || partReferenceToUpdate.lastCalibrationDate || null,
                location: this.formData.location || partReferenceToUpdate.location || null,
                lotNumber: this.formData.lotNumber || partReferenceToUpdate.lotNumber || null,
                notes: (this.formData.notes
                    ? `${partReferenceToUpdate.notes} ${this.formData.notes}`
                    : partReferenceToUpdate.notes) || null,
                serialNumber: this.formData.serialNumber || partReferenceToUpdate.serialNumber || null,
                shelfLifeExpirationDate: this.formData.shelfLifeExpirationDate || partReferenceToUpdate.shelfLifeExpirationDate || null,
                side: (this.formData.side ? this.formData.side : partReferenceToUpdate.side) || Side.NONE,
                size: this.formData.size || partReferenceToUpdate.size || null,
                usageLifeExpirationDate: this.formData.usageLifeExpirationDate || partReferenceToUpdate.usageLifeExpirationDate || null
            };
            return putPartReference(partReferenceToUpdate.id, body);
        });
        try {
            const resp = await Promise.all(updates);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Saved ${this.computedPartReferencesThatAreUpdatable.length} items.`
            });
            // this.emitSaved(resp);
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved(data) {
        return data;
    }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], ModalPartReferenceEditMultiple.prototype, "partReferences", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalPartReferenceEditMultiple.prototype, "emitSaved", null);
ModalPartReferenceEditMultiple = __decorate([
    Component
], ModalPartReferenceEditMultiple);
export default ModalPartReferenceEditMultiple;
