var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CosmicEventResponseWithRefs, EventType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { getAssemblyTemplate } from '~/nasa_ui/utils';
import { transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalEventAllocationWindowCreateEdit = class ModalEventAllocationWindowCreateEdit extends Mixins(BaseModal) {
    selectedAllocationWindow = null;
    selectedEntity = null;
    formData = {
        endDate: null,
        name: null,
        startDate: null,
        subType: EventType.ALLOCATION_WINDOW
    };
    get isValidAllocationWindow() {
        return Boolean(this.formData.startDate) && Boolean(this.formData.endDate);
    }
    get transformedSelectedEntity() {
        if (!this.selectedEntity) {
            return null;
        }
        return transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0];
    }
    assemblyTemplateId;
    async onClickOfCreateNewAllocationWindow() {
        if (!this.isValidAllocationWindow) {
            return;
        }
        try {
            const body = {
                endDate: this.formData.endDate,
                name: `Allocation window for ${this.transformedSelectedEntity._name}`,
                startDate: this.formData.startDate,
                subType: EventType.ALLOCATION_WINDOW
                // assemblyTemplateId: this.selectedMega.id
            };
            const resp = await this.$http.post(`/cosmic-events`, body);
            if (!this.selectedEntity) {
                return;
            }
            const assignEventsToTemplate = {
                cosmicEventIds: [...this.selectedEntity.cosmicEvents.map((evt) => evt.id), resp.data.id]
            };
            const assignToAssemblyTemplateResponse = await this.$http.put(`/assembly-templates/${this.selectedEntity.id}/events`, assignEventsToTemplate);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Allocation window created.'
            });
            this.emitSaved(resp.data);
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfEditAllocationWindow() {
        if (!this.isValidAllocationWindow || !this.selectedAllocationWindow) {
            return;
        }
        try {
            const body = {
                endDate: this.formData.endDate,
                name: `Allocation window for ${this.selectedEntity.name}`,
                startDate: this.formData.startDate,
                subType: EventType.ALLOCATION_WINDOW
            };
            const resp = await this.$http.put(`/cosmic-events/${this.selectedAllocationWindow.id}`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.emitSaved(resp.data);
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved(data) {
        return data;
    }
    async fetchSelectedEntity() {
        if (!this.assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(this.assemblyTemplateId);
            this.selectedEntity = resp.data;
            this.selectedAllocationWindow =
                resp.data.cosmicEvents?.find((evt) => evt.subType === EventType.ALLOCATION_WINDOW) ?? null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSelectedAllocationWindowChange() {
        if (!this.selectedAllocationWindow) {
            this.formData = {
                endDate: null,
                name: null,
                startDate: null,
                subType: EventType.ALLOCATION_WINDOW
            };
        }
        else {
            this.formData.endDate = this.selectedAllocationWindow.endDate;
            this.formData.name = this.selectedAllocationWindow.name;
            this.formData.startDate = this.selectedAllocationWindow.startDate;
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalEventAllocationWindowCreateEdit.prototype, "assemblyTemplateId", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof CosmicEventResponseWithRefs !== "undefined" && CosmicEventResponseWithRefs) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalEventAllocationWindowCreateEdit.prototype, "emitSaved", null);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalEventAllocationWindowCreateEdit.prototype, "fetchSelectedEntity", null);
__decorate([
    Watch('selectedAllocationWindow'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalEventAllocationWindowCreateEdit.prototype, "onSelectedAllocationWindowChange", null);
ModalEventAllocationWindowCreateEdit = __decorate([
    Component
], ModalEventAllocationWindowCreateEdit);
export default ModalEventAllocationWindowCreateEdit;
