var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('draggable', {
    staticClass: "authority_overrides mb-3",
    attrs: {
      "disabled": _vm.isReadOnly,
      "ghost-class": "ghost",
      "handle": ".hamburger",
      "tag": "div"
    },
    on: {
      "end": function ($event) {
        _vm.isDragging = false;
      },
      "start": function ($event) {
        _vm.isDragging = true;
      }
    },
    model: {
      value: _vm.valueLocal,
      callback: function ($$v) {
        _vm.valueLocal = $$v;
      },
      expression: "valueLocal"
    }
  }, [_c('transition-group', {
    staticClass: "pl-0",
    attrs: {
      "tag": "ul"
    }
  }, _vm._l(_vm.valueLocal, function (authorityOverride, index) {
    return _c('li', {
      key: authorityOverride.groupCode,
      staticClass: "authority_override_item white--text justify-start mb-1 pa-2"
    }, [!_vm.isReadOnly ? _c('c-icon-fa', {
      staticClass: "hamburger pr-2",
      attrs: {
        "tooltip": _vm.isDragging ? 'Drag to sort.' : false,
        "icon": "fal fa-bars",
        "icon-color": "white"
      }
    }) : _vm._e(), _c('strong', {
      staticClass: "order_number chip mr-2"
    }, [_vm._v(_vm._s(index + 1))]), _c('strong', [_vm._v(_vm._s(_vm.computedGroupName(authorityOverride)))]), _c('v-spacer'), _vm.shouldShowChips(index) ? _c('div', {
      staticClass: "chips_wrapper mr-3"
    }, [_vm.isGmip(index) ? _c('span', {
      staticClass: "chip mr-2"
    }, [_c('strong', [_vm._v("GMIP")])]) : _c('span', {
      staticClass: "chip mr-2"
    }, [_c('strong', [_vm._v("MIP")])]), _c('v-tooltip', {
      attrs: {
        "bottom": "",
        "max-width": "300px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_vm.mipTypeValue(index) ? _c('span', _vm._g({
            staticClass: "chip mr-2 text-truncate"
          }, on), [_vm._v("Type: "), _c('strong', [_vm._v(_vm._s(_vm.mipTypeValue(index)))])]) : _vm._e()];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.mipTypeValue(index)) + " ")]), _c('v-tooltip', {
      attrs: {
        "bottom": "",
        "max-width": "300px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_vm.mipExplanationValue(index) ? _c('span', _vm._g({
            staticClass: "chip mr-2 text-truncate"
          }, on), [_c('strong', [_vm._v(_vm._s(_vm.mipExplanationValue(index)))])]) : _vm._e()];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.mipExplanationValue(index)) + " ")])], 1) : _vm._e(), !_vm.isReadOnly ? _c('ButtonMiniActionRemoveFromList', {
      attrs: {
        "icon-color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfRemoveItem(index);
        }
      }
    }) : _vm._e()], 1);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };