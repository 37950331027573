var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-change_sets-base)",
      "modal-width": _vm.computedModalWidth,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Close "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_vm.transformedSelectedEntity ? _c('div', [_c('c-card', {
    attrs: {
      "border-color": "var(--v-change_sets-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.change_sets,
      "icon-color": "var(--v-change_sets-base)"
    }
  }), _vm._v(" Line item details ")], 1), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Item status"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._deliveryOrderLineItemStatus) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Item type"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._lineItemType) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Task owner"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._ownerContact || _vm.DEFAULT_DASH) + " ")])])], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "DIL #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._deliveryItemsListNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-twelveth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "J Doc #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._jDocumentNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Shipper #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._shipperNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "WBS"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._workBreakdownStructureNumber) + " ")])])], 1)]), _c('hr', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._createdDateTime) + " ")]), _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._createdDateTimeDistanceInWordsFromToday) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Opened"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._openedDate) + " ")]), _vm.transformedSelectedEntity.openedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._openedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Closed"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._closedDate) + " ")]), _vm.transformedSelectedEntity.closedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._closedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Est. completion"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._projectedDate) + " ")]), _vm.transformedSelectedEntity.projectedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._projectedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Planned expiry"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._plannedExpirationDate) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Computed need"
    }
  }, [_vm.transformedSelectedEntity._partReference ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._computedNeedDate) + " ")]) : _c('span', {
    staticClass: "monospace_font"
  }, [_c('i', [_vm._v("No part selected")])])])], 1)])]), _vm.transformedSelectedEntity.partReference ? _c('c-card', {
    staticClass: "my-2",
    attrs: {
      "start-collapsed": "",
      "border-color": "var(--v-item_instances-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon-size": "18px",
      "icon": _vm.$icons.part_reference
    }
  }), _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
    ..._vm.transformedSelectedEntity.partReference
  }, false))], 1), _c('AppPartReferenceDisplay', {
    attrs: {
      "part-reference-id": _vm.transformedSelectedEntity.partReference.id
    }
  })], 1) : _vm._e()], 1) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };