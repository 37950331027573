var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass, JDocumentType, JDocumentTypeDisplay, Side } from '~/db_types';
import { DeliveryOrderLineItemDetailsRequest, DeliveryOrderLineItemStatus, DeliveryOrderLineItemType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { InventoryClassDisplay, Maybe, SideDisplay } from '~/nasa_ui/types';
import { DeliveryOrderLineItemStatusDisplay, DeliveryOrderLineItemTypeDisplay } from '~/nasa_ui/types/enums/document';
import { getAssemblyTemplate, getAssemblyTemplates, getAutoHardwareLists, getPartReference, getPartReferences, sortObjectBy } from '~/nasa_ui/utils';
import { transformAutoHardwareListResponseWithCounts, transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalDeliveryOrderLineItemDetailsRequest = class ModalDeliveryOrderLineItemDetailsRequest extends Mixins(BaseModal) {
    autoHardwareLists = null;
    computedSelectedPartReference = null;
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    DeliveryOrderLineItemType = DeliveryOrderLineItemType;
    DeliveryOrderLineItemTypeDisplay = DeliveryOrderLineItemTypeDisplay;
    InventoryClass = InventoryClass;
    InventoryClassDisplay = InventoryClassDisplay;
    isLoadingPartsForSelectedAutoHardwareList = false;
    isFetchingMegaRootAssemblyTemplates = false;
    JDocumentType = JDocumentType;
    JDocumentTypeDisplay = JDocumentTypeDisplay;
    megaRootAssemblyTemplates = null;
    selectablePartsForMegaNeed = null;
    selectablePartsForSelectedAutoHardwareList = null;
    selectedAutoHardwareListId = null;
    selectedMegaNeedAssemblyTemplateId = null;
    selectedPartReferenceIdViaAutoHardwareListing = null;
    selectedPartReferenceIdViaMegaNeed = null;
    showPartReferenceForm = false;
    Side = Side;
    SideDisplay = SideDisplay;
    formData = {
        attributes: {},
        createPartReference: null,
        deliveryItemsListNumber: null,
        deliveryOrderLineItemStatus: null,
        jDocumentNumber: null,
        lineItemQuantity: 1,
        lineItemType: null,
        openedDate: null,
        ownerContactId: null,
        partReferenceId: null,
        plannedExpirationDate: null,
        projectedDate: null,
        shipperNumber: null,
        workBreakdownStructureNumber: null
    };
    formDataPartReference = {
        asBuiltNumber: null,
        attributes: {},
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: null,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: null,
        size: null,
        usageLifeExpirationDate: null
    };
    lineItem;
    get computedSelectedPartReferenceId() {
        return (this.selectedPartReferenceIdViaAutoHardwareListing ||
            this.selectedPartReferenceIdViaMegaNeed ||
            this.formData.partReferenceId ||
            null);
    }
    get existingPartReferenceId() {
        return this.lineItem?.partReferenceId;
    }
    get selectOptionsItemListing() {
        return this.transformedAutoHardwareLists
            .filter((ahl) => ahl.partReferenceCount)
            .map((ahl) => {
            return {
                displayText: ahl._name,
                value: ahl.id
            };
        });
    }
    get selectOptionsMegaNeed() {
        if (!this.megaRootAssemblyTemplates) {
            return [];
        }
        return this.megaRootAssemblyTemplates
            .map((mega) => {
            return {
                displayText: mega.name,
                value: mega.id
            };
        })
            .sort(sortObjectBy('displayText'));
    }
    get selectOptionsPartReferenceFromSelectedItemListing() {
        if (!this.selectablePartsForSelectedAutoHardwareList) {
            return [];
        }
        return this.selectablePartsForSelectedAutoHardwareList.map((part) => {
            const dn = part.drawingNumber;
            const ab = part.asBuiltNumber && part.asBuiltNumber !== DB_DEFAULT ? `ab${part.asBuiltNumber} ` : '';
            const side = part.side && part.side !== Side.NONE ? `sd${SideDisplay.get(part.side)} ` : '';
            const size = part.size && part.size !== DB_DEFAULT ? `sz${part.size} ` : '';
            const serial = part.serialNumber ? `sn${part.serialNumber} ` : '';
            const lot = part.lotNumber ? `ln${part.lotNumber}` : '';
            return {
                displayText: `${dn} ${ab}${side}${size}${serial}${lot}`,
                value: part.id
            };
        });
    }
    get selectOptionsPartReferenceFromMegaNeed() {
        if (!this.selectedMegaNeedAssemblyTemplateId) {
            return [];
        }
        return (this.selectablePartsForMegaNeed || []).map((part) => {
            const dn = part.drawingNumber;
            const ab = part.asBuiltNumber && part.asBuiltNumber !== DB_DEFAULT ? `ab${part.asBuiltNumber} ` : '';
            const side = part.side && part.side !== Side.NONE ? `sd${SideDisplay.get(part.side)} ` : '';
            const size = part.size && part.size !== DB_DEFAULT ? `sz${part.size} ` : '';
            const serial = part.serialNumber ? `sn${part.serialNumber} ` : '';
            const lot = part.lotNumber ? `ln${part.lotNumber}` : '';
            return {
                displayText: `${dn} ${ab}${side}${size}${serial}${lot}`,
                value: part.id
            };
        });
    }
    get selectedMegaNeedAssemblyTemplateName() {
        return this.megaRootAssemblyTemplates
            ? this.megaRootAssemblyTemplates.find((mega) => mega.id === this.selectedMegaNeedAssemblyTemplateId)?.name
            : '';
    }
    get selectedAutoHardwareListName() {
        return this.transformedAutoHardwareLists.find((ahl) => ahl.id === this.selectedAutoHardwareListId)?._name || null;
    }
    get transformedAutoHardwareLists() {
        return transformAutoHardwareListResponseWithCounts(this.autoHardwareLists || []);
    }
    created() {
        this.fetchAutoHardwareLists();
        this.fetchMegaRootAssemblyTemplates();
    }
    clearAllPartReferenceSelections() {
        this.formData.partReferenceId = null;
        this.computedSelectedPartReference = null;
        this.selectedPartReferenceIdViaAutoHardwareListing = null;
        this.selectedPartReferenceIdViaMegaNeed = null;
    }
    clearPartReferenceForm() {
        this.formDataPartReference = {
            asBuiltNumber: null,
            attributes: {},
            birthDate: null,
            calibrationNumber: null,
            contractEndingItemNumber: null,
            drawingDescription: null,
            drawingNumber: null,
            isTool: null,
            itemClass: null,
            lastCalibrationDate: null,
            location: null,
            lotNumber: null,
            serialNumber: null,
            shelfLifeExpirationDate: null,
            side: null,
            size: null,
            usageLifeExpirationDate: null
        };
        this.showPartReferenceForm = false;
    }
    findPartReferenceFromId(id) {
        const partFromAhl = this.selectablePartsForSelectedAutoHardwareList?.find((part) => part.id === id);
        const partFromMega = this.selectablePartsForMegaNeed?.find((part) => part.id === id);
        const part = partFromAhl || partFromMega;
        return part ? transformPartReferenceResponses([part])[0] : null;
    }
    async fetchAutoHardwareLists() {
        try {
            this.isFetching = true;
            const params = {
                take: -1
            };
            const resp = await getAutoHardwareLists(params);
            this.autoHardwareLists = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchMegaRootAssemblyTemplates() {
        try {
            this.isFetchingMegaRootAssemblyTemplates = true;
            const params = {
                rootsOnly: true,
                take: -1
            };
            const resp = await getAssemblyTemplates(params);
            this.megaRootAssemblyTemplates = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    uiClickOfCreateNewPartReference(text) {
        // Means the user was looking for a particular part reference via an AHL
        if (this.selectedAutoHardwareListId) {
            const ahl = this.transformedAutoHardwareLists.find((ahl) => ahl.id === this.selectedAutoHardwareListId);
            if (ahl) {
                this.formDataPartReference.drawingDescription = ahl.description;
                if (ahl.drawingNumberPrefix?.length && ahl.drawingNumberPrefix.length === 1) {
                    this.formDataPartReference.drawingNumber = ahl.drawingNumberPrefix[0];
                }
                this.showPartReferenceForm = true;
            }
        }
        else if (this.selectedMegaNeedAssemblyTemplateId) {
            const mega = this.megaRootAssemblyTemplates?.find((at) => at.id === this.selectedMegaNeedAssemblyTemplateId);
            if (mega) {
                this.formDataPartReference.drawingDescription = mega.autoHardwareListDescription || '';
            }
            this.showPartReferenceForm = true;
        }
        this.clearAllPartReferenceSelections();
    }
    async onClickOfEmitLineItem() {
        const obj = {
            attributes: this.formData.attributes,
            deliveryItemsListNumber: this.formData.deliveryItemsListNumber ?? null,
            deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
            jDocumentNumber: this.formData.jDocumentNumber ?? null,
            lineItemQuantity: this.formData.lineItemQuantity ?? null,
            lineItemType: this.formData.lineItemType ?? undefined,
            openedDate: this.formData.openedDate ?? null,
            ownerContactId: this.formData.ownerContactId ?? null,
            partReferenceId: this.computedSelectedPartReferenceId ?? undefined,
            plannedExpirationDate: this.formData.plannedExpirationDate ?? null,
            projectedDate: this.formData.projectedDate ?? null,
            shipperNumber: this.formData.shipperNumber ?? null,
            workBreakdownStructureNumber: this.formData.workBreakdownStructureNumber ?? null,
            createPartReference: this.computedSelectedPartReferenceId
                ? undefined
                : {
                    asBuiltNumber: this.formDataPartReference.asBuiltNumber,
                    attributes: this.formDataPartReference.attributes,
                    birthDate: this.formDataPartReference.birthDate,
                    calibrationNumber: this.formDataPartReference.calibrationNumber,
                    contractEndingItemNumber: this.formDataPartReference.contractEndingItemNumber,
                    drawingDescription: this.formDataPartReference.drawingDescription,
                    drawingNumber: this.formDataPartReference.drawingNumber,
                    isTool: this.formDataPartReference.isTool ?? false,
                    itemClass: this.formDataPartReference.itemClass,
                    lastCalibrationDate: this.formDataPartReference.lastCalibrationDate,
                    location: this.formDataPartReference.location,
                    lotNumber: this.formDataPartReference.lotNumber,
                    notes: this.formDataPartReference.notes,
                    serialNumber: this.formDataPartReference.serialNumber,
                    shelfLifeExpirationDate: this.formDataPartReference.shelfLifeExpirationDate,
                    side: this.formDataPartReference.side ?? undefined,
                    size: this.formDataPartReference.size,
                    usageLifeExpirationDate: this.formDataPartReference.usageLifeExpirationDate
                }
        };
        this.emitLineItem(obj);
        this.closeDialog();
    }
    emitLineItem(lineItem) { }
    onChangeOfLineItem(lineItem) {
        if (!lineItem) {
            return;
        }
        this.formData.attributes = lineItem.attributes;
        this.formData.deliveryItemsListNumber = lineItem.deliveryItemsListNumber ?? null;
        this.formData.deliveryOrderLineItemStatus = lineItem.deliveryOrderLineItemStatus;
        this.formData.jDocumentNumber = lineItem.jDocumentNumber ?? null;
        this.formData.lineItemQuantity = lineItem.lineItemQuantity || 1;
        this.formData.lineItemType = lineItem.lineItemType;
        this.formData.openedDate = lineItem.openedDate;
        this.formData.ownerContactId = lineItem.ownerContactId ?? null;
        this.formData.partReferenceId = lineItem.partReferenceId ?? null;
        this.formData.plannedExpirationDate = lineItem.plannedExpirationDate ?? null;
        this.formData.projectedDate = lineItem.projectedDate ?? null;
        this.formData.shipperNumber = lineItem.shipperNumber ?? null;
        this.formData.workBreakdownStructureNumber = lineItem.workBreakdownStructureNumber ?? null;
    }
    async fetchAssemblyTemplateInformation() {
        if (!this.selectedMegaNeedAssemblyTemplateId) {
            return;
        }
        try {
            this.selectablePartsForMegaNeed = [];
            this.selectedPartReferenceIdViaMegaNeed = null;
            const resp = await getAssemblyTemplate(this.selectedMegaNeedAssemblyTemplateId);
            const parts = resp.data.children?.flatMap((child) => child.parts?.map((p) => p.partReference) || []);
            this.selectablePartsForMegaNeed = parts || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onSelectedAutoHardwareListIdChange() {
        if (!this.selectedAutoHardwareListId) {
            return;
        }
        try {
            this.isLoadingPartsForSelectedAutoHardwareList = true;
            this.selectablePartsForSelectedAutoHardwareList = [];
            this.selectedPartReferenceIdViaAutoHardwareListing = null;
            const resp = await getPartReferences({
                autoHardwareListId: [this.selectedAutoHardwareListId],
                take: -1
            });
            this.selectablePartsForSelectedAutoHardwareList = resp.data.results || [];
            this.isLoadingPartsForSelectedAutoHardwareList = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onPartSelection() {
        this.showPartReferenceForm = false;
    }
    async onComputedSelectedPartReferenceIdChange() {
        if (!this.computedSelectedPartReferenceId) {
            return;
        }
        try {
            const resp = await getPartReference(this.computedSelectedPartReferenceId);
            this.computedSelectedPartReference = resp.data ? transformPartReferenceResponses([resp.data])[0] : null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "lineItem", void 0);
__decorate([
    Emit('line-item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof DeliveryOrderLineItemDetailsRequest !== "undefined" && DeliveryOrderLineItemDetailsRequest) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "emitLineItem", null);
__decorate([
    Watch('lineItem', {
        immediate: true
    }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "onChangeOfLineItem", null);
__decorate([
    Watch('selectedMegaNeedAssemblyTemplateId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "fetchAssemblyTemplateInformation", null);
__decorate([
    Watch('selectedAutoHardwareListId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "onSelectedAutoHardwareListIdChange", null);
__decorate([
    Watch('selectedPartReferenceIdViaAutoHardwareListing'),
    Watch('selectedPartReferenceIdViaMegaNeed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "onPartSelection", null);
__decorate([
    Watch('computedSelectedPartReferenceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemDetailsRequest.prototype, "onComputedSelectedPartReferenceIdChange", null);
ModalDeliveryOrderLineItemDetailsRequest = __decorate([
    Component
], ModalDeliveryOrderLineItemDetailsRequest);
export default ModalDeliveryOrderLineItemDetailsRequest;
