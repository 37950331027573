import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "pa-0"
  }, [_vm._t("breadcrumbs", function () {
    return [_c('v-layout', {
      attrs: {
        "row": "",
        "wrap": "",
        "justify-space-between": ""
      }
    }, [_c('AppBreadcrumbs', {
      staticClass: "mb-3",
      attrs: {
        "breadcrumbs": _vm.breadcrumbs,
        "router-instance": _vm.routerInstance
      },
      scopedSlots: _vm._u([{
        key: "breadcrumb",
        fn: function ({
          item
        }) {
          return [item.overrideIcon ? _c('ButtonMiniAction', {
            attrs: {
              "icon": item.overrideIcon,
              "to": item.to,
              "icon-color": "var(--v-primary-base)",
              "icon-size": "16"
            },
            on: {
              "click": function ($event) {
                return _vm.$router.push(item.to);
              }
            }
          }) : _vm._e()];
        }
      }])
    })], 1)];
  }), _vm._t("title", function () {
    return [_vm.title ? _c('AppHeader', {
      staticClass: "mb-3",
      attrs: {
        "size": "report"
      }
    }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.description && !_vm.showResults ? _c('AppPageDescription', {
      attrs: {
        "remove-margin-left": ""
      }
    }, [_vm._v(_vm._s(_vm.description))]) : _vm._e()];
  }), !_vm.showResults ? _c('div', [_vm._t("filters"), _vm._t("slowness-alert", function () {
    return [_vm.showSlownessAlert ? _c('div', {
      staticClass: "row wrap"
    }, [_c('v-flex', {
      attrs: {
        "xs12": ""
      }
    }, [_c('Alert', {
      attrs: {
        "alert": _vm.computedQueryAlert
      }
    })], 1)], 1) : _vm._e()];
  }), _vm._t("run-report-button", function () {
    return [_c('div', {
      staticClass: "row wrap"
    }, [_c('ButtonRunReport', {
      attrs: {
        "disabled": _vm.disableRunReport
      },
      on: {
        "click": _vm.onClickOfRunReport
      }
    })], 1)];
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm._t("loader", function () {
    return [_vm.isRunningReport ? _c('c-progress') : _vm._e()];
  })], 2)], 2) : _vm._e(), _vm.showResults ? _c('div', {
    staticClass: "mt-2"
  }, [_vm._t("results-buttons", function () {
    return [_c('div', {
      staticClass: "layout mb-3 row wrap"
    }, [_c('ButtonBackToFilters', {
      staticClass: "mr-2",
      on: {
        "click": _vm.onClickOfBackToFilters
      }
    }), _c('ButtonShareLink', {
      staticClass: "mr-2",
      on: {
        "click": _vm.onClickOfShareLink
      }
    }), _vm.showExportButton ? _c('ButtonExport', {
      attrs: {
        "disabled": _vm.disableExportButton
      },
      on: {
        "click": _vm.onClickOfExportPdf
      }
    }) : _vm._e()], 1)];
  }), _vm._t("results")], 2) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };