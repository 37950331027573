var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DB_DEFAULT, LOC_DB_DEFAULT } from '~/nasa_ui/constants';
import { Maybe } from '~/nasa_ui/types';
let SingleLineDisplayLocation = class SingleLineDisplayLocation extends Mixins(BaseVue) {
    DB_DEFAULT = DB_DEFAULT;
    LOC_DB_DEFAULT = LOC_DB_DEFAULT;
    bin;
    building;
    contractNumber;
    organizationCode;
    room;
    stock;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SingleLineDisplayLocation.prototype, "bin", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], SingleLineDisplayLocation.prototype, "building", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], SingleLineDisplayLocation.prototype, "contractNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], SingleLineDisplayLocation.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], SingleLineDisplayLocation.prototype, "room", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object)
], SingleLineDisplayLocation.prototype, "stock", void 0);
SingleLineDisplayLocation = __decorate([
    Component
], SingleLineDisplayLocation);
export default SingleLineDisplayLocation;
