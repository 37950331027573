/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./InitializationStartStop.vue?vue&type=template&id=f8f1f88a&scoped=true"
import script from "./InitializationStartStop.ts?vue&type=script&lang=ts&external"
export * from "./InitializationStartStop.ts?vue&type=script&lang=ts&external"
import style0 from "./InitializationStartStop.vue?vue&type=style&index=0&id=f8f1f88a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f1f88a",
  null
  
)

export default component.exports