var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { HardwareListType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { BASE_DATE_TIME_COL, BASE_TEXT_COL, NUMBER_NO_UNDERSCORE_COL, OPEN_DATE_COL, SUB_TYPE_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { AlertType, HardwareListTypeDisplay, Maybe } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformEvents, transformHardwareListTemplateResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { transformDocumentLineItemResponses, transformDocumentResponses, transformDocumentResponseWithRefs } from '~/nasa_ui/utils/helpers/transforms/documents';
let TabHardwareListInformation = class TabHardwareListInformation extends Mixins(BaseTab) {
    hardwareListExists = false;
    hardwareListStatusReportData = null;
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    isSaving = false;
    isFetchingReport = false;
    NOT_APPLICABLE = NOT_APPLICABLE;
    selectedDocument = null;
    selectedEntity = null;
    selectedEvent = null;
    selectedHardwareListTemplate = null;
    showAssignTemplateModal = false;
    showAssignToEventModal = false;
    showDeleteConfirmationModal = false;
    showEditHardwareListTemplateSlotModal = false;
    showHardwareListItemEditModal = false;
    showHardwareListTemplateWithList = false;
    UserPermission = UserPermission;
    isViewingReportByPart = true;
    isViewingReportByLineItem = false;
    isViewingReportByDocument = false;
    formDataStatusReport = {
        includeAssemblyChildren: false
    };
    formData = {
        addendumNumber: null,
        addendumDate: null,
        authorizer: null,
        name: null,
        number: null,
        revision: null,
        subType: null
    };
    tableHeadersForStatusReportByDocument = [
        composeTableHeader(NUMBER_NO_UNDERSCORE_COL, {
            width: '200px',
            truncate: true
        }),
        composeTableHeader(SUB_TYPE_COL, {
            width: '150px'
        }),
        OPEN_DATE_COL,
        TITLE_COL
    ];
    tableHeadersForStatusReportByLineItem = [
        SUB_TYPE_COL,
        composeTableHeader(BASE_DATE_TIME_COL, {
            text: 'Opened',
            value: '_openedDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Part',
            value: '_partReference'
        })
    ];
    tableHeadersForStatusReportByPart = [
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Part',
            value: '_partReference'
        }),
        SUB_TYPE_COL,
        composeTableHeader(BASE_DATE_TIME_COL, {
            text: 'Opened',
            value: '_openedDate'
        })
    ];
    get hardwareListHasEvent() {
        return Boolean(this.selectedEvent);
    }
    get hardwareListHasTemplate() {
        return Boolean(this.selectedHardwareListTemplate);
    }
    get hardwareListTemplateId() {
        return this.selectedEntity?.hardwareListTemplateId ?? null;
    }
    get hasHardwareListTemplateAdminPermission() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN);
    }
    get isFormValid() {
        return Boolean(this.formData.number && this.formData.subType && !this.hardwareListExists);
    }
    get shouldDisableAddToHardwareList() {
        return (!this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN) ||
            !this.currentUserHasPermission(UserPermission.ASSEMBLY_TEMPLATE_ADMIN));
    }
    created() {
        this.$listen(`reload_${this.$route.params.id}`, () => this.fetchEntity(this.$route.params.id));
    }
    findOpenChangeSets() {
        if (!this.hardwareListStatusReportData) {
            return [];
        }
        return transformDocumentLineItemResponses(this.hardwareListStatusReportData.openLineItems.changeSets || []);
    }
    findOpenDocuments() {
        if (!this.hardwareListStatusReportData) {
            return [];
        }
        return transformDocumentResponses(this.hardwareListStatusReportData.openLineItems.documents || []);
    }
    onDeleted() {
        this.$router.push(`/hardware/lists/search`);
    }
    reset() {
        this.selectedEvent = null;
        this.selectedHardwareListTemplate = null;
        this.selectedDocument = null;
        this.showAssignTemplateModal = false;
        this.showAssignToEventModal = false;
        this.showDeleteConfirmationModal = false;
        this.showEditHardwareListTemplateSlotModal = false;
        this.showHardwareListItemEditModal = false;
        if (this.selectedEntity) {
            this.fetchEntity(this.selectedEntity.id);
            this.$message(`reload_${this.selectedEntity.id}`);
        }
    }
    async fetchEntity(hardwareListId) {
        if (!hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            // Fetch Hardware list
            const resp = await this.$http.get(`/hardware-lists/${hardwareListId}`);
            this.selectedEntity = resp.data;
            // If it has a template fetch that
            if (resp.data.hardwareListTemplateId) {
                const respHlt = await this.$http.get(`/hardware-list-templates/${resp.data.hardwareListTemplateId}`);
                this.selectedHardwareListTemplate = respHlt.data
                    ? transformHardwareListTemplateResponses([respHlt.data])[0]
                    : null;
            }
            else {
                this.selectedHardwareListTemplate = null;
            }
            // If it has a document... fetch that
            if (resp.data.documentId) {
                const respDoc = await this.$http.get(`/documents/${resp.data.documentId}`);
                this.selectedDocument = respDoc.data ? transformDocumentResponseWithRefs([respDoc.data])[0] : null;
            }
            else {
                this.selectedDocument = null;
            }
            // If it has an event... fetch that
            if (resp.data.eventId) {
                const respEvent = await this.$http.get(`/cosmic-events/${resp.data.eventId}`);
                this.selectedEvent = respEvent.data ? transformEvents([respEvent.data])[0] : null;
            }
            else {
                this.selectedEvent = null;
            }
            this.isFetching = false;
            // Fetch status report
            this.fetchHardwareListStatusReport();
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Hardware List Template'
            });
            this.isFetching = false;
        }
    }
    seedFormData() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData.addendumDate = this.selectedEntity.addendumDate;
        this.formData.addendumNumber = this.selectedEntity.addendumNumber;
        this.formData.authorizer = this.selectedEntity.authorizer;
        this.formData.name = this.selectedEntity.name;
        this.formData.number = this.selectedEntity.number;
        this.formData.revision = this.selectedEntity.revision;
        this.formData.subType = this.selectedEntity.subType;
    }
    async onClickOfSaveHardwareList() {
        if (!this.isFormValid) {
            return;
        }
        try {
            await this.updateHardwareListMutation(this.formData);
            this.$notification.add({
                text: 'Save successful.',
                type: AlertType.SUCCESS
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfClearAssignedTemplate() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                addendumDate: this.selectedEntity.addendumDate,
                addendumNumber: this.selectedEntity.addendumNumber,
                authorizer: this.selectedEntity.authorizer,
                closeDate: this.selectedEntity.closeDate,
                documentId: this.selectedEntity.documentId,
                eventId: this.selectedEntity.eventId,
                hardwareListTemplateId: null,
                isCritical: this.selectedEntity.isCritical,
                name: this.selectedEntity.name || '',
                number: this.selectedEntity.number,
                openDate: this.selectedEntity.openDate,
                revision: this.selectedEntity.revision,
                subType: this.selectedEntity.subType
            };
            const resp = await this.$http.put(`/hardware-lists/${this.id}`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.$message(`reload_${this.id}`);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfClearAssignedEvent() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const body = {
                addendumDate: this.selectedEntity.addendumDate,
                addendumNumber: this.selectedEntity.addendumNumber,
                authorizer: this.selectedEntity.authorizer,
                closeDate: this.selectedEntity.closeDate,
                documentId: this.selectedEntity.documentId,
                eventId: null,
                hardwareListTemplateId: this.selectedEntity.hardwareListTemplateId,
                isCritical: this.selectedEntity.isCritical,
                name: this.selectedEntity.name || '',
                number: this.selectedEntity.number,
                openDate: this.selectedEntity.openDate,
                revision: this.selectedEntity.revision,
                subType: this.selectedEntity.subType
            };
            const resp = await this.$http.put(`/hardware-lists/${this.id}`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.$message(`reload_${this.id}`);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchHardwareListStatusReport() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetchingReport = true;
            const statusReportResponse = await this.$http.get(`/hardware-lists/report/${this.id}/document-status`, {
                params: {
                    includeAssemblyChildren: this.formDataStatusReport.includeAssemblyChildren
                }
            });
            this.hardwareListStatusReportData = statusReportResponse.data;
            this.isFetchingReport = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    updateHardwareListMutation(patch) {
        if (!this.selectedEntity || !Object.keys(patch).length) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation UpdateHardwareListOnTabHardwareListInformation($id: UUID!, $patch: HardwareListPatch!) {
          updateHardwareListById(input: { id: $id, patch: $patch }) {
            clientMutationId
          }
        }
      `,
            variables: {
                id: this.selectedEntity.id,
                patch
            }
        });
    }
    async checkForExistence() {
        if (this.formData.number && this.formData.subType) {
            try {
                const reqObj = {
                    number: this.formData.number,
                    subType: this.formData.subType
                };
                const resp = await this.$http.post(`/hardware-lists/exists`, reqObj);
                this.hardwareListExists = resp.data.exists && this.formData.number !== this.selectedEntity?.number;
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
        else {
            this.hardwareListExists = false;
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], TabHardwareListInformation.prototype, "fetchEntity", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabHardwareListInformation.prototype, "seedFormData", null);
__decorate([
    Watch('formDataStatusReport.includeAssemblyChildren'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabHardwareListInformation.prototype, "fetchHardwareListStatusReport", null);
__decorate([
    DebounceAll(100),
    Watch('formData.number', { immediate: true }),
    Watch('formData.subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabHardwareListInformation.prototype, "checkForExistence", null);
TabHardwareListInformation = __decorate([
    Component
], TabHardwareListInformation);
export default TabHardwareListInformation;
