var render = function render() {
  var _vm$cosmicDocumentInf, _vm$nextAuthority, _vm$nextAuthority2, _vm$nextAuthority3, _vm$nextAuthority4, _vm$nextAuthority5;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-container', {
    attrs: {
      "fluid": "",
      "grid-list-lg": "",
      "pa-0": "",
      "mt-1": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-3": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.currentInitChangeSet) + " "), _vm.currentInitChangeSet ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.hasHistoricalApprovals ? _c('v-switch', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "label": "Show historical Approvals"
    },
    model: {
      value: _vm.showHistorical,
      callback: function ($$v) {
        _vm.showHistorical = $$v;
      },
      expression: "showHistorical"
    }
  }) : _vm._e(), _c('AppChangeSetStatusDisplay', {
    staticClass: "mb-2",
    attrs: {
      "show-historical": _vm.showHistorical,
      "id": _vm.currentInitChangeSet.id
    },
    on: {
      "hasHistoricalApprovals": _vm.onHasHistoricalApprovalsChange
    }
  }, [_c('span', {
    staticClass: "init_pipeline_label",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Init Pipeline")])]), _vm.computedLastCommentForInit ? _c('AppBlockquote', {
    attrs: {
      "title": "Initialization Comment"
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.computedLastCommentForInit
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), !_vm.pendingInitializationChangeSetId ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.isInitialized ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.SUCCESS
    }
  }, [_vm._v(" Initialization Status: "), _c('b', [_vm._v("Approved")]), _vm._v(" with revision control. ")]) : _vm._e(), _vm.isOpenForEdits ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._v(" This Document has "), _c('b', [_vm._v("NOT")]), _vm._v(" been initialized and can be directly saved without revision control. ")]) : _vm._e(), _vm.isRejected ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.ERROR
    }
  }, [_vm._v(" This Document has been rejected. If you would like to reopen the Document, click Reopen For Edits below.")]) : _vm._e(), _vm.shouldShowInitializationButton ? _c('ButtonChangeSet', {
    staticClass: "ml-0",
    attrs: {
      "disabled": _vm.disableStartDocumentInitializationButton,
      "isLoading": _vm.disableStartDocumentInitializationButton
    },
    on: {
      "click": _vm.onClickOfStartDocumentInitialization
    }
  }, [_vm._v(_vm._s(_vm.initializationButtonText) + " ")]) : _vm._e(), !_vm.isOpenForEdits ? _c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "disabled": _vm.isReopening,
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "loading": _vm.isReopening,
      "dark": ""
    },
    on: {
      "click": _vm.onClickOfReopen
    }
  }, [_vm._v(" Reopen for edits ")]) : _vm._e(), _vm.entity && _vm.showDocumentInitializationModal ? _c('ModalDocumentInitialization', {
    attrs: {
      "id": _vm.entityId,
      "read-only": _vm.isReadOnly,
      "type": _vm.entity.subType
    },
    on: {
      "changeset-created": _vm.emitRefetchEntity,
      "modal-close": function ($event) {
        _vm.showDocumentInitializationModal = false;
      }
    }
  }) : _vm._e(), _vm.showReviseDocumentModal ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600px"
    },
    on: {
      "affirmativeClicked": _vm.reopenDocumentForEdits,
      "negativeClicked": _vm.onCloseOfReviseDocument
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will clear Document Initialization approvals. Document will require reapproval. ")])]) : _vm._e()], 1) : _vm._e(), _vm.shouldShowGeneralInfoPanel ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "is-open": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": "fad fa-info-circle"
          }
        }), _c('span', {
          staticClass: "title"
        }, [_vm._v("General Information")])];
      },
      proxy: true
    }], null, false, 4001456155)
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": _vm.createdByDisplay,
      "title": "Creator"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.createdDateDisplay) + " ")])])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Current rev created"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.currentRevisionCreatedOnDateDisplay) + " ")])])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": _vm.initiatorDisplay,
      "title": "Initiator"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": "",
      "offset-md6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": (_vm$cosmicDocumentInf = _vm.cosmicDocumentInfo) === null || _vm$cosmicDocumentInf === void 0 ? void 0 : _vm$cosmicDocumentInf.computedRevisionNumber,
      "title": "Revision"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isInitialized && _vm.computedRevisionNumber && _vm.computedRevisionNumber > 1 ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalsTableHeaders,
      "items": _vm.approvalsTableItemsCurrentRevision,
      "disable-initial-sort": "",
      "icon": "fad fa-balance-scale",
      "title": "Current revision history"
    }
  })], 1) : _vm._e(), _vm.stepApprovalTableItems.length ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.StepApprovalTableHeaders,
      "items": _vm.stepApprovalTableItems,
      "disable-initial-sort": "",
      "icon": "fad fa-balance-scale"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', {
          staticClass: "title"
        }, [_vm._v("Steps approvals")])];
      },
      proxy: true
    }], null, false, 4040828757)
  })], 1) : _vm._e(), _vm.shouldShowApprovalFormPanel ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "is-open": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": "fad fa-thumbs-up"
          }
        }), _c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": "fad fa-thumbs-down"
          }
        }), _c('span', {
          staticClass: "title"
        }, [_vm._v("Approve / Reject")])];
      },
      proxy: true
    }], null, false, 770118456)
  }, [_vm.userIsInNextAuthority ? [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('p', [_vm._v(" You are a member of "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$nextAuthority = _vm.nextAuthority) === null || _vm$nextAuthority === void 0 ? void 0 : _vm$nextAuthority.groupCode) || '-'))]), _vm._v(" and can thusly provide "), _c('span', {
    staticClass: "success--text font-weight-bold"
  }, [_vm._v("approval")]), _vm._v(" or "), _c('span', {
    staticClass: "error--text font-weight-bold"
  }, [_vm._v("rejection")]), _vm._v(" for this Document Initialization. ")]), !_vm.nextAuthorityRequiresNoData ? _c('p', [_vm._v("Please fill out the information below to proceed.")]) : _c('p', [_vm._v("No further data is required to provide feedback.")])]), _c('AppFormWrapper', {
    ref: "approvalFormWrapper",
    attrs: {
      "no-border": ""
    }
  }, [!_vm.nextAuthorityRequiresNoData ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.nextAuthorityRequiresQualityControlStamp ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-4": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Quality cntrol stamp",
      "required": "",
      "c-value": _vm.approvalFormData.qualityControlStamp
    },
    on: {
      "c-input": function ($event) {
        _vm.approvalFormData.qualityControlStamp = $event.detail;
      }
    }
  })], 1) : _vm._e(), _vm.nextAuthorityRequiresComment ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppMarkdown', {
    attrs: {
      "read-only": false,
      "label": "Authority Comment",
      "required": ""
    },
    model: {
      value: _vm.approvalFormData.comment,
      callback: function ($$v) {
        _vm.$set(_vm.approvalFormData, "comment", $$v);
      },
      expression: "approvalFormData.comment"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('ButtonGeneric', {
    attrs: {
      "color": "success",
      "text-color": "white",
      "disabled": _vm.shouldDisableApproveButton,
      "icon": "fal fa-thumbs-up",
      "loading": _vm.isApproving
    },
    on: {
      "click": _vm.onClickOfApprove
    }
  }, [_c('span', [_vm._v("Approve change")])]), _c('ButtonGeneric', {
    attrs: {
      "text-color": "white",
      "color": "var(--v-secondary-base)",
      "disabled": _vm.shouldDisableRejectButton,
      "icon": "fal fa-thumbs-down",
      "loading": _vm.isRejecting
    },
    on: {
      "click": _vm.onClickOfReject
    }
  }, [_c('span', [_vm._v("Reject change")])])] : _vm._e(), !_vm.userIsInNextAuthority ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('p', [_vm._v(" You are not a member of the group "), _c('strong', [_vm._v(_vm._s(((_vm$nextAuthority2 = _vm.nextAuthority) === null || _vm$nextAuthority2 === void 0 || (_vm$nextAuthority2 = _vm$nextAuthority2.userGroup) === null || _vm$nextAuthority2 === void 0 ? void 0 : _vm$nextAuthority2.name) || '-'))]), _vm._v(" and therefore cannot "), _c('span', {
    staticClass: "success--text font-weight-bold"
  }, [_vm._v("approve")]), _vm._v(" or "), _c('span', {
    staticClass: "error--text font-weight-bold"
  }, [_vm._v("reject")]), _vm._v(" this Document Initialization. ")]), _c('p', [_vm._v(" You can click the button below to email the "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$nextAuthority3 = _vm.nextAuthority) === null || _vm$nextAuthority3 === void 0 || (_vm$nextAuthority3 = _vm$nextAuthority3.userGroup) === null || _vm$nextAuthority3 === void 0 ? void 0 : _vm$nextAuthority3.name) || '-'))]), _vm._v(" group. ")]), _c('ButtonGeneric', {
    staticClass: "emailButton ml-0 mt-4",
    attrs: {
      "href": _vm.emailLinkToSend,
      "tooltip": _vm.userNamesTitleForEmailButton,
      "text-color": "white",
      "color": "primary",
      "target": "_blank",
      "icon": "fad fa-envelope-open-text"
    }
  }, [_vm._v(" Email " + _vm._s(((_vm$nextAuthority4 = _vm.nextAuthority) === null || _vm$nextAuthority4 === void 0 || (_vm$nextAuthority4 = _vm$nextAuthority4.userGroup) === null || _vm$nextAuthority4 === void 0 ? void 0 : _vm$nextAuthority4.name) || '-') + " ("), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s((_vm$nextAuthority5 = _vm.nextAuthority) === null || _vm$nextAuthority5 === void 0 || (_vm$nextAuthority5 = _vm$nextAuthority5.userGroup) === null || _vm$nextAuthority5 === void 0 ? void 0 : _vm$nextAuthority5.usersToUserGroups.nodes.length))]), _vm._v(" members) ")])], 1) : _vm._e(), _vm.showRejectionModal ? _c('AreYouSureDialog', {
    attrs: {
      "disabled": !_vm.hasRejectionComment
    },
    on: {
      "negativeClicked": _vm.onCloseOfReject,
      "affirmativeClicked": _vm.onConfirmRejection
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_vm._v("This will reject the change set.")]), _c('template', {
    slot: "html"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Comment"
    },
    model: {
      value: _vm.rejectionComment,
      callback: function ($$v) {
        _vm.rejectionComment = $$v;
      },
      expression: "rejectionComment"
    }
  })], 1)], 2) : _vm._e()], 2)], 1) : _vm._e(), _vm.shouldShowApprovalsTablePanel ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalsTableHeaders,
      "items": _vm.approvalHistoryTableItems,
      "disable-initial-sort": "",
      "icon": "fad fa-balance-scale",
      "title": "Approval history"
    }
  })], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };