var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isInitializing || _vm.isReopening ? _c('v-flex', {
    staticClass: "d-flex justify-center"
  }, [_c('c-progress')], 1) : _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.isInitialized && !_vm.isClosed ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.SUCCESS
    }
  }, [_vm._t("message", function () {
    return [_c('p', [_vm._v(" This " + _vm._s(_vm.documentTypeDisplay) + " has been started. "), _vm.showButton ? _c('span', [_vm._v(" If you would like to stop the " + _vm._s(_vm.documentTypeDisplay) + " to make changes, click Stop below. ")]) : _vm._e()])];
  }, null, {
    showButton: _vm.showButton,
    isMemberOfAllInitializationAuthorities: _vm.isMemberOfAllInitializationAuthorities
  }), _vm.showButton ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "color": "error",
      "disabled": _vm.isSaving,
      "show-loader": _vm.isSaving,
      "dark": "",
      "icon": _vm.$icons.stop
    },
    on: {
      "click": function ($event) {
        _vm.showReopenDialog = true;
      }
    }
  }, [_vm._v(" STOP ")])], 1)], 1) : _vm._e()], 2) : !_vm.isInitialized && !_vm.isRejected ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._t("message", function () {
    return [_c('p', [_vm._v("This " + _vm._s(_vm.documentTypeDisplay) + " has "), _c('b', [_vm._v("NOT")]), _vm._v(" been started. Changes can be made.")])];
  }, null, {
    showButton: _vm.showButton,
    isMemberOfAllInitializationAuthorities: _vm.isMemberOfAllInitializationAuthorities
  }), _vm._t("message-append", null, null, {
    isMemberOfAllInitializationAuthorities: _vm.isMemberOfAllInitializationAuthorities
  }), _vm.showButton ? _c('ButtonGeneric', {
    attrs: {
      "color": "success",
      "text-color": "white",
      "show-loader": _vm.isSaving,
      "disabled": _vm.isSaving,
      "icon": _vm.$icons.play
    },
    on: {
      "click": _vm.onClickOfStartDocumentInitialization
    }
  }, [_vm._v(" START ")]) : _vm._e()], 2) : _vm.isRejected ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.ERROR
    }
  }, [_vm._v(" This Document has been rejected. Document must be reopened for edits. ")]) : _vm.isClosed ? _c('Alert', [_c('c-icon-fa', {
    attrs: {
      "slot": "icon",
      "icon": _vm.$icons.status_close
    },
    slot: "icon"
  }), _vm._v(" This " + _vm._s(_vm.documentTypeDisplay) + " has been closed. All items are complete. To make changes, click Reopen below. "), _vm.hasReopenPermissions ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "color": "var(--v-secondary-base)",
      "dark": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showReopenDialog = true;
      }
    }
  }, [_vm._v(" Reopen ")])], 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.showReopenDialog ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600px"
    },
    on: {
      "negativeClicked": function ($event) {
        _vm.showReopenDialog = false;
      },
      "affirmativeClicked": _vm.onClickOfConfirmReopen
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will stop the " + _vm._s(_vm.documentTypeDisplay) + "; making tasks editable and Users cannot make Approvals during this time. ")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };