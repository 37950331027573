var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cleanObject } from '~/nasa_ui/utils/helpers/cleanObject';
let WagonWheelPropsMixin = class WagonWheelPropsMixin extends Vue {
    activityId;
    // part of Item Master primary key (see drawingNumber and side)
    asBuiltNumber;
    assemblyTemplateId;
    changeSetId;
    changeSetAuthorityId;
    cosmicEventId;
    documentId;
    // Item Drawing primary key
    // part of Item Master primary key (see asBuiltNumber and side)
    drawingNumber;
    hardwareListId;
    itemInstanceId;
    organizationCode;
    physicalInventoryAuditId;
    physicalInventoryAuditItemId;
    riskId;
    riskMitigationId;
    // part of Item Master primary key (see asBuiltNumber and drawingNumber)
    side;
    transactionId;
    workOrderStepId;
    get hasAtLeastOnePrimaryKey() {
        return (this.activityId ||
            this.assemblyTemplateId ||
            this.changeSetId ||
            this.changeSetAuthorityId ||
            this.cosmicEventId ||
            this.documentId ||
            this.hardwareListId ||
            this.itemInstanceId ||
            this.organizationCode ||
            this.physicalInventoryAuditId ||
            this.physicalInventoryAuditItemId ||
            this.riskId ||
            this.riskMitigationId ||
            this.transactionId ||
            this.workOrderStepId ||
            (this.drawingNumber && !this.asBuiltNumber && !this.side) ||
            (this.drawingNumber && this.asBuiltNumber && this.side));
    }
    getRelationshipsObj() {
        const relationshipProps = {
            activityId: this.activityId || undefined,
            asBuiltNumber: this.asBuiltNumber || undefined,
            assemblyTemplateId: this.assemblyTemplateId || undefined,
            changeSetId: this.changeSetId || undefined,
            changeSetAuthorityId: this.changeSetAuthorityId || undefined,
            cosmicEventId: this.cosmicEventId || undefined,
            drawingNumber: this.drawingNumber || undefined,
            documentId: this.documentId || undefined,
            hardwareListId: this.hardwareListId || undefined,
            itemInstanceId: this.itemInstanceId || undefined,
            organizationCode: this.organizationCode || undefined,
            physicalInventoryAuditId: this.physicalInventoryAuditId || undefined,
            physicalInventoryAuditItemId: this.physicalInventoryAuditItemId || undefined,
            riskId: this.riskId || undefined,
            riskMitigationId: this.riskMitigationId || undefined,
            side: this.side || undefined,
            transactionId: this.transactionId || undefined,
            workOrderStepId: this.workOrderStepId || undefined
        };
        return cleanObject(relationshipProps);
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "assemblyTemplateId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "changeSetAuthorityId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "cosmicEventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "itemInstanceId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "physicalInventoryAuditId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "physicalInventoryAuditItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "riskMitigationId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "transactionId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "workOrderStepId", void 0);
WagonWheelPropsMixin = __decorate([
    Component
], WagonWheelPropsMixin);
export default WagonWheelPropsMixin;
