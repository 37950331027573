var _a, _b;
import { __decorate, __metadata } from "tslib";
import axios from 'axios';
import { parse } from 'date-fns';
import flatten from 'lodash/flatten';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Side, UserRole } from '~/db_types';
import { AssemblyTemplateType, InventoryClass, OrganizationType } from '~/db_types/swagger_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, END_DATE_COL, LOT_COL, NAME_COL, NAME_DISPLAY_COL, NUMBER_NO_UNDERSCORE_COL, SERIAL_COL, SIDE_COL, START_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { AlertType } from '~/nasa_ui/types';
import { clearContractCollectionCache, deleteAutoHardwareList, getAutoHardwareLists, getContracts, getPartReferences, postAutoHardwareList, postPartReference, putAutoHardwareList, putPartReference } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformAutoHardwareListResponses, transformPartReferenceResponses, transformVehicleResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { transformContractResponses } from '~/nasa_ui/utils/helpers/transforms/contracts';
let TabAdminCrud = class TabAdminCrud extends Mixins(BaseTab, UserRepoMixin, EmailNotificationRepoMixin) {
    autoHardwareLists = null;
    contracts = null;
    fileAccepted = false;
    fileAcceptedMega = false;
    fileAcceptedPartReference = false;
    fileAcceptedSarah = false;
    fileMega = null;
    filePartReferences = null;
    isSaving = false;
    OrganizationType = OrganizationType;
    partReferences = null;
    rawResult = null;
    rawResultMega = null;
    rawResultPartReferences = null;
    selectedAutoHardwareList = null;
    selectedContract = null;
    selectedPartReference = null;
    selectedVehicle = null;
    showModalAutoHardwareListCreate = false;
    showModalAutoHardwareListEdit = false;
    showModalContractCreate = false;
    showModalContractEdit = false;
    showModalPartReferenceEdit = false;
    showModalPartReferenceInfo = false;
    showModalUserImport = false;
    showModalVehicleCreate = false;
    showModalVehicleEdit = false;
    usersToCreate = [];
    vehicles = [];
    //#region formData
    formDataEmail = {
        data: {},
        messageTextTemplate: '',
        messageHtmlTemplate: '',
        recipientContactId: [],
        subjectTemplate: ''
    };
    formDataContract = {
        endDate: null,
        name: null,
        number: null,
        startDate: null
    };
    formDataOrganizationCreate = {
        code: null,
        subType: OrganizationType.PROCESSOR,
        name: null,
        attributes: {},
        isCageCode: false,
        isActive: true
    };
    formDataAutoHardwareList = {
        description: null,
        drawingNumberPrefix: null,
        isCritical: false,
        name: null
    };
    formDataMegaCreation = {
        attributes: {},
        autoHardwareListId: null,
        installedOn: Side.NONE,
        maxQuantity: 0,
        minQuantity: 0,
        name: null,
        parentId: null,
        sequence: 0,
        subType: AssemblyTemplateType.MEGA
    };
    formDataSarah = {
        file: null,
        description: null
    };
    formDataVehicle = {
        name: null
    };
    //#endregion
    //#region table headers
    tableHeadersAutoHardwareList = [
        composeTableHeader(NAME_COL, {
            monospacedFont: true,
            align: 'right',
            width: '150px'
        }),
        DESCRIPTION_COL,
        composeTableHeader(BASE_ICON_COL, {
            text: 'Critical?',
            value: '_isCritical'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Drawing number<br/>prefixes',
            value: '_drawingNumberPrefixes'
        })
    ];
    tableHeadersContracts = [NUMBER_NO_UNDERSCORE_COL, NAME_DISPLAY_COL, START_DATE_COL, END_DATE_COL];
    tableHeadersPartReference = [
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: 'drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            noWrap: true,
            value: '_asBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_description'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_side'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_lotNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_itemClasses'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_contractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_location'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_birthDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration<br/>number',
            value: '_calibrationNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Calibration',
            value: '_lastCalibrationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage<br/>expiry',
            value: '_usageLifeExpirationDate'
        })
    ];
    tableHeaderUsersImport = [
        {
            text: 'AUID',
            value: 'auid'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'Last Name',
            value: 'lastName'
        },
        {
            text: 'First Name',
            value: 'firstName'
        }
    ];
    tableHeadersVehicle = [NAME_DISPLAY_COL];
    //#endregion
    get allParsedUsers() {
        return (this.rawResult || []).map(([auid, email, lastName, firstName], index) => {
            return {
                auid: auid.toUpperCase(),
                email: [email],
                firstName: firstName?.trim(),
                index,
                lastName,
                middleName: null,
                organizationCodes: [this.currentUserActiveSupportContext],
                role: UserRole.USER
            };
        });
    }
    get availableContexts() {
        return this.currentUserSupportContexts;
    }
    get disableEmailTestButton() {
        return (!this.formDataEmail.messageHtmlTemplate ||
            !this.formDataEmail.subjectTemplate ||
            !this.formDataEmail.recipientContactId.length);
    }
    get hasAvailableContexts() {
        return Boolean(this.availableContexts?.length);
    }
    get tableItemsAutoHardwareList() {
        return this.autoHardwareLists ? transformAutoHardwareListResponses(this.autoHardwareLists) : [];
    }
    get tableItemsContracts() {
        return this.contracts ? transformContractResponses(this.contracts) : [];
    }
    get tableItemsPartReference() {
        return this.partReferences ? transformPartReferenceResponses(this.partReferences) : [];
    }
    get tableItemsVehicle() {
        return this.vehicles ? transformVehicleResponses(this.vehicles) : [];
    }
    created() {
        this.formDataEmail.recipientContactId = [this.currentUser?.contactId];
        this.fetchAutoHardwareLists();
        this.fetchContracts();
        this.fetchPartReferences();
        this.fetchVehicles();
    }
    async createUsers() {
        try {
            this.$notification.add({
                text: `Checking users for duplicates....`,
                type: AlertType.INFO
            });
            const existingUsers = await this.checkUsersForExistence(this.usersToCreate);
            const existingUserAuids = (existingUsers?.users?.nodes || []).map((user) => user?.auid);
            const usersToCreate = this.usersToCreate.filter((user) => {
                // see if any emails match any of the existing emails
                const matchBasedOnEmail = user.email?.find((email) => {
                    return (existingUsers?.users?.nodes || []).find((user) => user?.email?.find((e) => e === email));
                });
                return (user.auid && !existingUserAuids.includes(user.auid)) || !matchBasedOnEmail;
            });
            if (usersToCreate.length > 0) {
                const resp = await Promise.all(usersToCreate.map((user) => {
                    return this.httpPost('/users', user);
                }));
                if (resp) {
                    this.$notification.add({
                        text: `${resp.length} users created.`,
                        type: AlertType.SUCCESS
                    });
                }
                this.reset();
            }
            else {
                setTimeout(() => {
                    this.$notification.add({
                        text: `All selected users currently exist.`,
                        type: AlertType.WARNING
                    });
                }, 2000);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchAutoHardwareLists() {
        try {
            const params = {
                take: -1
            };
            const resp = await getAutoHardwareLists(params);
            this.autoHardwareLists = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchContracts() {
        try {
            const params = {
                take: -1
            };
            const resp = await getContracts(params);
            this.contracts = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchPartReferences() {
        try {
            const resp = await getPartReferences({
                take: -1
            });
            this.partReferences = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchVehicles() {
        try {
            const params = {
                take: -1
            };
            const resp = await this.$http.get(`/vehicles`, {
                params
            });
            this.vehicles = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreatProcessorOrganization() {
        if (!this.formDataOrganizationCreate.code || !this.formDataOrganizationCreate.name) {
            return;
        }
        try {
            const body = {
                attributes: {},
                code: this.formDataOrganizationCreate.code,
                isActive: true,
                name: this.formDataOrganizationCreate.name
            };
            const resp = await this.$http.post(`/organizations/support-contexts`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Support ctx created.'
            });
            this.formDataOrganizationCreate.code = null;
            this.formDataOrganizationCreate.name = null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfImportSarah() {
        try {
            if (!this.formDataSarah.file || !this.formDataSarah.description) {
                this.$notification.add({
                    type: AlertType.ERROR,
                    text: 'Please select a file and provide a description.'
                });
                return;
            }
            const formData = new FormData();
            formData.append('file', this.formDataSarah.file);
            formData.append('description', this.formDataSarah.description);
            // Don’t set content-type when using multipart/form-data
            // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
            const headers = {
                'x-organization': this.currentUserActiveSupportContext
            };
            // use fresh axios instance because we're using multipart/form-data
            // instead of application/json
            await axios.post(`/api2/import/part-references`, formData, { headers });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Import complete.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    launchCreateAutoHardwareListModal() {
        this.showModalAutoHardwareListCreate = true;
    }
    launchCreateVehicleModal() {
        this.showModalVehicleCreate = true;
    }
    launchEditAutoHardwareList(item) {
        this.showModalAutoHardwareListEdit = true;
        this.selectedAutoHardwareList = item;
        this.formDataAutoHardwareList.description = this.selectedAutoHardwareList.description;
        this.formDataAutoHardwareList.drawingNumberPrefix = this.selectedAutoHardwareList.drawingNumberPrefix;
        this.formDataAutoHardwareList.isCritical = this.selectedAutoHardwareList.isCritical;
        this.formDataAutoHardwareList.name = this.selectedAutoHardwareList.name;
    }
    launchEditVehicleModal(item) {
        this.showModalVehicleEdit = true;
        this.selectedVehicle = item;
        this.formDataVehicle.name = this.selectedVehicle.name;
    }
    async onClickOfCreateNewAutoHardwareList() {
        try {
            this.isSaving = true;
            const reqBody = {
                description: this.formDataAutoHardwareList.description,
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                isCritical: this.formDataAutoHardwareList.isCritical ?? false,
                name: this.formDataAutoHardwareList.name
            };
            const resp = await postAutoHardwareList(reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreateNewVehicle() {
        try {
            this.isSaving = true;
            const reqBody = {
                name: this.formDataVehicle.name
            };
            const resp = await this.$http.post(`/vehicles`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.resetVehicles();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfImportMega() {
        this.isSaving = true;
        const createdAssemblyTemplates = [];
        let sequence = 0;
        const rootMegaBody = {
            attributes: {
                isArchived: false
            },
            autoHardwareListId: null,
            installedOn: Side.NONE,
            maxQuantity: 1,
            minQuantity: 1,
            name: this.formDataMegaCreation.name,
            parentId: null,
            sequence: 0,
            subType: AssemblyTemplateType.MEGA
        };
        const rootMega = await this.$http.post(`/assembly-templates`, rootMegaBody);
        if (!rootMega.data) {
            return;
        }
        createdAssemblyTemplates.push(rootMega.data);
        for (const assy of this.rawResultMega) {
            const [itemNumber, itemName, partNumbers, parentName] = assy;
            const cleanItemNumber = itemNumber.replaceAll('"', '').replaceAll('\r', '');
            const cleanItemName = itemName.replaceAll('"', '').replaceAll('\r', '');
            const cleanPartNumbers = partNumbers
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split('/')
                .filter((pn) => pn && pn.trim().length);
            const cleanParentItemNumber = parentName.replaceAll('"', '').replaceAll('\r', '');
            // Check for matching AutoHardwareList
            const ahlExistence = await getAutoHardwareLists({
                skip: 0,
                take: 1,
                query: cleanItemNumber
            });
            let ahl = null;
            if (ahlExistence.data.results?.length === 0) {
                const createdAhl = await postAutoHardwareList({
                    description: cleanItemName,
                    drawingNumberPrefix: cleanPartNumbers.length ? cleanPartNumbers : [],
                    isCritical: false,
                    name: cleanItemNumber
                });
                ahl = createdAhl.data;
            }
            else {
                ahl = ahlExistence.data.results[0];
            }
            // Create new AssyTemplate
            const assemblyTemplateReqBody = {
                attributes: {},
                minQuantity: 1,
                maxQuantity: cleanItemName.includes('qty') ? 2 : 1,
                sequence,
                subType: AssemblyTemplateType.MEGA,
                autoHardwareListId: ahl.id,
                name: `${cleanItemNumber}`,
                installedOn: Side.NONE,
                parentId: cleanParentItemNumber === '-'
                    ? rootMega.data.id
                    : createdAssemblyTemplates.find((temp) => temp.name === cleanParentItemNumber)?.id ?? null
            };
            const newAssemblyTemplate = await this.$http.post(`/assembly-templates`, assemblyTemplateReqBody);
            if (assemblyTemplateReqBody.parentId === null) {
                sequence = 0;
            }
            else {
                sequence += 10;
            }
            createdAssemblyTemplates.push(newAssemblyTemplate.data);
        }
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: `${createdAssemblyTemplates.length} assembly templates created.`
        });
        this.isSaving = false;
    }
    async onClickOfImportPartReferences() {
        this.isSaving = true;
        const toBeCreated = [];
        const dateStringFormat = 'dd-MMM-yy';
        const ShitClassToItemClass = new Map([
            ['I', [InventoryClass.CLASS_I]],
            ['II', [InventoryClass.CLASS_II]],
            ['III', [InventoryClass.CLASS_III]]
        ]);
        const allPartReferences = await getPartReferences({
            take: -1
        });
        for (const partRef of this.rawResultPartReferences) {
            const [itemNumber, serialNumber, description, itemClass, location, birthDate, usageExpirationDate] = partRef;
            const cleanItemNumber = itemNumber.replaceAll('"', '').replaceAll('\r', '');
            const cleanSerialNumber = serialNumber
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .replaceAll('*', '')
                .replaceAll("'", '');
            const cleanDescription = description.replaceAll('"', '').replaceAll('\r', '');
            const cleanClass = itemClass.replaceAll('"', '').replaceAll('\r', '');
            const cleanLocation = location.replaceAll('"', '').replaceAll('\r', '');
            const cleanBirth = birthDate.replaceAll('"', '').replaceAll('\r', '');
            const cleanUsage = usageExpirationDate.replaceAll('"', '').replaceAll('\r', '');
            const parsedBirthDate = cleanBirth ? this.$dateDisplay(parse(cleanBirth, dateStringFormat, new Date())) : null;
            const parsedUsageDate = cleanUsage ? this.$dateDisplay(parse(cleanUsage, dateStringFormat, new Date())) : null;
            // Check for existence
            const found = allPartReferences.data.results?.find((p) => p.drawingNumber === cleanItemNumber && p.serialNumber === cleanSerialNumber && p.location === cleanLocation);
            const partRefRequest = {
                attributes: {},
                isTool: false,
                asBuiltNumber: null,
                birthDate: parsedBirthDate,
                calibrationNumber: null,
                contractEndingItemNumber: null,
                drawingDescription: cleanDescription,
                drawingNumber: cleanItemNumber,
                itemClass: cleanClass ? ShitClassToItemClass.get(cleanClass) ?? null : null,
                lastCalibrationDate: null,
                location: cleanLocation,
                lotNumber: null,
                notes: null,
                serialNumber: cleanSerialNumber,
                shelfLifeExpirationDate: null,
                side: Side.NONE,
                size: null,
                usageLifeExpirationDate: parsedUsageDate
            };
            if (found) {
                console.log('Found...');
                console.log('Updating...');
                await putPartReference(found.id, Object.assign({}, found, partRefRequest));
                console.log('Complete!');
            }
            else {
                console.log('Creating...');
                await postPartReference(partRefRequest);
                console.log('Complete!');
            }
        }
    }
    async onClickOfSaveAutoHardwareList() {
        if (!this.selectedAutoHardwareList) {
            return;
        }
        try {
            this.isSaving = true;
            const reqBody = {
                description: this.formDataAutoHardwareList.description,
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                isCritical: this.formDataAutoHardwareList.isCritical ?? false,
                name: this.formDataAutoHardwareList.name
            };
            const resp = await putAutoHardwareList(this.selectedAutoHardwareList.id, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfSaveVehicleList() {
        if (!this.selectedVehicle) {
            return;
        }
        try {
            this.isSaving = true;
            const reqBody = {
                name: this.formDataVehicle.name
            };
            const resp = await this.$http.put(`/vehicles/${this.selectedVehicle.id}`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.isSaving = false;
            this.resetVehicles();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfDeleteAutoHardwareList(item) {
        // delete it
        try {
            const resp = await deleteAutoHardwareList(item.id);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.resetAutoHardwareList();
    }
    async onClickOfDeleteVehicle(item) {
        // delete it
        try {
            const resp = await this.$http.delete(`/vehicles/${item.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.resetVehicles();
    }
    uiClickOfContractCreate() {
        this.showModalContractCreate = true;
    }
    uiClickOfContractEdit(item) {
        this.showModalContractEdit = true;
        this.selectedContract = item;
        this.formDataContract.endDate = item.endDate;
        this.formDataContract.name = item.name;
        this.formDataContract.number = item.number;
        this.formDataContract.startDate = item.startDate;
    }
    async onClickOfContractDelete(item) {
        try {
            const resp = await this.$http.delete(`/contracts/${encodeURIComponent(item.number)}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Success.'
            });
            this.resetContracts();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreateNewContract() {
        if (!this.formDataContract.number || !this.formDataContract.startDate) {
            return;
        }
        try {
            const body = {
                endDate: this.formDataContract.endDate,
                name: this.formDataContract.name,
                number: this.formDataContract.number,
                startDate: this.formDataContract.startDate
            };
            const resp = await this.$http.post(`/contracts`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.resetContracts();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfEditContract() {
        if (!this.formDataContract.number || !this.formDataContract.startDate || !this.selectedContract) {
            return;
        }
        try {
            const body = {
                endDate: this.formDataContract.endDate,
                name: this.formDataContract.name,
                number: this.formDataContract.number,
                startDate: this.formDataContract.startDate
            };
            const resp = await this.$http.put(`/contracts/${encodeURIComponent(this.selectedContract.number)}`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.resetContracts();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    uiClickOfInfoPartReference(item) {
        this.selectedPartReference = item;
        this.showModalPartReferenceInfo = true;
    }
    uiClickOfEditPartReference(item) {
        this.selectedPartReference = item;
        this.showModalPartReferenceEdit = true;
    }
    uiClickOfDeletePartReference(item) {
        this.selectedPartReference = item;
    }
    resetAutoHardwareList() {
        this.showModalAutoHardwareListCreate = false;
        this.showModalAutoHardwareListEdit = false;
        this.selectedAutoHardwareList = null;
        this.formDataAutoHardwareList = {
            description: null,
            drawingNumberPrefix: null,
            isCritical: false,
            name: null
        };
        this.fetchAutoHardwareLists();
    }
    resetPartReferences() {
        this.showModalPartReferenceEdit = false;
        this.showModalPartReferenceInfo = false;
        this.selectedPartReference = null;
    }
    resetContracts() {
        clearContractCollectionCache();
        this.showModalContractCreate = false;
        this.showModalContractEdit = false;
        this.selectedContract = null;
        this.formDataContract = {
            endDate: null,
            name: null,
            number: null,
            startDate: null
        };
        this.fetchContracts();
    }
    resetVehicles() {
        this.showModalVehicleCreate = false;
        this.showModalVehicleEdit = false;
        this.selectedVehicle = null;
        this.formDataVehicle = {
            name: null
        };
        this.fetchVehicles();
    }
    onMegaImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResultMega = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAcceptedMega = true;
        };
    }
    onPartRefImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResultPartReferences = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAcceptedPartReference = true;
        };
    }
    // Called from AppFileUpload @input
    onUserImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResult = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAccepted = true;
        };
    }
    reset() {
        this.formDataEmail.messageHtmlTemplate = '';
        this.formDataEmail.recipientContactId = [];
        this.formDataEmail.subjectTemplate = '';
        this.fileAccepted = false;
        this.isSaving = false;
        this.showModalUserImport = false;
        this.usersToCreate = [];
        this.showModalPartReferenceEdit = false;
        this.showModalPartReferenceInfo = false;
        this.selectedPartReference = null;
        this.showModalContractCreate = false;
        this.showModalContractEdit = false;
        this.selectedContract = null;
    }
    checkUsersForExistence(users) {
        return this.getUsersByAuidOrEmail({
            userAuids: users.map((user) => user.auid),
            emails: flatten(users.map((user) => user.email))
        });
    }
    async sendEmail() {
        try {
            const resp = await this.createEmailNotification({
                emailDetails: this.formDataEmail
            });
            if (resp?.createNotification?.notification) {
                this.$notification.add({
                    text: `Email sent to human(s).`,
                    type: AlertType.SUCCESS
                });
                this.reset();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onShowImportUserSpreadsheetModalChange() {
        if (!this.showModalUserImport) {
            this.reset();
        }
    }
};
__decorate([
    Watch('fileMega'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof File !== "undefined" && File) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TabAdminCrud.prototype, "onMegaImportFileSelected", null);
__decorate([
    Watch('filePartReferences'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof File !== "undefined" && File) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], TabAdminCrud.prototype, "onPartRefImportFileSelected", null);
__decorate([
    Watch('showModalUserImport'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabAdminCrud.prototype, "onShowImportUserSpreadsheetModalChange", null);
TabAdminCrud = __decorate([
    Component
], TabAdminCrud);
export default TabAdminCrud;
