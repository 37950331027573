import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, InventoryClassDisplay, SideDisplay } from '~/nasa_ui/types';
import { postPartReference } from '~/nasa_ui/utils';
let ModalPartReferenceCreate = class ModalPartReferenceCreate extends Mixins(BaseModal) {
    InventoryClassDisplay = InventoryClassDisplay;
    InventoryClass = InventoryClass;
    SideDisplay = SideDisplay;
    Side = Side;
    initialValues;
    get selectOptionsForDrawingNumber() {
        if (!this.initialValues.drawingNumbers) {
            return;
        }
        return this.initialValues.drawingNumbers.map((drawingNumber) => {
            return {
                displayText: drawingNumber,
                value: drawingNumber
            };
        });
    }
    formDataPartReference = {
        asBuiltNumber: null,
        attributes: {},
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: null,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: null,
        size: null,
        usageLifeExpirationDate: null
    };
    async onClickOfCreatePartReference() {
        try {
            this.isSaving = true;
            const body = {
                asBuiltNumber: this.formDataPartReference.asBuiltNumber,
                attributes: this.formDataPartReference.attributes,
                birthDate: this.formDataPartReference.birthDate,
                calibrationNumber: this.formDataPartReference.calibrationNumber,
                contractEndingItemNumber: this.formDataPartReference.contractEndingItemNumber,
                drawingDescription: this.formDataPartReference.drawingDescription,
                drawingNumber: this.formDataPartReference.drawingNumber,
                isTool: this.formDataPartReference.isTool ?? false,
                itemClass: this.formDataPartReference.itemClass,
                lastCalibrationDate: this.formDataPartReference.lastCalibrationDate,
                location: this.formDataPartReference.location,
                lotNumber: this.formDataPartReference.lotNumber,
                serialNumber: this.formDataPartReference.serialNumber,
                shelfLifeExpirationDate: this.formDataPartReference.shelfLifeExpirationDate,
                side: this.formDataPartReference.side ?? Side.NONE,
                size: this.formDataPartReference.size,
                usageLifeExpirationDate: this.formDataPartReference.usageLifeExpirationDate
            };
            const resp = await postPartReference(body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Part created.'
            });
            this.isSaving = false;
            this.closeDialog();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onInitialValuesChanged() {
        if (!this.initialValues) {
            return;
        }
        this.formDataPartReference.drawingNumber =
            this.initialValues.drawingNumbers?.length === 1 ? this.initialValues.drawingNumbers[0] : '';
        this.formDataPartReference.drawingDescription = this.initialValues.description || '';
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], ModalPartReferenceCreate.prototype, "initialValues", void 0);
__decorate([
    Watch('initialValues', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalPartReferenceCreate.prototype, "onInitialValuesChanged", null);
ModalPartReferenceCreate = __decorate([
    Component
], ModalPartReferenceCreate);
export default ModalPartReferenceCreate;
