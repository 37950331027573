var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      [_vm.color]: _vm.color,
      hasNoActions: !_vm.hasAnyActions,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.VEHICLE],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v(_vm._s(_vm.name))])]), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down white--text"
  }, _vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "text-capitalize mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), 0), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions"
  }, [_vm.inlineClear && !_vm.readOnly ? _c('c-icon-fa', {
    staticClass: "inline_clear_icon",
    attrs: {
      "icon-color": "white",
      "tooltip": "Clear selection",
      "icon": _vm.inlineClearIcon
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('c-progress') : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };