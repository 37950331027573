var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('c-list-group', {
    staticClass: "quick_display_location",
    attrs: {
      "gap": _vm.gap,
      "width": _vm.width
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": _vm.LABEL_WIDTH,
      "value-width": _vm.computedValueWidth
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Org")]), _c('div', {
    staticStyle: {
      "font-family": "Roboto"
    },
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.organizationCode) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": _vm.LABEL_WIDTH,
      "value-width": _vm.computedValueWidth
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Building")]), _vm.canDisplayLocationElement(_vm.transformedSelectedEntity.building) ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._building) + " ")]) : _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": _vm.LABEL_WIDTH,
      "value-width": _vm.computedValueWidth
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Room")]), _vm.canDisplayLocationElement(_vm.transformedSelectedEntity.room) ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._room) + " ")]) : _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": _vm.LABEL_WIDTH,
      "value-width": _vm.computedValueWidth
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Stock")]), _vm.canDisplayLocationElement(_vm.transformedSelectedEntity.stock) ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._stock) + " ")]) : _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": _vm.LABEL_WIDTH,
      "value-width": _vm.computedValueWidth
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Bin")]), _vm.canDisplayLocationElement(_vm.transformedSelectedEntity.bin) ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._bin) + " ")]) : _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")])])], 1) : !_vm.id ? _c('div', {
    style: `width: ${_vm.width}; font-style: italic`
  }, [_vm._v("No Location data found")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };