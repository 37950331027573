var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": "",
      "group": ""
    }
  }, [!_vm.transformedSelectedEntity ? _c('div', {
    key: "skeleton",
    staticClass: "quick_display_part_reference skeleton",
    style: `width: ${_vm.width}`
  }, [_c('div', {
    staticClass: "skeleton__item"
  }), _c('div', {
    staticClass: "skeleton__item"
  }), _c('div', {
    staticClass: "skeleton__item"
  }), _c('div', {
    staticClass: "skeleton__item"
  })]) : _vm._e(), _vm.transformedSelectedEntity ? _c('c-list-group', {
    key: "quick_display_part_reference",
    staticClass: "quick_display_part_reference",
    attrs: {
      "gap": _vm.gap,
      "width": _vm.width
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": `calc(${_vm.width} * .35)`,
      "value-width": `calc(${_vm.width} * .65)`
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Drawing number")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.drawingNumber || _vm.DEFAULT_DASH) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": `calc(${_vm.width} * .35)`,
      "value-width": `calc(${_vm.width} * .65)`
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Serial number")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._serialNumber) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": `calc(${_vm.width} * .35)`,
      "value-width": `calc(${_vm.width} * .65)`
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Description")]), _vm.transformedSelectedEntity.drawingDescription ? _c('div', {
    staticClass: "text-truncate",
    staticStyle: {
      "font-family": "Roboto"
    },
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._description) + " ")]) : _c('div', {
    staticClass: "monospace_font",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.DEFAULT_DASH) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": `calc(${_vm.width} * .35)`,
      "value-width": `calc(${_vm.width} * .65)`
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Location")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._location) + " ")])])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };