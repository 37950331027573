var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tab_hardware_list_information"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('c-progress') : _vm._e()], 1), _vm.selectedEntity ? _c('c-card', {
    attrs: {
      "border-color": "var(--v-item_instances-base)"
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.formData.number && _vm.formData.subType && _vm.hardwareListExists ? _c('div', {
    staticClass: "col-full"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" Hardware list found matching "), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formData.number))])]), _vm._v(" with the type "), _c('strong', [_vm._v(_vm._s(_vm.HardwareListTypeDisplay.get(_vm.formData.subType)))])])], 1) : _vm._e()]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "List name"
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.HardwareListTypeDisplay,
      "enum": _vm.HardwareListType,
      "label": "List type",
      "required": ""
    },
    model: {
      value: _vm.formData.subType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "subType", $$v);
      },
      expression: "formData.subType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "List number",
      "required": ""
    },
    model: {
      value: _vm.formData.number,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "number", $$v);
      },
      expression: "formData.number"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Revision"
    },
    model: {
      value: _vm.formData.revision,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "revision", $$v);
      },
      expression: "formData.revision"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "pr-2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Addendum number"
    },
    model: {
      value: _vm.formData.addendumNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "addendumNumber", $$v);
      },
      expression: "formData.addendumNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Addendum"
    },
    model: {
      value: _vm.formData.addendumDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "addendumDate", $$v);
      },
      expression: "formData.addendumDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs5": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Authorizer"
    },
    model: {
      value: _vm.formData.authorizer,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "authorizer", $$v);
      },
      expression: "formData.authorizer"
    }
  })], 1)], 1), !_vm.isReadOnly ? _c('div', {
    attrs: {
      "slot": "actions"
    },
    slot: "actions"
  }, [_c('ButtonSave', {
    attrs: {
      "disabled": !_vm.isFormValid,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSaveHardwareList
    }
  })], 1) : _vm._e(), !_vm.isReadOnly ? _c('div', {
    attrs: {
      "slot": "right-aligned-actions"
    },
    slot: "right-aligned-actions"
  }, [_c('DeleteHardwareList', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "deletionComplete": _vm.onDeleted
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.selectedEntity ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-item_instances-base)"
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('AppHardwareListParts', {
    attrs: {
      "read-only": _vm.isReadOnly,
      "hardware-list-id": _vm.id
    }
  })], 1)])]) : _vm._e(), _vm.selectedEntity ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-events-base)"
    }
  }, [!_vm.isReadOnly ? _c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.EntityType.EVENT,
      "icon": _vm.$icons[_vm.EntityType.EVENT],
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showAssignToEventModal = true;
      }
    }
  }, [_vm.hardwareListHasEvent ? [_vm._v("Reassign to an Event")] : [_vm._v("Assign an Event")]], 2) : _vm._e(), !_vm.isReadOnly && _vm.hardwareListHasEvent ? _c('ButtonClearSelection', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.onClickOfClearAssignedEvent
    }
  }) : _vm._e(), _vm.selectedEvent ? _c('MiniEvent', {
    attrs: {
      "entity": _vm.selectedEvent,
      "title": "Currently assigned to Event"
    }
  }) : _vm._e(), !_vm.hardwareListHasEvent ? _c('AppPageDescription', [_vm._v(" No Event is currently assigned to this Hardware List. ")]) : _vm._e()], 1) : _vm._e(), _vm.selectedEntity ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-hardware_lists-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  }), _vm._v(" Status report ")], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetchingReport || !_vm.hardwareListStatusReportData ? _c('c-progress') : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [!_vm.isFetchingReport && _vm.hardwareListStatusReportData ? _c('div', {
    staticClass: "col-full"
  }, [_vm.findOpenChangeSets().length > 0 ? _c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.selectedEntity.number))]), _vm._v(" has "), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.findOpenChangeSets().length)))]), _vm._v(" OPEN line items across "), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.findOpenDocuments().length)))]), _vm._v(" Documents. ")]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "col-full"
  }, [_c('ButtonGeneric', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "icon": _vm.$icons.part_reference,
      "color": _vm.isViewingReportByPart ? 'var(--v-item_instances-base)' : 'var(--v-primary-base)'
    },
    on: {
      "click": function ($event) {
        _vm.isViewingReportByPart = !_vm.isViewingReportByPart;
      }
    }
  }, [_vm._v(" By part ")]), _c('ButtonGeneric', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET],
      "color": _vm.isViewingReportByLineItem ? 'var(--v-change_sets-base)' : 'var(--v-primary-base)'
    },
    on: {
      "click": function ($event) {
        _vm.isViewingReportByLineItem = !_vm.isViewingReportByLineItem;
      }
    }
  }, [_vm._v(" By line item ")]), _c('ButtonGeneric', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "color": _vm.isViewingReportByDocument ? 'var(--v-documents-base)' : 'var(--v-primary-base)'
    },
    on: {
      "click": function ($event) {
        _vm.isViewingReportByDocument = !_vm.isViewingReportByDocument;
      }
    }
  }, [_vm._v(" By Document ")]), _c('ButtonGeneric', {
    attrs: {
      "small": "",
      "icon": _vm.$icons.reload
    },
    on: {
      "click": _vm.fetchHardwareListStatusReport
    }
  }, [_vm._v(" Refetch data ")])], 1), _c('div', {
    staticClass: "col-full my-1"
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Include assembly children?"
    },
    model: {
      value: _vm.formDataStatusReport.includeAssemblyChildren,
      callback: function ($$v) {
        _vm.$set(_vm.formDataStatusReport, "includeAssemblyChildren", $$v);
      },
      expression: "formDataStatusReport.includeAssemblyChildren"
    }
  })], 1), _vm.isViewingReportByPart && !_vm.isFetchingReport && _vm.hardwareListStatusReportData ? _c('div', {
    staticClass: "col-half"
  }, [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "headers": _vm.tableHeadersForStatusReportByPart,
      "icon": _vm.$icons.part_reference,
      "items": _vm.findOpenChangeSets(),
      "rows-per-page-items": [10, 20, 50],
      "dense-rows": "",
      "title": "By part"
    },
    scopedSlots: _vm._u([{
      key: "_partReference",
      fn: function ({
        item
      }) {
        return [_c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
          ...item.partReference
        }, false))];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [item.partReference.itemInstanceId ? _c('ButtonMiniActionManage', {
          attrs: {
            "to": `/hardware/item_instances/manage/${item.partReference.itemInstanceId}#change_sets`
          }
        }) : _vm._e()];
      }
    }], null, false, 3861830923)
  })], 1) : _vm._e(), _vm.isViewingReportByLineItem && !_vm.isFetchingReport && _vm.hardwareListStatusReportData ? _c('div', {
    staticClass: "col-half"
  }, [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.tableHeadersForStatusReportByLineItem,
      "items": _vm.findOpenChangeSets(),
      "rows-per-page-items": [10, 20, 50],
      "dense-rows": "",
      "title": "By line item"
    },
    scopedSlots: _vm._u([{
      key: "_partReference",
      fn: function ({
        item
      }) {
        return [_c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
          ...item.partReference
        }, false))];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": `/change_sets/manage/${item.id}`
          }
        })];
      }
    }], null, false, 1157879488)
  })], 1) : _vm._e(), _vm.isViewingReportByDocument && !_vm.isFetchingReport && _vm.hardwareListStatusReportData ? _c('div', {
    staticClass: "col-half",
    class: {
      'mt-2': _vm.isViewingReportByPart && _vm.isViewingReportByLineItem
    }
  }, [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "items": _vm.findOpenDocuments(),
      "headers": _vm.tableHeadersForStatusReportByDocument,
      "rows-per-page-items": [10, 20, 50],
      "dense-rows": "",
      "title": "By Document"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": `/documents/manage/${item.id}#line_items`
          }
        })];
      }
    }], null, false, 2442539797)
  })], 1) : _vm._e()])])], 1) : _vm._e(), _vm.selectedEntity ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-hardware_lists-base)"
    }
  }, [!_vm.isReadOnly ? _c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE],
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showAssignTemplateModal = true;
      }
    }
  }, [_vm.hardwareListHasTemplate ? [_vm._v("Reassign a template")] : [_vm._v("Assign template")]], 2) : _vm._e(), !_vm.isReadOnly && _vm.hardwareListHasTemplate ? _c('ButtonClearSelection', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.onClickOfClearAssignedTemplate
    }
  }) : _vm._e(), _vm.selectedEntity ? _c('ButtonGeneric', {
    staticClass: "ml-2",
    attrs: {
      "color": "success",
      "href": `/hardware/list_templates/create?hardwareListId=${_vm.selectedEntity.id}`,
      "icon": _vm.$icons.create_fat,
      "small": ""
    }
  }, [_vm._v(" Create a Template from this list ")]) : _vm._e(), _vm.hardwareListHasTemplate ? _c('MiniHardwareListTemplate', {
    staticClass: "mt-2",
    attrs: {
      "allow-child-creation": false,
      "allow-edit": false,
      "entity": _vm.selectedHardwareListTemplate,
      "initially-show-list-items": "",
      "title": "Currently assigned template"
    }
  }) : _vm._e(), !_vm.hardwareListHasTemplate ? _c('AppPageDescription', {
    staticClass: "mt-2"
  }, [_vm._v(" No template is currently assigned to this Hardware List. ")]) : _vm._e()], 1) : _vm._e(), _vm.showAssignTemplateModal ? _c('ModalHardwareListTemplateAssign', {
    attrs: {
      "hardware-list-id": _vm.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showAssignTemplateModal = false;
      }
    }
  }) : _vm._e(), _vm.showAssignToEventModal ? _c('ModalEventAssignToHardwareList', {
    attrs: {
      "hardware-list-id": _vm.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };