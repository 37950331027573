var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": "",
      "entity-type": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hint": "hardware list name",
      "is-loading": _vm.isLoading,
      "placeholder": "search for hardware list templates"
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _vm.shouldShowTable ? _c('AppTableExpandable', {
    key: "hlt_searchResults",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "headers": _vm.tableHeaders,
      "items": _vm.searchResults,
      "title": "Hardware list templates",
      "item-key": "id",
      "show-count": "",
      "sticky": ""
    },
    on: {
      "expandableRowClick": _vm.onClickOfHardwareListTemplate
    },
    scopedSlots: _vm._u([{
      key: "expanded-row",
      fn: function (props) {
        return [_c('ButtonGeneric', {
          staticClass: "my-2 ml-0",
          attrs: {
            "color": "primary",
            "icon": _vm.$icons.square_check
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfSearchResult(_vm.selectedHardwareListTemplate);
            }
          }
        }, [_vm._v(" Select this template ")]), props.item ? _c('MiniHardwareListTemplate', {
          attrs: {
            "entity": props.item,
            "initially-show-list-items": ""
          }
        }) : _vm._e()];
      }
    }], null, false, 3544262890)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };