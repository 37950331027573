var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UserSupportContextToValidItemMasterTypes } from '~/application.config';
import { ChangeSetType, ItemMasterType as EquipmentGroup } from '~/db_types';
import BaseManageView from '~/nasa_ui/base/BaseManageView';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { ChangeSetsRepoMixin } from '~/nasa_ui/DAL/changeSet';
import { CosmicUserGroupSlimFragment, CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { AlertType, EntityType, EntityTypeSingularDisplay, HeaderSortOptions } from '~/nasa_ui/types';
import { ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { InventoryClassDisplay, ItemClassesWithoutMultiple } from '~/nasa_ui/types/enums/hardware';
import { dateTimeDisplay } from '~/nasa_ui/utils/dates/dateTimeDisplay';
import { contactDisplay } from '~/nasa_ui/utils/helpers/displays';
import { transformUserGroups } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
// reusable fragment
export const changeSetTemplateFragment = gql `
  ${CosmicUserSlimFragment}
  ${CosmicUserGroupSlimFragment}

  fragment changeSetTemplateOnCSTemplateManage on ChangeSetTemplate {
    createdByUserId
    createdDateTime
    documentType
    groupCode
    id
    isActive
    itemInstanceInventoryType
    itemMasterType
    requiresComment
    requiresQualityControlStamp
    subType

    changeSetAuthorities {
      totalCount
    }

    createdByUser {
      ...CosmicUserSlim
    }

    userGroup {
      ...CosmicUserGroupSlim
    }
  }
`;
const ChangeSetTypesBasedOnEntityType = new Map([
    [EntityType.ITEM_DRAWING, [ChangeSetType.ITEM_DRAWING_CODES_CHANGE, ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE]],
    [
        EntityType.ITEM_MASTER,
        [
            ChangeSetType.ITEM_MASTER_APPROVAL_CHANGE,
            ChangeSetType.ITEM_MASTER_INITIALIZATION,
            ChangeSetType.ITEM_MASTER_INVENTORY_METHOD_CHANGE,
            ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE,
            ChangeSetType.ITEM_MASTER_EQUIPMENT_GROUP_CHANGE,
            ChangeSetType.ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE,
            ChangeSetType.ITEM_MASTER_REFURB_CHANGE
        ]
    ],
    [
        EntityType.ITEM_INSTANCE,
        [
            ChangeSetType.ITEM_INSTANCE_CONFIGURATION_CHANGE,
            ChangeSetType.ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE,
            ChangeSetType.ITEM_INSTANCE_LIMITED_LIFE_CHANGE,
            ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE
        ]
    ],
    [
        EntityType.INVENTORY,
        [
            ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL,
            ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE,
            ChangeSetType.ITEM_INSTANCE_INVENTORY_CLASS_CHANGE,
            ChangeSetType.ITEM_INSTANCE_INVENTORY_COST_CHANGE,
            ChangeSetType.ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE
        ]
    ]
]);
let ChangeSetTemplatesManageView = class ChangeSetTemplatesManageView extends Mixins(BaseManageView, ChangeSetsRepoMixin) {
    changeSetTemplates = null;
    ChangeSetType = ChangeSetType;
    ChangeSetTypeDisplay = ChangeSetTypeDisplay;
    EquipmentGroup = EquipmentGroup;
    EquipmentGroupDisplay = EquipmentGroupDisplay;
    formDataDirtyFlag = false;
    formState = null;
    hardwareLevel = null;
    isEditing = false;
    isHardwareRelated = null;
    isSaving = false;
    InventoryClassDisplay = InventoryClassDisplay;
    selectedChangeSetTemplate = null;
    showExistingChangeSetModal = false;
    showForm = false;
    showNewChangeSetModal = false;
    tableFilters = {
        subType: null,
        itemMasterType: null,
        itemInstanceInventoryType: null
    };
    tableHeaders = [
        {
            text: 'Approval</br>order',
            value: '_order',
            sortable: false,
            headerSortType: HeaderSortOptions.NUMBER
        },
        {
            text: 'Approval</br>Group',
            value: '_userGroup._displayName',
            sortable: false
        },
        {
            text: 'Requires<br/>QC stamp?',
            value: '_requiresQualityControlStampIcon'
        },
        {
            text: 'Requires<br/>comment?',
            value: '_requiresCommentIcon',
            sortable: false
        },
        {
            text: 'Currently<br/>active?',
            value: '_isActiveIcon',
            sortable: false
        },
        {
            text: 'Created',
            value: '_createdDateTime'
        },
        {
            text: 'Created by',
            value: '_createdBy',
            sortable: false
        }
    ];
    get computedHardwareLevelSelectOptions() {
        return [EntityType.ITEM_DRAWING, EntityType.ITEM_MASTER, EntityType.ITEM_INSTANCE, EntityType.INVENTORY].map((level) => {
            return {
                displayText: `${EntityTypeSingularDisplay.get(level)}`,
                value: level
            };
        });
    }
    get computedItemMasterTypeSelectOptions() {
        const imTypes = UserSupportContextToValidItemMasterTypes.get(this.currentUserActiveSupportContext);
        return imTypes
            ? imTypes.map((imType) => {
                return {
                    displayText: EquipmentGroupDisplay.get(imType),
                    value: imType
                };
            })
            : [];
    }
    get computedTableHeaders() {
        const headers = [...this.tableHeaders];
        const eqGrpCol = {
            text: 'Equipment group',
            value: '_subType'
        };
        if (this.isFilterSelectedItemMaster || this.isFilterSelectedItemInstance) {
            headers.push(eqGrpCol);
        }
        else if (this.isFilterSelectedInventory) {
            headers.push(eqGrpCol, {
                text: 'Class',
                value: '_itemClass'
            });
        }
        return [...headers];
    }
    /**
     * TODO: This filter probably needs to return just the subType portion. Further filtering
     * will need to be done after the query. It doesn’t seem to be possible to filter for
     * itemMasterType and/or itemInstanceInventoryType to be equal to something or null.
     */
    get changeSetTemplateFilter() {
        if (!this.tableFilters.subType) {
            return null;
        }
        const filter = {
            subType: {
                equalTo: this.tableFilters.subType
            }
        };
        return filter;
    }
    get changeSetTemplatesTableData() {
        if (!this.changeSetTemplates || !this.tableFilters.subType) {
            return [];
        }
        let templates = [...this.changeSetTemplates];
        if (this.tableFilters.itemMasterType) {
            templates = templates.filter((template) => {
                return !template.itemMasterType || template.itemMasterType === this.tableFilters.itemMasterType;
            });
        }
        else {
            templates = templates.filter((template) => {
                return !template.itemMasterType;
            });
        }
        if (this.tableFilters.itemInstanceInventoryType) {
            templates = templates.filter((template) => {
                return (!template.itemInstanceInventoryType ||
                    template.itemInstanceInventoryType === this.tableFilters.itemInstanceInventoryType);
            });
        }
        else {
            templates = templates.filter((template) => {
                return !template.itemInstanceInventoryType;
            });
        }
        return templates.map((template, index) => {
            const tableItem = Object.assign({}, template, {
                _createdBy: contactDisplay(template.createdByUser),
                _createdDateTime: dateTimeDisplay(template.createdDateTime),
                _isActiveIcon: this.booleanTableItemDisplay(template.isActive),
                _itemClass: template.itemInstanceInventoryType
                    ? InventoryClassDisplay.get(template.itemInstanceInventoryType) || DEFAULT_DASH
                    : 'ALL',
                _order: index + 1,
                _requiresCommentIcon: this.booleanTableItemDisplay(template.requiresComment),
                _requiresQualityControlStampIcon: this.booleanTableItemDisplay(template.requiresQualityControlStamp),
                _subType: template.itemMasterType ? EquipmentGroupDisplay.get(template.itemMasterType) || DEFAULT_DASH : 'ALL',
                _userGroup: transformUserGroups([template.userGroup])[0]
            });
            return tableItem;
        });
    }
    get changeSetTypesBasedOnHardwareLevel() {
        return this.hardwareLevel ? ChangeSetTypesBasedOnEntityType.get(this.hardwareLevel) || [] : [];
    }
    get computedInventoryClasss() {
        return ItemClassesWithoutMultiple;
    }
    get computedChangeSetTypes() {
        return this.changeSetTypesBasedOnHardwareLevel.reduce((acc, key) => {
            if (!key.includes('LINE_ITEM')) {
                acc[key] = key;
            }
            return acc;
        }, {});
    }
    get changeSetTypesToRemove() {
        const hardwareChangeSetTypes = ChangeSetTypesBasedOnEntityType.values();
        const systemControlledChangeSetTypes = Object.keys(ChangeSetType).filter((changeSetType) => (changeSetType.includes('LINE_ITEM') && changeSetType !== ChangeSetType.DELIVERY_ORDER_LINE_ITEM) ||
            [ChangeSetType.COSMIC_ACTION, ChangeSetType.WORK_ORDER_STEP].includes(changeSetType));
        return [].concat(...hardwareChangeSetTypes).concat(systemControlledChangeSetTypes);
    }
    get isActive() {
        return Boolean(this.selectedChangeSetTemplate?.isActive);
    }
    get isFilterSelectedInventory() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.tableFilters.subType.startsWith('ITEM_INSTANCE_INVENTORY');
    }
    get isFilterSelectedItemDrawing() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.tableFilters.subType.startsWith('ITEM_DRAWING');
    }
    get isFilterSelectedItemInstance() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.tableFilters.subType.startsWith('ITEM_INSTANCE') && !this.tableFilters.subType.includes('INVENTORY');
    }
    get isFilterSelectedItemMaster() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.tableFilters.subType.startsWith('ITEM_MASTER');
    }
    get shouldDisableDeleteButton() {
        return Boolean(this.selectedChangeSetTemplate?.changeSetAuthorities?.totalCount &&
            this.selectedChangeSetTemplate.changeSetAuthorities.totalCount > 0);
    }
    get shouldDisableSaveButton() {
        if (this.formState?.isSaving || this.formState?.isLocked) {
            return true;
        }
        if (this.formState?.isEditing) {
            return !this.formState?.isFormDirty || !this.formState?.isFormValid;
        }
        return !this.formState?.isFormValid;
    }
    get shouldShowEquipmentGroupAndClassDdl() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.isFilterSelectedInventory;
    }
    get shouldShowChangeSetTypeDdl() {
        return Boolean(this.hardwareLevel);
    }
    get shouldShowEquipmentGroupDdl() {
        if (!this.tableFilters.subType) {
            return false;
        }
        return this.isFilterSelectedItemMaster || this.isFilterSelectedItemInstance;
    }
    get shouldShowForm() {
        return this.showForm && this.tableFilters.subType;
    }
    get onlyActiveFilters() {
        if (!this.tableFilters) {
            return {};
        }
        return Object.entries(this.tableFilters).reduce((a, [k, v]) => (v == (null || undefined) ? a : { ...a, [k]: v }), {});
    }
    get tooltipRemoveButton() {
        let tooltip = 'Remove this Change Set Template';
        if (this.shouldDisableDeleteButton) {
            tooltip = 'Can NOT delete this Change Set Template because it is in use.';
        }
        if (this.isActive) {
            tooltip += '\nYou may set the Template to inactive using the Deactivate button.';
        }
        return tooltip;
    }
    getSingularEntityType(entityType) {
        let type = null;
        switch (entityType) {
            case EntityType.ITEM_DRAWING:
                type = 'ITEM_DRAWING';
                break;
            case EntityType.ITEM_MASTER:
                type = 'ITEM_MASTER';
                break;
            case EntityType.ITEM_INSTANCE:
                type = 'ITEM_INSTANCE';
                break;
            case EntityType.INVENTORY:
                type = 'ITEM_INSTANCE_INVENTORY';
                break;
            default:
                break;
        }
        return type;
    }
    onSubTypeChange(newSubType, oldSubType) {
        this.fetchChangeSetTemplates();
    }
    onRouteQueryChange() {
        if (this.tableFilters) {
            if (this.$route.query.subType) {
                this.tableFilters.subType = this.$route.query.subType;
            }
            if (this.$route.query.itemMasterType) {
                this.isHardwareRelated = true;
                this.tableFilters.itemMasterType = this.$route.query.itemMasterType;
            }
            if (this.$route.query.itemInstanceInventoryType) {
                this.isHardwareRelated = true;
                this.tableFilters.itemInstanceInventoryType = this.$route.query.itemInstanceInventoryType;
            }
        }
    }
    booleanTableItemDisplay(value) {
        return this.$booleanIconDisplay(value);
    }
    closeModal() {
        this.showNewChangeSetModal = false;
        this.showExistingChangeSetModal = false;
    }
    async fetchChangeSetTemplates() {
        try {
            this.isFetching = true;
            this.changeSetTemplates = null;
            const resp = await this.fetchChangeSetTemplatesQuery();
            if (resp?.data.changeSetTemplates?.nodes) {
                this.changeSetTemplates = [
                    ...resp.data.changeSetTemplates.nodes
                ];
            }
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isFetching = false;
        }
    }
    fetchChangeSetTemplatesQuery() {
        if (!this.changeSetTemplateFilter) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${changeSetTemplateFragment}

        query ChangeSetTemplatesOnCSTemplateManage($filter: ChangeSetTemplateFilter) {
          changeSetTemplates(filter: $filter) {
            nodes {
              ...changeSetTemplateOnCSTemplateManage
            }
          }
        }
      `,
            variables: {
                filter: this.changeSetTemplateFilter
            },
            fetchPolicy: 'network-only'
        });
    }
    async onClickOfRemove(item) {
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation DeleteChangeSetTemplate($id: Int!) {
            deleteChangeSetTemplateById(input: { id: $id }) {
              clientMutationId
            }
          }
        `,
                variables: {
                    id: item.id
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfSave(formRefName) {
        const form = this.$refs[formRefName];
        if (!form) {
            return;
        }
        form.onClickOfSave();
    }
    onClickOfTableRow(row) {
        if (!row?.subType || !row?.id) {
            return;
        }
        this.selectedChangeSetTemplate = row;
        this.showExistingChangeSetModal = true;
    }
    onCreatedChangeSetTemplate(template) {
        if (!this.changeSetTemplates) {
            this.changeSetTemplates = [];
        }
        this.changeSetTemplates.push(template);
        this.showNewChangeSetModal = false;
        this.$notification.add({
            text: 'Template created.',
            type: AlertType.SUCCESS
        });
    }
    async onDeactivateChangeSetTemplate() {
        if (!this.selectedChangeSetTemplate) {
            return;
        }
        try {
            const resp = await this.deactivateChangeSetTemplate({
                changeSetTemplateId: this.selectedChangeSetTemplate.id
            });
            if (resp) {
                this.onUpdatedChangeSetTemplate();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onReactivateChangeSetTemplate() {
        if (!this.selectedChangeSetTemplate) {
            return;
        }
        try {
            const resp = await this.reactivateChangeSetTemplate({
                changeSetTemplateId: this.selectedChangeSetTemplate.id
            });
            if (resp) {
                this.onUpdatedChangeSetTemplate();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onDeletedChangeSetTemplate(templateId) {
        this.changeSetTemplates = this.changeSetTemplates?.filter((template) => template.id !== templateId) || null;
        this.$notification.add({
            text: 'Deleted.',
            type: AlertType.SUCCESS
        });
        this.closeModal();
    }
    async onUpdatedChangeSetTemplate() {
        try {
            await this.fetchChangeSetTemplates();
            this.$notification.add({
                text: 'Updated.',
                type: AlertType.SUCCESS
            });
            this.closeModal();
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    clearTableFilters() {
        this.hardwareLevel = null;
        this.tableFilters.itemInstanceInventoryType = null;
        this.tableFilters.itemMasterType = null;
        this.tableFilters.subType = null;
    }
    onFormStateChange(formState) {
        this.formState = formState;
    }
    resetForm() {
        this.isEditing = false;
    }
    toggleForm() {
        this.showForm = !this.showForm;
    }
    onIsHardwareRelatedChange() {
        this.clearTableFilters();
    }
    syncDropDowns(hardwareLevel) {
        if (!hardwareLevel) {
            this.clearTableFilters();
        }
    }
    clearSubTypeBasedDropDowns(subType) {
        if (!subType) {
            this.tableFilters.itemMasterType = null;
            this.tableFilters.itemInstanceInventoryType = null;
        }
    }
};
__decorate([
    Watch('tableFilters.subType', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeSetType !== "undefined" && ChangeSetType) === "function" ? _a : Object, typeof (_b = typeof ChangeSetType !== "undefined" && ChangeSetType) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesManageView.prototype, "onSubTypeChange", null);
__decorate([
    Watch('$route.query', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesManageView.prototype, "onRouteQueryChange", null);
__decorate([
    Watch('isHardwareRelated', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesManageView.prototype, "onIsHardwareRelatedChange", null);
__decorate([
    Watch('hardwareLevel', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof EntityType !== "undefined" && EntityType) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesManageView.prototype, "syncDropDowns", null);
__decorate([
    Watch('tableFilters.subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof ChangeSetType !== "undefined" && ChangeSetType) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetTemplatesManageView.prototype, "clearSubTypeBasedDropDowns", null);
ChangeSetTemplatesManageView = __decorate([
    Component
], ChangeSetTemplatesManageView);
export default ChangeSetTemplatesManageView;
