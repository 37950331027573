var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { Maybe } from '~/nasa_ui/types';
import { getContracts } from '~/nasa_ui/utils';
let AppSelectContract = class AppSelectContract extends Mixins(LoaderMixin, AutocompleteMixin) {
    options = []; // contract numbers
    label;
    get computedLabel() {
        return this.label ? this.label : this.multiple ? 'Contract number(s)' : 'Contract number';
    }
    async created() {
        try {
            this.isLoading = true;
            const resp = await getContracts({
                take: -1
            });
            this.options = resp.data.results.map((c) => ({
                displayText: c.number,
                value: c.number
            }));
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: 'Failed to fetch all contracts.' });
        }
        finally {
            this.isLoading = false;
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppSelectContract.prototype, "label", void 0);
AppSelectContract = __decorate([
    Component
], AppSelectContract);
export default AppSelectContract;
