import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "elevation-2 cosmic_table",
    attrs: {
      "entity-type": _vm.computedColor
    }
  }, [_vm.title || _vm.$slots.title ? _c('v-toolbar', {
    attrs: {
      "dark": "",
      "flat": "",
      "color": _vm.computedColor
    }
  }, [_vm.computedIcon ? _c('c-icon-fa', {
    staticClass: "cosmic_table_header_icon",
    attrs: {
      "icon-color": _vm.computedIconColor,
      "icon": _vm.computedIcon
    }
  }) : _vm._e(), _vm.computedTitle || _vm.hasTitleSlot ? _c('v-toolbar-title', {
    staticClass: "cosmic_table_title"
  }, [_vm.hasTitleSlot ? _vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  }) : _c('div', [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.computedTitle,
      expression: "computedTitle"
    }]
  }), _vm.showCount ? _c('span', [_vm._v(" ( "), _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.computedItems.length)))])]), _vm._v(" ) ")]) : _vm._e()])], 2) : _vm._e(), _vm._t("headerBeforeSearchInput", function () {
    return [_c('v-spacer')];
  }), _vm._t("headerSearchInput", function () {
    return [_vm.computedShowSearchInput ? _c('v-text-field', {
      staticClass: "cosmic_filter_table",
      attrs: {
        "append-icon": _vm.$icons.filter,
        "color": _vm.computedColor,
        "clearable": "",
        "hide-details": "",
        "light": "",
        "placeholder": "Filter",
        "solo": ""
      },
      on: {
        "input": _vm.emitSearchFilterInput,
        "keyup": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
          return _vm.clearSearchFilter.apply(null, arguments);
        }
      },
      model: {
        value: _vm.searchFilter,
        callback: function ($$v) {
          _vm.searchFilter = $$v;
        },
        expression: "searchFilter"
      }
    }) : _vm._e()];
  })], 2) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    class: _vm.computedClass,
    attrs: {
      "headers": _vm.headers,
      "item-key": _vm.itemKey,
      "items": _vm.items,
      "loading": _vm.isLoading,
      "pagination": _vm.localPagination,
      "rows-per-page-items": _vm.rowsPerPageOptions,
      "total-items": _vm.totalItems
    },
    on: {
      "update:pagination": _vm.onPaginationUpdate
    },
    scopedSlots: _vm._u([{
      key: "progress",
      fn: function () {
        return [_vm.isLoading ? _c('c-progress') : _vm._e()];
      },
      proxy: true
    }, {
      key: "items",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "cosmic_table_row",
          class: `${_vm.selectableRows ? 'selectable_row' : ''} ${props.item.rowClass ? props.item.rowClass : ''}`
        }, [_vm._l(_vm.computedTableHeaders, function (header) {
          return _c('td', {
            key: header.value,
            class: [_vm.getAlignClass(header), header.noWrap ? 'no-wrap' : '', _vm.addMonospaceIfNeeded(header), props.item.rowClass ? props.item.rowClass : ''],
            on: {
              "click": function ($event) {
                return _vm.onClickOfRow(props.item, $event);
              },
              "dblclick": function ($event) {
                return _vm.onDblClickOfRow(props.item, $event);
              }
            }
          }, [_vm.hasSlot(header.value) ? _vm._t(header.value, null, {
            "defaultData": _vm.getTableData(header, props.item),
            "header": header,
            "item": props.item
          }) : _vm._t("table-cell", function () {
            return [_c('div', {
              directives: [{
                name: "safe-html",
                rawName: "v-safe-html",
                value: _vm.getTableData(header, props.item),
                expression: "getTableData(header, props.item)"
              }]
            })];
          }, {
            "defaultData": _vm.getTableData(header, props.item),
            "header": header,
            "item": props.item
          })], 2);
        }), _c('td', [_c('div', {
          staticClass: "actions"
        }, [_vm._t("actions", null, {
          "item": props.item
        })], 2)])], 2)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [!_vm.isLoading ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("No results found")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_c('Alert', {
          attrs: {
            "slot": "no-results",
            "type": _vm.AlertType.WARNING
          },
          slot: "no-results"
        }, [_vm._v(" No "), _c('strong', [_vm._v("filtered")]), _vm._v(" results found. ")])];
      },
      proxy: true
    }], null, true)
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };