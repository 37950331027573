import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType, DeliveryOrderLineItemStatus } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, DeliveryOrderLineItemStatusDisplay } from '~/nasa_ui/types';
import { getDeliveryOrder, putDeliveryOrderLineItem } from '~/nasa_ui/utils';
let ModalDeliveryOrderLineItemTaskOwnerUpdate = class ModalDeliveryOrderLineItemTaskOwnerUpdate extends Mixins(BaseModalWithUser) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    deliveryOrder = null;
    formData = {
        attributes: {},
        deliveryOrderLineItemStatus: null,
        projectedDate: null
    };
    changeSetId;
    deliveryOrderId;
    get selectedEntity() {
        return this.deliveryOrder?.lineItems.changeSets?.find((cs) => cs.id === this.changeSetId) ?? null;
    }
    async onClickOfSave() {
        if (!this.selectedEntity) {
            console.log('Could not find line item to update');
            return;
        }
        // this request body needs to list every property of the line item, even if
        // it's not being updated. It’s typed to be explicit (no undefined values)
        // because omitting a field here could lead to unintended data loss.
        const body = {
            attributes: this.selectedEntity.attributes,
            createPartReference: null,
            deliveryItemsListNumber: this.selectedEntity.deliveryItemsListNumber ?? null,
            deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
            jDocumentNumber: this.selectedEntity.jDocumentNumber ?? null,
            lineItemQuantity: this.selectedEntity.lineItemQuantity ?? null,
            lineItemType: this.selectedEntity.lineItemType ?? null,
            openedDate: this.selectedEntity.openedDate,
            ownerContactId: this.selectedEntity.ownerContactId ?? null,
            partReferenceId: this.selectedEntity.partReferenceId ?? null,
            plannedExpirationDate: this.selectedEntity.plannedExpirationDate ?? null,
            projectedDate: this.formData.projectedDate ?? null,
            shipperNumber: this.selectedEntity.shipperNumber ?? null,
            workBreakdownStructureNumber: this.selectedEntity.workBreakdownStructureNumber ?? null
        };
        try {
            // cast the request body because some fields don’t expect null
            const resp = await putDeliveryOrderLineItem(this.deliveryOrderId, this.changeSetId, body);
            // User wants this specifically logged as a comment
            // when the projectedDate is updated -> make a comment
            if (this.selectedEntity.projectedDate !== this.formData.projectedDate) {
                const payload = {
                    comment: `
DIL #:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${this.selectedEntity.deliveryItemsListNumber ?? DEFAULT_DASH}\`
Drawing #:&nbsp;&nbsp;\`${this.selectedEntity.partReference.drawingNumber ?? DEFAULT_DASH}\`
Serial #:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${this.selectedEntity.partReference.serialNumber ?? DEFAULT_DASH}\`

Est. completion date change:

&nbsp;&nbsp;&nbsp;&nbsp;From:&nbsp;&nbsp;&nbsp;&nbsp;\`${this.$dateDisplay(this.selectedEntity.projectedDate)}\`
&nbsp;&nbsp;&nbsp;&nbsp;To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\`${this.$dateDisplay(this.formData.projectedDate)}\` 

Updated by:&nbsp;&nbsp;&nbsp;&nbsp;${this.currentUserFirstLastNameDisplay} on \`${this.$dateTimeDisplay(new Date())}\` `,
                    subType: CommentType.SYSTEM
                };
                const params = {
                    changeSetId: this.changeSetId,
                    documentId: this.deliveryOrderId
                };
                const resp = await this.$http.post(`/comments`, payload, { params });
            }
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.emitSaved();
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved() { }
    async onDeliveryOrderIdChange() {
        try {
            const resp = await getDeliveryOrder(this.deliveryOrderId);
            this.deliveryOrder = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSelectedEntityChange() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData.deliveryOrderLineItemStatus = this.selectedEntity.deliveryOrderLineItemStatus;
        this.formData.projectedDate = this.selectedEntity.projectedDate;
    }
};
__decorate([
    Prop({
        type: Number,
        required: true
    }),
    __metadata("design:type", Number)
], ModalDeliveryOrderLineItemTaskOwnerUpdate.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalDeliveryOrderLineItemTaskOwnerUpdate.prototype, "deliveryOrderId", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemTaskOwnerUpdate.prototype, "emitSaved", null);
__decorate([
    Watch('deliveryOrderId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemTaskOwnerUpdate.prototype, "onDeliveryOrderIdChange", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalDeliveryOrderLineItemTaskOwnerUpdate.prototype, "onSelectedEntityChange", null);
ModalDeliveryOrderLineItemTaskOwnerUpdate = __decorate([
    Component
], ModalDeliveryOrderLineItemTaskOwnerUpdate);
export default ModalDeliveryOrderLineItemTaskOwnerUpdate;
