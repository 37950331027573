import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass, SerialLot } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { AnalysisResult, EntityType, InventoryClassDisplay, OutputMode } from '~/nasa_ui/types';
import { getItemInstance } from '~/nasa_ui/utils';
let ModalInventoryCreate = class ModalInventoryCreate extends Mixins(BaseModal) {
    EntityType = EntityType;
    InventoryClass = InventoryClass;
    InventoryClassDisplay = InventoryClassDisplay;
    OutputMode = OutputMode;
    selectedEntity = null;
    locationSearchFilters = {
        hasInventory: false
    };
    formData = {
        location: null,
        subType: null
    };
    itemInstanceId;
    get isInternalSelectedLocation() {
        return this.formData.location?.isInternal ?? false;
    }
    async onClickOfCreate() {
        if (!this.itemInstanceId || !this.selectedEntity || !this.formData.location || !this.formData.subType) {
            return;
        }
        try {
            this.isSaving = true;
            if (this.isInternalSelectedLocation) {
                const body = {
                    asBuiltNumber: this.selectedEntity.asBuiltNumber || DB_DEFAULT,
                    drawingNumber: this.selectedEntity.drawingNumber,
                    inspectionPerformed: true,
                    inspectionResult: AnalysisResult.UNKNOWN,
                    inventorySubType: this.formData.subType,
                    isCostEstimated: false,
                    locationId: this.formData.location.id,
                    openDiscrepancyReport: false,
                    quantityReceived: 1,
                    side: this.selectedEntity.side,
                    serialLotNumbers: [
                        {
                            number: this.selectedEntity.serialNumber || '',
                            numberType: SerialLot.SERIAL
                        }
                    ]
                };
                const resp = await this.$http.post(`/inventory/initial-receipt`, body);
            }
            else {
                const body = {
                    asBuiltNumber: this.selectedEntity.asBuiltNumber || DB_DEFAULT,
                    drawingNumber: this.selectedEntity.drawingNumber,
                    inventorySubType: this.formData.subType,
                    isCostEstimated: false,
                    issueToOrganizationCode: this.formData.location.organizationCode,
                    quantityReceived: 1,
                    side: this.selectedEntity.side,
                    serialLotNumbers: [
                        {
                            number: this.selectedEntity.serialNumber || '',
                            numberType: SerialLot.SERIAL
                        }
                    ]
                };
                const resp = await this.$http.post(`/inventory/issued`, body);
            }
            this.emitSaved();
            this.isSaving = false;
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitSaved() {
        return;
    }
    async fetchItemInstanceEntity() {
        if (!this.itemInstanceId) {
            return;
        }
        try {
            const resp = await getItemInstance(this.itemInstanceId);
            if (resp.data) {
                this.selectedEntity = resp.data;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalInventoryCreate.prototype, "itemInstanceId", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalInventoryCreate.prototype, "emitSaved", null);
__decorate([
    Watch('itemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalInventoryCreate.prototype, "fetchItemInstanceEntity", null);
ModalInventoryCreate = __decorate([
    Component
], ModalInventoryCreate);
export default ModalInventoryCreate;
