import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { getPartReference } from '~/nasa_ui/utils';
import { transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let QuickDisplayPartReference = class QuickDisplayPartReference extends Mixins(BaseVue) {
    selectedEntity = null;
    DEFAULT_DASH = DEFAULT_DASH;
    gap;
    // PartReferenceId
    id;
    width;
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformPartReferenceResponses([this.selectedEntity])[0] : null;
    }
    async fetchSelectedEntity() {
        if (!this.id) {
            return;
        }
        try {
            const resp = await getPartReference(this.id);
            setTimeout(() => {
                this.selectedEntity = resp.data;
            }, 300);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        default: '0'
    }),
    __metadata("design:type", String)
], QuickDisplayPartReference.prototype, "gap", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], QuickDisplayPartReference.prototype, "id", void 0);
__decorate([
    Prop({
        type: String,
        default: '300px'
    }),
    __metadata("design:type", String)
], QuickDisplayPartReference.prototype, "width", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], QuickDisplayPartReference.prototype, "fetchSelectedEntity", null);
QuickDisplayPartReference = __decorate([
    Component
], QuickDisplayPartReference);
export default QuickDisplayPartReference;
