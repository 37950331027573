var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.selectedEntity ? _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.isLocationArchived ? _c('v-flex', {
    staticClass: "mb-0",
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "slot": "icon",
      "icon": _vm.$icons.archived
    },
    slot: "icon"
  }), _vm._v(" This is Location has been archived and will not be selectable throughout the system. ")], 1)], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.isLocationManaged ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.SUCCESS
    }
  }, [_vm._v(" This is a "), _c('strong', [_vm._v("managed")]), _vm._v(" " + _vm._s(_vm.currentSystemName) + " Location because it has the "), _c('u', [_c('strong', [_vm._v(_vm._s(_vm.contractDisplay))])]), _vm._v(" Contract assigned. ")]) : _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._v(" This is an "), _c('strong', [_vm._v("unmanaged")]), _vm._v(" " + _vm._s(_vm.currentSystemName) + " Location because it is not assigned a Contract. ")])], 1)], 1), _c('c-card', [_vm.selectedEntity && _vm.selectedEntity.organizationCode ? _c('MiniOrganization', {
    attrs: {
      "code": _vm.selectedEntity.organizationCode,
      "show-manage-link": ""
    }
  }) : _vm._e(), _vm.hasContract && _vm.formData ? _c('MiniContract', {
    attrs: {
      "inline-clear": !_vm.isReadOnly && !_vm.locationContainsInventory,
      "number": _vm.formData.contractNumber,
      "read-only": _vm.isReadOnly || _vm.locationContainsInventory
    },
    on: {
      "inlineClear": _vm.onContractRemoval
    }
  }) : !_vm.isReadOnly && _vm.formData ? _c('v-flex', {
    attrs: {
      "xs3": "",
      "my-2": ""
    }
  }, [_c('AppFormWrapper', {
    attrs: {
      "size": "1",
      "color": _vm.EntityType.CONTRACT
    }
  }, [_c('AppSelectContract', {
    model: {
      value: _vm.formData.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "contractNumber", $$v);
      },
      expression: "formData.contractNumber"
    }
  })], 1)], 1) : _vm._e()], 1), !_vm.isReadOnly ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('Alert', {
    attrs: {
      "type": "AlertType.INFO"
    }
  }, [_vm._v(" Locations within " + _vm._s(_vm.currentSystemName) + " must be unique by the following criteria: "), _c('ul', {
    staticClass: "mt-2"
  }, [_c('li', [_vm._v("Contract")]), _c('li', [_vm._v("Building")]), _c('li', [_vm._v("Room")]), _c('li', [_vm._v("Stock")]), _c('li', [_vm._v("Bin")])])])], 1)]) : _vm._e(), _vm.formData ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-locations-base)"
    }
  }, [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly && !_vm.locationContainsInventory ? _c('AppText', {
    attrs: {
      "label": "Building",
      "required": ""
    },
    model: {
      value: _vm.formData.building,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "building", $$v);
      },
      expression: "formData.building"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Building",
      "value": _vm.formData.building || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Room"
    },
    model: {
      value: _vm.formData.room,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "room", $$v);
      },
      expression: "formData.room"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Room",
      "value": _vm.formData.room || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Stock",
      "required": ""
    },
    model: {
      value: _vm.formData.stock,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "stock", $$v);
      },
      expression: "formData.stock"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Stock",
      "value": _vm.formData.stock || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Bin",
      "required": ""
    },
    model: {
      value: _vm.formData.bin,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "bin", $$v);
      },
      expression: "formData.bin"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Bin",
      "value": _vm.formData.bin || '-'
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Address"
    },
    model: {
      value: _vm.formData.address,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Address",
      "value": _vm.formData.address || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "City"
    },
    model: {
      value: _vm.formData.city,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "city", $$v);
      },
      expression: "formData.city"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "City",
      "value": _vm.formData.city || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('c-select', {
    attrs: {
      "chips": false,
      "label": "State"
    },
    domProps: {
      "display": _vm.UsStateDisplay,
      "enum": _vm.UsState,
      "cValue": _vm.formData.state
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.state = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "State",
      "value": _vm.formData.state || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Zip"
    },
    model: {
      value: _vm.formData.zip,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "zip", $$v);
      },
      expression: "formData.zip"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Zip",
      "value": _vm.formData.zip || '-'
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-third"
  }, [!_vm.isReadOnly ? _c('AppText', {
    attrs: {
      "label": "Phone"
    },
    model: {
      value: _vm.formData.phone,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Phone",
      "value": _vm.formData.phone || '-'
    }
  })], 1), _c('div', {
    staticClass: "col-third"
  }, [!_vm.isReadOnly ? _c('AppContact', {
    model: {
      value: _vm.formData.contactId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "contactId", $$v);
      },
      expression: "formData.contactId"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Contact",
      "value": _vm.contactInformation
    }
  })], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-half"
  }, [!_vm.isReadOnly ? _c('AppBoolean', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "label": "Is off Earth?",
      "hide-unknown": ""
    },
    model: {
      value: _vm.formData.isOffEarth,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isOffEarth", $$v);
      },
      expression: "formData.isOffEarth"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Is off Earth?"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.isOffEarth
    }
  })], 1)], 1)]), !_vm.isReadOnly ? _c('ButtonSave', {
    attrs: {
      "slot": "actions",
      "disabled": _vm.shouldDisableSaveButton || _vm.isSaving,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    },
    slot: "actions"
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex",
    attrs: {
      "slot": "right-aligned-actions"
    },
    slot: "right-aligned-actions"
  }, [_vm.selectedEntity ? _c('ArchiveLocation', {
    staticClass: "mr-2",
    attrs: {
      "location": _vm.selectedEntity
    }
  }) : _vm._e(), _c('DeleteLocation', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "deletionComplete": _vm.onLocationDeleted
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };