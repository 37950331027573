var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-item_instances-base)",
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('c-card', {
          staticClass: "mb-2",
          attrs: {
            "start-collapsed": "",
            "border-color": "var(--v-item_instances-base)"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('IconAutoHardwareList', {
          staticClass: "mr-2",
          attrs: {
            "icon-size": "18px"
          }
        }), !_vm.selectedEntity ? _c('span', [_vm._v("Item listing")]) : _c('span', [_vm._v(" Item listing "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.selectedEntity.name))]), _vm._v(" details ")])], 1), _vm.transformedSelectedEntity ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Name"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._name) + " ")])])], 1), _c('div', {
          staticClass: "col-two-thirds"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))])], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number prefixes"
          }
        }, [_vm.transformedSelectedEntity.drawingNumberPrefix.length ? _c('ul', _vm._l(_vm.transformedSelectedEntity._drawingNumberPrefix, function (dn) {
          return _c('li', {
            key: dn
          }, [_c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(" " + _vm._s(dn) + " ")])]);
        }), 0) : _vm._e()])], 1)]) : _vm._e()]), _vm.transformedPartReferences.length ? _c('AppPageDescription', {
          attrs: {
            "remove-margin-left": "",
            "color": "info"
          }
        }, [_vm._v(" Below are the "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.transformedPartReferences.length))]), _vm._v(" parts matching by the drawing number prefixes ")]) : _vm._e(), _vm.transformedPartReferences.length ? _c('div', {
          staticStyle: {
            "max-height": "300px"
          }
        }, _vm._l(_vm.transformedPartReferences, function (part) {
          return _c('c-card', {
            key: part.id,
            staticClass: "mb-2",
            attrs: {
              "start-collapsed": "",
              "border-color": "var(--v-item_instances-base)"
            }
          }, [_c('div', {
            attrs: {
              "slot": "title"
            },
            slot: "title"
          }, [_c('c-icon-fa', {
            staticClass: "mr-2",
            attrs: {
              "icon-size": "18px",
              "icon": _vm.$icons.part_reference,
              "icon-color": "var(--v-item_instances-base)"
            }
          }), _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
            ...part
          }, false))], 1), _c('AppPartReferenceDisplay', {
            attrs: {
              "part-reference-id": part.id
            }
          })], 1);
        }), 1) : _c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v(" No matching parts found. ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Close "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };